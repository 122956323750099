import { makeStyles } from '@mui/styles'

export const avatarstyle = (bg, w=30, h=30, radius="10%") => {
    let s = makeStyles(theme => ({
        avatar: {
            // border: '3px solid #fff',
            background: `url(${bg})`,
            borderRadius: '10%',
            backgroundColor: '#fff',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: w,
            maxWidth: w,
            minWidth: w,
            height: h,
            maxHeight: h,
            minHeight: h,
        },
    }))

    return s().avatar
}

export const avatarstylesx = (bg, w=30, h=30, radius="10%") => {
    return {
        // border: '3px solid #fff',
        background: `url(${bg})`,
        borderRadius: '10%',
        backgroundColor: '#fff',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: w,
        maxWidth: w,
        minWidth: w,
        height: h,
        maxHeight: h,
        minHeight: h,
    }
}
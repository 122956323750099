import { useState } from 'react'

export const ConnectionState = () => {
    const [dMsg, setDMsg] = useState({base: [], search: [], list: [], isSearch: false})
    const [gMsg, setGMsg] = useState({base: [], search: [], list: [], isSearch: false})
    const [pMsg, setPmsg] = useState({base: [], search: [], list: [], isSearch: false})
    const [triggerLoad, setTriggerLoad] = useState(0)
    return {
        directData:{data: dMsg, set: setDMsg},
        groupData:{data: gMsg, set: setGMsg},
        proData:{data: pMsg, set: setPmsg},
        trigger:{data: triggerLoad, set: setTriggerLoad},
    }
}

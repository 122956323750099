/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserSocket = /* GraphQL */ `
  subscription OnCreateUserSocket(
    $filter: ModelSubscriptionUserSocketFilterInput
  ) {
    onCreateUserSocket(filter: $filter) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserSocket = /* GraphQL */ `
  subscription OnUpdateUserSocket(
    $filter: ModelSubscriptionUserSocketFilterInput
  ) {
    onUpdateUserSocket(filter: $filter) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserSocket = /* GraphQL */ `
  subscription OnDeleteUserSocket(
    $filter: ModelSubscriptionUserSocketFilterInput
  ) {
    onDeleteUserSocket(filter: $filter) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConnectionChatUser = /* GraphQL */ `
  subscription OnCreateConnectionChatUser(
    $filter: ModelSubscriptionConnectionChatUserFilterInput
  ) {
    onCreateConnectionChatUser(filter: $filter) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateConnectionChatUser = /* GraphQL */ `
  subscription OnUpdateConnectionChatUser(
    $filter: ModelSubscriptionConnectionChatUserFilterInput
  ) {
    onUpdateConnectionChatUser(filter: $filter) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteConnectionChatUser = /* GraphQL */ `
  subscription OnDeleteConnectionChatUser(
    $filter: ModelSubscriptionConnectionChatUserFilterInput
  ) {
    onDeleteConnectionChatUser(filter: $filter) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConnectionChat = /* GraphQL */ `
  subscription OnCreateConnectionChat(
    $filter: ModelSubscriptionConnectionChatFilterInput
  ) {
    onCreateConnectionChat(filter: $filter) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateConnectionChat = /* GraphQL */ `
  subscription OnUpdateConnectionChat(
    $filter: ModelSubscriptionConnectionChatFilterInput
  ) {
    onUpdateConnectionChat(filter: $filter) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteConnectionChat = /* GraphQL */ `
  subscription OnDeleteConnectionChat(
    $filter: ModelSubscriptionConnectionChatFilterInput
  ) {
    onDeleteConnectionChat(filter: $filter) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onCreateAttachment(filter: $filter) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onUpdateAttachment(filter: $filter) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onDeleteAttachment(filter: $filter) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const onCreateCheckVersion = /* GraphQL */ `
  subscription OnCreateCheckVersion(
    $filter: ModelSubscriptionCheckVersionFilterInput
  ) {
    onCreateCheckVersion(filter: $filter) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCheckVersion = /* GraphQL */ `
  subscription OnUpdateCheckVersion(
    $filter: ModelSubscriptionCheckVersionFilterInput
  ) {
    onUpdateCheckVersion(filter: $filter) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCheckVersion = /* GraphQL */ `
  subscription OnDeleteCheckVersion(
    $filter: ModelSubscriptionCheckVersionFilterInput
  ) {
    onDeleteCheckVersion(filter: $filter) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAppVersion = /* GraphQL */ `
  subscription OnCreateAppVersion(
    $filter: ModelSubscriptionAppVersionFilterInput
  ) {
    onCreateAppVersion(filter: $filter) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAppVersion = /* GraphQL */ `
  subscription OnUpdateAppVersion(
    $filter: ModelSubscriptionAppVersionFilterInput
  ) {
    onUpdateAppVersion(filter: $filter) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAppVersion = /* GraphQL */ `
  subscription OnDeleteAppVersion(
    $filter: ModelSubscriptionAppVersionFilterInput
  ) {
    onDeleteAppVersion(filter: $filter) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCallFeedBack = /* GraphQL */ `
  subscription OnCreateCallFeedBack(
    $filter: ModelSubscriptionCallFeedBackFilterInput
  ) {
    onCreateCallFeedBack(filter: $filter) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCallFeedBack = /* GraphQL */ `
  subscription OnUpdateCallFeedBack(
    $filter: ModelSubscriptionCallFeedBackFilterInput
  ) {
    onUpdateCallFeedBack(filter: $filter) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCallFeedBack = /* GraphQL */ `
  subscription OnDeleteCallFeedBack(
    $filter: ModelSubscriptionCallFeedBackFilterInput
  ) {
    onDeleteCallFeedBack(filter: $filter) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateForward = /* GraphQL */ `
  subscription OnCreateForward($filter: ModelSubscriptionForwardFilterInput) {
    onCreateForward(filter: $filter) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateForward = /* GraphQL */ `
  subscription OnUpdateForward($filter: ModelSubscriptionForwardFilterInput) {
    onUpdateForward(filter: $filter) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteForward = /* GraphQL */ `
  subscription OnDeleteForward($filter: ModelSubscriptionForwardFilterInput) {
    onDeleteForward(filter: $filter) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSwitch = /* GraphQL */ `
  subscription OnCreateSwitch($filter: ModelSubscriptionSwitchFilterInput) {
    onCreateSwitch(filter: $filter) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const onUpdateSwitch = /* GraphQL */ `
  subscription OnUpdateSwitch($filter: ModelSubscriptionSwitchFilterInput) {
    onUpdateSwitch(filter: $filter) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const onDeleteSwitch = /* GraphQL */ `
  subscription OnDeleteSwitch($filter: ModelSubscriptionSwitchFilterInput) {
    onDeleteSwitch(filter: $filter) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;

// Libraries
import { Box } from '@mui/material/';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// Assets
// Layouts
import awsmobile from '../aws-exports';
import { domain, env } from '../core/Env';
import { rqx } from '../core/request/API';
import { Logo } from '../core/global/Icons';
import { ToolContext } from '../core/context/ToolContext';
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { WebRoutePsl } from '../core/webrouter/PslRoute';
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { onCreateConnectionChatUser, onUpdateConnectionChat, onUpdateConnectionChatUser } from '../graphql/subscriptions';
import { gfCheckVersionsByUserID, gfCreateCheckVersion, gfCreateUser, gfListConnectionChatUsers, gfUpdateUser, gfUserByAid } from '../core/global/graphqlFunc/GraphqlFunctions';
import { FunctionPromt } from '../core/global/promter/FunctionPromt';
import { VM } from '../core/VM';
import { SuperSnackbar } from '../core/global/snackbar/SnackbarRemove';
import { OnBoardingFunctions } from './components/onboarding/OnBoardingFunctions';
import ModalUpdateFunction from '../core/global/modal/modalupdate/ModalUpdateFunction';
import { updateAttachment } from '../graphql/mutations';
import { socket } from '../service/socket';
import { useParams } from "react-router-dom";
import { FunctionNotification } from './components/calling/notification/FunctionNotification';
import IncomingCallSound from '../core/global/audio/IncomingCallSound';
import { userByAid } from '../graphql/queries';
import { SuperSnackbarUnsuc } from '../core/global/snackbar/SnackbarUnsuc';
import { BrandLog } from '../core/global/BrandLog';

// import { updateConnectionChat } from '../graphql/mutations';
let view = VM();
const InstallNotification = lazy(() => import(`./components/layouts/installation${view}/InstallNotification`))
const InstallPromotion = lazy(() => import(`./components/layouts/installation${view}/InstallPromotion`))
//variables
const __SESSION = JSON.parse(localStorage.getItem('pof_chat_session'))
Amplify.configure({ ...awsmobile, Analytics: { disabled: true, } });

export const CorePersonal = () => {
	//context values
	//variables
	let webAppVersion = "POFChat V2.9.15"
	//states
	const [notifChecker, setNotifChecker] = useState(false)
	const [userLog, setUserLog] = useState(true)
	const [vcVal, setVcVal] = useState(false)
	const [vcData, setVcData] = useState([])

	const { tool_state, install_state, connection_state, calling_state } = useContext(ToolContext)

	const ringingPlay = calling_state.isRinging;


	//functions
	// const handleJoyrideCallback = (data) => {
	// 	const { status } = data;
	// 	if ([Joyride.STATUSES.FINISHED, Joyride.STATUSE .SKIPPED].includes(status)) {
	// 		setRunTour(false);
	// 	}
	// };
	//functions
	const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
			installPromptHandler()
		});
	}

	const installPromptHandler = () => {
		// setTimeout(() => {
		// 	install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		// }, 2000);
	}

	const CheckNotifHandler = () => {
		if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
			let notifGranter = Notification.permission
			if (notifGranter !== 'granted') {
				setTimeout(() => {
					setNotifChecker(true)
				}, 1000);
			}
		}
	}

	//useeffect
	useEffect(() => {
		BrandLog()
		const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
			let ainfo = await rqx.get(`<<global>>/${env() === 'local' ? 'dev' : env()}/account/read`, { aid: __SESSION.ainfo.aid })
			let pinfo = await rqx.get(`<<global>>/${env() === 'local' ? 'dev' : env()}/primeinfo/read`, { aid: __SESSION.ainfo.aid })
			if (pinfo.length === 0) {
				window.location.href = `${domain('psl').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(__SESSION.ainfo))}`
			}
			// initialize user form appsync & and check if the user browser is currently updateed by version
			let listDirect = []
			let listGroup = []
			const graphAid = await gfUserByAid(`${window.atob(ainfo.aid).split("_")[1]}`)
			if (graphAid.data.userByAid.items.length === 0) {
				await gfCreateUser(window.atob(ainfo.aid).split("_")[1], `${pinfo[0].first_name} ${pinfo[0].last_name}`, JSON.parse(pinfo[0].profile).profile === null ? 'none' : JSON.parse(pinfo[0].profile).profile, 'online', ainfo.account_type)
			} else {
				const checkVersion = await gfCheckVersionsByUserID(graphAid.data.userByAid.items[0].id)
				if ((graphAid.data.userByAid.items[0].name !== `${pinfo[0].first_name} ${pinfo[0].last_name}`) || (graphAid.data.userByAid.items[0].profileImg !== JSON.parse(pinfo[0].profile).profile)) {
					let dataTC = {id:graphAid.data.userByAid.items[0].id}
					if (graphAid.data.userByAid.items[0].name !== `${pinfo[0].first_name} ${pinfo[0].last_name}`) {
						// dataTC.push({name: `${pinfo[0].first_name} ${pinfo[0].last_name}`})
						dataTC = {...dataTC, name:`${pinfo[0].first_name} ${pinfo[0].last_name}`}
					}
					if (graphAid.data.userByAid.items[0].profileImg !== JSON.parse(pinfo[0].profile).profile) {
						dataTC = {...dataTC, profileImg:JSON.parse(pinfo[0].profile).profile}
					}
					// console.log(dataTC)
					gfUpdateUser(dataTC)
				}
				if (checkVersion.data.checkVersionsByUserID.items.length === 0) {
					await gfCreateCheckVersion(graphAid.data.userByAid.items[0].id, webAppVersion)
				} else if (checkVersion.data.checkVersionsByUserID.items[0].version !== webAppVersion) {
					setVcData(checkVersion.data.checkVersionsByUserID.items[0])
					setVcVal(true)
				}
				listDirect = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 1, 1000, `${window.atob(ainfo.aid).split("_")[1]}`)
				listGroup = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 2, 1000, `${window.atob(ainfo.aid).split("_")[1]}`)
			}
			// initialize user connections

			// SET STATES HERE ( SAMPLE STORING BELOW: )
			let d = new Date()
			d.setDate(d.getDate() + 30);
			localStorage.setItem('pof_chat_session', JSON.stringify({ expiration: `${d.getMonth() + 1}${d.getUTCDate()}${d.getUTCFullYear()}`, ainfo: ainfo }))
			tool_state.__SESSION.set({ ainfo, pinfo, graphAid: graphAid.data.userByAid.items[0] })
			let onboardingRead = await rqx.post(`<<global>>/${env() === 'local' ? 'dev' : env()}/personalization/readfoundation?user_id=${window.atob(__SESSION.ainfo.aid).split("_")[1]}&ref=pchatp_onboarding`)
			let swtchingRead = await rqx.post(`<<global>>/${env() === 'local' ? 'dev' : env()}/personalization/readfoundation?user_id=${window.atob(__SESSION.ainfo.aid).split("_")[1]}&ref=pchatp_onboarding`)
			if (onboardingRead.length === 0) {
				setUserLog(!userLog)
			}
			if (swtchingRead.length === 0) {
				setUserLog(!userLog)
			}
			connection_state.directData.set({ ...connection_state.directData.data, base: listDirect, list: listDirect })
			connection_state.groupData.set({ ...connection_state.groupData.data, base: listGroup, list: listGroup })
			tool_state.prefetch.set(true)
			// info_users.ConnectionState.set({...info_users.LocationStates.data})
			installPromptHandler() // RUN PWA INSTALLATION PROMPT HANDLER
		}

		if (__SESSION !== null) {
			__installer()
			__init()
			CheckNotifHandler()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW:)
				tool_state.prefetch.set(true)

				__installer() // RUN PWA INSTALLATION PROMPT HANDLER
			}, 1000);
		}
		console.log(`${webAppVersion} is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const data = await API.graphql(graphqlOperation(onUpdateConnectionChatUser)).subscribe({
				next: async (messageData) => {
					const graphAid = await gfUserByAid(`${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listDirect = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 1, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listGroup = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 2, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					connection_state.directData.set({ ...connection_state.directData.data, base: listDirect, list: listDirect })
					connection_state.groupData.set({ ...connection_state.groupData.data, base: listGroup, list: listGroup })
				}
			});
			return () => data.unsubscribe();
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const data = await API.graphql(graphqlOperation(onCreateConnectionChatUser)).subscribe({
				next: async (messageData) => {
					const graphAid = await gfUserByAid(`${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listDirect = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 1, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listGroup = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 2, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					connection_state.directData.set({ ...connection_state.directData.data, base: listDirect, list: listDirect })
					connection_state.groupData.set({ ...connection_state.groupData.data, base: listGroup, list: listGroup })
				}
			});
			return () => data.unsubscribe();
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const __init1 = async () => {
			const data = await API.graphql(graphqlOperation(onUpdateConnectionChat)).subscribe({
				next: async (messageData) => {
					const graphAid = await gfUserByAid(`${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listDirect = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 1, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					const listGroup = await gfListConnectionChatUsers(graphAid.data.userByAid.items[0].id, 2, 1000, `${window.atob(__SESSION.ainfo.aid).split("_")[1]}`)
					connection_state.directData.set({ ...connection_state.directData.data, base: listDirect, list: listDirect })
					connection_state.groupData.set({ ...connection_state.groupData.data, base: listGroup, list: listGroup })
				}
			});
			return () => data.unsubscribe();
		}
		__init1()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection_state.trigger.data])

	// useEffect(async () => {
	// 	const allData = []
	// 	const grabData = []
	// 	let token = null

	// 	const listConnectionChats = /* GraphQL */ `
	// 	query ListConnectionChats( $filter: ModelConnectionChatFilterInput $limit: Int $nextToken: String) {
	// 	listConnectionChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
	// 	items {id}
	// 	nextToken
	// 	}
	// 	}
	// 	`;

	// 	do {
	// 		const listconnection = await API.graphql(graphqlOperation(listConnectionChats, {nextToken: token}))
	// 		token = listconnection.data.listConnectionChats.nextToken
	// 		allData.push(...listconnection.data.listConnectionChats.items)
	// 	} while (token !== '' && token !== null);
	// 	console.log(allData)

	// 	for (let i = 0; i < allData.length; i++) {
	// 		try {
	// 			let upconnection = await API.graphql(graphqlOperation(updateConnectionChat, {input: {
	// 				id: allData[i].id,
	// 				isActive: 1
	// 			}}))
	// 			console.log(upconnection)
	// 		} catch (error) {
	// 			return console.log(error)
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// useEffect(async () => {
	// 	// return
	// 	const allData = []
	// 	const grabData = []
	// 	let token = null

	// 	const listMessages2 = /* GraphQL */ `
	// 	query ListMessages(
	// 		$filter: ModelMessageFilterInput
	// 		$limit: Int
	// 		$nextToken: String
	// 	) {
	// 		listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
	// 		items {
	// 			id
	// 			connectionChatID
	// 			attachments {
	// 				nextToken
	// 				items {
	// 					id
	// 					messageId
	// 				}
	// 			}
	// 			__typename
	// 		}
	// 		nextToken
	// 		__typename
	// 		}
	// 	}
	// 	`;

	// 	do {
	// 		const listMessage = await API.graphql(graphqlOperation(listMessages2, {nextToken: token}))
	// 		token = listMessage.data.listMessages.nextToken
	// 		allData.push(...listMessage.data.listMessages.items)
	// 	} while (token !== '' && token !== null);
	// 	console.log(allData)
	// 	// return

	// 	for (let i = 0; i < allData.length; i++) {
	// 		try {
	// 			if (allData[i].attachments.items.length > 0) {
	// 				for (let ii = 0; ii < allData[i].attachments.items.length; ii++) {
	// 					// const element = array[ii];
	// 					console.log(allData[i].attachments.items[ii].connectionChatId)
	// 					if (allData[i].attachments.items[ii].connectionChatId === null || allData[i].attachments.items[ii].connectionChatId === undefined) {
	// 						console.log(allData[i].attachments.items[ii].id)
	// 						console.log(allData[i].connectionChatID)
	// 						let upconnection = await API.graphql(graphqlOperation(updateAttachment, {input: {
	// 							id: allData[i].attachments.items[ii].id,
	// 							connectionChatId: allData[i].connectionChatID
	// 						}}))

	// 					}

	// 				}

	// 			}
	// 			// console.log(upconnection)
	// 		} catch (error) {
	// 			return console.log(error)
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// useEffect(() => {
	// 	const data = socket.emit("join_room", {
	// 		userName: getEmail.ainfo.email, roomID: conId, socket: socket.id
	// 	})
	// 	socket.on("all users", users => {
	// 		console.log("users conversation===>", users);
	// 		calling_state.socketUsers.set(users);
	// 	});
	// }, [])

	return (
		<Box width="100%" height="100%" sx={{ borderRadius: '12px 12px 6px 6px', backgroundColor: '#EEECF7' }}>
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#FAFAFF' }}>
								<Logo size={60} />
								<Box mt={4}>
									<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
								</Box>
							</Box>
						}>
							{
								!userLog ?
									<OnBoardingFunctions webAppVersion={webAppVersion} setUserLog={setUserLog} />
								:
									<>
										<WebRoutePsl connection_state={connection_state} />
									</>
							}
							<FunctionNotification />
							<IncomingCallSound ringingPlay={ringingPlay} />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Logo size={60} />
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<Suspense fallback={<SnakeLoader bg="#000000" size="0.75rem" distance="0.3rem" />} >
				<InstallNotification notifChecker={notifChecker} setNotifChecker={setNotifChecker} />
				<InstallPromotion install_state={install_state} />
				<FunctionPromt />
				<ModalUpdateFunction openVal={vcVal} dataVal={vcData} newVerVal={webAppVersion} />
			</Suspense>
			<SuperSnackbar />
			<SuperSnackbarUnsuc/>
		</Box>
	)
}
import { useState } from 'react';

export const UserValidateState = () => {
    const [isPersonal, setIsPersonal] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [userLog, setUserLog] = useState(false)
    return{
        accntTypeValidate: {data: isPersonal, set: setIsPersonal},
        accntTypeValidateBiz: {data: isBusiness, set: setIsBusiness},
        userLogValidate:{data:userLog, set: setUserLog}
    }
}
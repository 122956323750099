

import { lazy, useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom"
import { VM } from '../../../../../core/VM';
import { InactiveGroup, LogoBiz, MessageIcon } from '../../../../../core/global/Icons';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { domain, env, key } from '../../../../../core/Env';
import { useMediaQuery } from '@mui/material';
//dynamic imports
let view = VM();
const IndexBottom = lazy(() => import(`.${view}/Index`))

export const FunctionBottom = () => {
    const { connection_state, navigation_state, tool_state, install_state  } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { myTool, notif } = navigation_state
    const Loc = useLocation().pathname.split('/')
    const xsm = useMediaQuery('(max-width:350px)');

    //variables
    let nav = new useHistory();
    let location = useLocation().pathname.split('/')
    let allDirect = connection_state.directData.data.base.map(data => data)
    let allGroup = connection_state.groupData.data.base.map(data => data)
    const listMenu = {
            home:[
                {pageState: 1, icon: MessageIcon, text: 'Chats', active: false, directory: '/psl', category: undefined,handlerChange:(2)},
                {pageState: 2, className:"third-step", text: 'Group',icon: InactiveGroup, active: false, directory: '/psl/group', category: 'group',handlerChange:(3)},
            ],
            pro:[
                {pageState: 1, icon: MessageIcon, text: 'Pro Chats', active: false, directory: '/psl/pro',  category: undefined,handlerChange:(2)},
                {pageState: 2, className:"third-step", text: 'Communities',icon: InactiveGroup, active: false, directory: '/psl/pro/communities',  category: 'communities', handlerChange:(3)},
            ],
        }
    //handlers/functions/validations
    const handleClick  = (data, index) =>{
        nav.push(data.directory)
        connection_state.directData.set({ ...connection_state.directData.data, list: allDirect })
        connection_state.groupData.set({ ...connection_state.groupData.data, list: allGroup })
    }

    const handlerNavBtn = (v) => {
        nav.push(v)
        if (myTool.data) {
            myTool.set(false)
        }
        if (notif.data) {
            notif.set(false)
        }
    }

    const installHandler = async () => {
        if (install_state.prompt.data !== null) {
            install_state.prompt.data.prompt();
            const { outcome } = await install_state.prompt.data.userChoice;

            if (outcome === 'accepted') {
                install_state.ip.set({stat: false, prompt_type: null})
                install_state.prompt.set(null)
                if (install_state.ip.data.prompt_type !== 'logout') return
                localStorage.removeItem('your_psl_session')
                window.location.href = `${domain('pm').url}/sso/rqx?from=${key('tsa_key')}&callback=${env()==='dev' || env()==='local'?'development':env()==='sb'?'sandbox':env()}&platform=personal`
            }
        }

    }

    const display = __SESSION.data.pinfo.length > 0 ? __SESSION.data.pinfo[0].profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile : LogoBiz : LogoBiz : LogoBiz
    
    return (
        <>
            <IndexBottom
                listMenu={listMenu}
                handleClick={handleClick}
                location={location}
                Loc={Loc}
                xsm={xsm}
                installHandler={installHandler}
                handlerNavBtn={handlerNavBtn}
                install_state={install_state}
                navigation_state={navigation_state}
                myTool={myTool}
                nav={nav}
                notif={notif}
                display={display}
            />
         </>
    )
}
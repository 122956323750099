import { useState } from 'react'

export const ConnectionProfileState = () => {
    const [conProfileData, setConProfileData] = useState({open: false, data: [], ref: null})
    const [conGroupProfileData, setConGroupProfileData] =  useState({open: false, data: [], ref: null})
    return {
        conprodata:{data: conProfileData, set: setConProfileData},
        congrodata:{data: conGroupProfileData, set: setConGroupProfileData},
    }
}

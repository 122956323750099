import { FunctionTop } from './top/FunctionTop';
import { FunctionLeft } from './left/FunctionLeft';
import { FunctionBottom } from './bottom/FunctionBottom';
import { FunctionTopConvo } from './topConvo/FunctionConvo';
import { FunctionBtmConvo } from './bottomConvo/FunctionConvo';

const Navigation = ({ side, propvals }) => {
    let Nav = [FunctionTop, FunctionLeft, FunctionBottom, FunctionTopConvo, FunctionBtmConvo]
    Nav = Nav[side]
    return (
        <Nav props={propvals}/>
    )
}

export default Navigation;
import React, { lazy, useContext, useState } from 'react'
import { VM } from '../../../../core/VM';
import { ToolContext } from '../../../../core/context/ToolContext';
import { socket } from '../../../../service/socket';
let view = VM();
const IndexRecording = lazy(() => import(`./Index`))

export default function FuncationRecording() {
    const [selected, setSelected] = useState(null)
    const { calling_state } = useContext(ToolContext)
    let callRecord = calling_state.openCallRecord
    let callRecordPermission = calling_state.recordingPermission

    const recorderType = [
        {
            id: 'start recording',
            name: 'Start Recording',
        },
        {
            id: 'disable recording',
            name: 'Disable Recording',
        },
    ]

    const handleClose = () => {
        callRecordPermission.set(selected)
        if (selected) {
            socket.emit('recordingPermission', selected);
        }
        callRecord.set(false)
        setSelected(null)
    };

    return (
        <IndexRecording selected={selected} setSelected={setSelected} callRecord={callRecord} callRecordPermission={callRecordPermission} recorderType={recorderType} handleClose={handleClose} />
    )
}

import { lazy, useContext } from 'react';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { useHistory, useLocation } from "react-router-dom"
import { VM } from '../../../../../core/VM';
import { makeStyles } from '@mui/styles';
let view = VM();
const IndexLeft = lazy(() => import(`.${view}/Index`))

export const FunctionLeft = () => {
    //states/variables/context values
    const { navigation_state, install_state, layout_drawer_state, con_profile_state, dialog_state, connection_state } = useContext(ToolContext)
    let allDirect = connection_state.directData.data.base.map(data => data)
    let allGroup = connection_state.groupData.data.base.map(data => data)
    let nav = new useHistory();
    let congrostate = con_profile_state.congrodata
    let promtprop = dialog_state.prompt
    const __LOCATION = useLocation().pathname
    const { tc } = navigation_state
    //functions
    const GPromtCaller = (open, title, content, btnTxt1, btnTxt2, btnStat1, btnStat2) => {
        promtprop.set({open: open, title:  title, content: content, btnTxt1: btnTxt1, btnTxt2: btnTxt2, btnStat1: btnStat1, btnStat2: btnStat2})
    }

    const installPromptHandler = () => {
		setTimeout(() => {
			install_state.ip.set({stat: true, prompt_type: 'install_btn'})
		});
	}
    // const drawerHandler = () => {
    //     tc.set({...tc.data, open: !tc.data.open, list: false})
    // }
    const categoryHandler = (category) => {
        nav.push(category)
    }
    const ctypeHandler = (ctype, loc) => {
        nav.push(`/psl${loc.ct === 'pro' ? ctype.cc === 'community' ? '/pro/communities' : '/pro' : ctype.cc === 'group' ? '/group' : ''}`)
    }
    const supportHandler = (e) => {
        window.chaport.q.open();
    }
    const closeinfohandler = () => {
        congrostate.set({...congrostate.data, open: false})
    }
    //styles
    const width = 278
    const useStyles = makeStyles((theme) => ({
        drawer_paper: {
            marginLeft: 0,
            width: tc.data.open ? width : 76,
        },
        drawerToggle: {
            width: tc.data.open ? width : 76,
            overflowX: "hidden",
            whiteSpace: 'nowrap',
            border: '0px solid white',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[tc.data.open ? 'leavingScreen' : 'enteringScreen'],
            }),
        },
        toggler_button: {
            position: 'fixed !important',
            overflowX: "hidden",
            top: 96,
            left: tc.data.open ? width : 76,
            zIndex: 100,
            whiteSpace: 'nowrap',
            border: '0px solid white',
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[tc.data.open ? 'leavingScreen' : 'enteringScreen'],
            }),
        },
        act_list_item: {    
            cursor: 'pointer',
            padding: '10px',
            boxShadow: 'none',
            backgroundColor: '#E7E7FA',
            borderRadius: '6px',
            transition: '0.3s',
            '&:hover': {
                backgroundColor: '#E7E7FA',
            }
        },
        inact_list_item: {
            cursor: 'pointer',
            padding: '10px',
            boxShadow: 'none',
            backgroundColor: '#FFFFFF',
            borderRadius: '6px',
            transition: '0.3s',
            '&:hover': {
                backgroundColor: '#E7E7FA',
            }
        }
    }));
    const classes = useStyles();
    let locate = __LOCATION.split('/')
    let loc = locate[2] === 'pro' ? locate[3] === 'communities' ? {ct: 'pro', cc: 'community'}:{ct: 'pro', cc: 'direct'}  : locate[2] === 'group' ? {ct: 'home', cc: 'group'} : {ct: 'home', cc: 'direct'}
    return (
        <IndexLeft 
            loc={loc}
            tc={tc}
            layout_drawer_state={layout_drawer_state}
            installPromptHandler={installPromptHandler}
            classes={classes}
            categoryHandler={categoryHandler}
            ctypeHandler={ctypeHandler}
            supportHandler={supportHandler}
            closeinfohandler={closeinfohandler}
            GPromtCaller={GPromtCaller}
            navigation_state={navigation_state}
            install_state={install_state}
            allDirect={allDirect}
            allGroup={allGroup}
            connection_state={connection_state}
        />
    )
}

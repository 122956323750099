import { lazy, useContext, useEffect, useState, useRef } from "react";
import { VM } from "../../../core/VM";
import { ToolContext } from "../../../core/context/ToolContext";
import { useLocation,useHistory } from "react-router-dom";
import { gfGetConnectionChat, gfMessageByConnectionChat } from "../../../core/global/graphqlFunc/GraphqlFunctions";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { onCreateAttachment, onCreateMessage, onUpdateMessage } from "../../../graphql/subscriptions";
import Prompt_Vector2 from '../../../assets/images/core/prompt_vector3.png'
import { PromptModalDesk } from "../../../core/global/modal/PromptModal";
let view = VM();
const IndexGroup = lazy(() => import(`.${view}/Index`))

export const FunctionGroup = ({match}) => {
    const { connection_state, conversation_state, tool_state } = useContext(ToolContext)
    let csdata = conversation_state.chatData.data
    const elementRef = useRef(null);
    const __LOCATION = useLocation().pathname
    const loc = __LOCATION.split('/')
    let nav = useHistory()
    const [loader, setLoader] = useState(false)
    const [withData, setWithData] = useState(false)
    const [notUserWarning, setNotUserWarning] = useState(false)
    const profInfo = tool_state.__SESSION.data.pinfo
    
    // console.log(csdata);
    const updateListenerMessage = (message) => {
		let dataChat = [...csdata.cdata]
		let shoveInsert = dataChat.map((d) =>  d.id === message.id ? message : d)
		conversation_state.chatData.set({...csdata, cdata: shoveInsert})
        let chatcontent = document.getElementById('chatcontentscroller');
        chatcontent.scrollTop = chatcontent.scrollHeight ;
	}
    
    const addListenerMessage = (message) => {
        conversation_state.chatData.set({...csdata, cdata: [...csdata.cdata, message]})
        let chatcontent = document.getElementById('chatcontentscroller');
        chatcontent.scrollTop = chatcontent.scrollHeight ;
    }

    const notUserHandler = () => {
        let loc = match.url.includes('group') ? '/psl/group' : match.url.includes('communities') ? '/psl/pro/communities' : match.url.includes('communities') ? '/psl/pro' : '/psl'
        nav.push(loc)
    }
    
    useEffect(() => {
        const s = API.graphql(graphqlOperation(onCreateMessage, {filter:{connectionChatID: {eq: match.params.conId }}})).subscribe({
            next: (messageData) => {
                let nm = messageData.value.data.onCreateMessage
                addListenerMessage(nm)
            }
        });
        return () => s.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csdata.cdata])

    useEffect(() => {
        const s = API.graphql(graphqlOperation(onUpdateMessage, {filter:{connectionChatID: {eq: match.params.conId }}})).subscribe({
            next: (messageData) => {
                let nm = messageData.value.data.onUpdateMessage
				updateListenerMessage(nm)
			}   
		});
		return () => s.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [csdata.cdata])

    useEffect(() => {
        const s = API.graphql(graphqlOperation(onCreateAttachment, {filter:{connectionChatId: {eq: match.params.conId }}})).subscribe({
            next: (attachData) => {
                let nm = attachData.value.data.onCreateAttachment
                let dataAttach = conversation_state.chatData.data.cdata
                let pushAttach = dataAttach.map((d) => d.id === nm.messageId ? {...d, attachments: {...d.attachments, items: [{...nm}]}} : d)
                conversation_state.chatData.set({...conversation_state.chatData.data, cdata: pushAttach})
            }
        });
        return () => s.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csdata.cdata])

    useEffect(() => {
        setLoader(false)
        
        const element = elementRef.current;
        const __initConnection = async() => {
            let getcdata = await gfGetConnectionChat(loc[4])
            let conData = getcdata.data
            if (conData.getConnectionChat.type === 2) {
                let getUser = conData.getConnectionChat.connectionChatUsers.items.filter((data) => data.userID === tool_state.__SESSION.data.graphAid.id)
                if (getUser.length > 0) {
                    let getStatus = getUser.filter((d) => d.status !== 'removed')
                    if (getStatus.length > 0) {
                        let getGroupInfo = conData.getConnectionChat
                        conversation_state.tdData.set({...conversation_state.tdData.data, dp: getGroupInfo.connectionProfile, dn: getGroupInfo.connectionName, id: getGroupInfo.id})
                        // conversation_state.aDataLoader.set({...conversation_state.attachData.data, assdata: conData.getConnectionChat.attachments.items})
                        __chatInit(conData)
                    } else {
                        console.log('not a user')
                        setNotUserWarning(true)
                    }
                } else {
                    console.log('not a user')
                    setNotUserWarning(true)
                }
            } else {
                console.log('guard clause for errors')
            }
        }
        const __chatInit = async(conData) => {
            let compileData = []
            let nextTokenAct = csdata.nextToken
            let chatStart = ''
            while (compileData.length < 10) {
                let data = await gfMessageByConnectionChat( loc[4], 20, 'DESC')
                compileData = [...compileData, ...data.data.messagesByConnectionChat.items]
                nextTokenAct = data.data.messagesByConnectionChat.nextToken
                if (nextTokenAct === null) {
                    if (compileData.length > 0) {
                        let lastDate = compileData.sort(function(a,b){
                            return new Date(a.createdAt) - new Date(b.createdAt);
                        })
                        chatStart = moment(lastDate[0].createdAt).format('ddd, MMMM DD, YYYY')
                    }
                    break
                }
            }
            let sorted = compileData.sort(function(a,b){
                return new Date(a.createdAt) - new Date(b.createdAt);
            })
            conversation_state.chatData.set({...csdata, cdata: sorted,  chatStarted: chatStart, nextToken: nextTokenAct})
            __finalInitialize()
            if (element) {
                const { scrollHeight } = element;
                element.scrollTop = scrollHeight;
                let chatcontent = document.getElementById('chatcontentscroller');
                chatcontent.scrollTop = chatcontent.scrollHeight ;
            }
        }
        const __finalInitialize = () => {
            setWithData(connection_state.directData.data.list.length > 0)
            setLoader(true)
        }
        if (loc[3] === 'c') {
            __initConnection()
        } else {
            setTimeout(() => {
                __finalInitialize()
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [__LOCATION])
    
    return (
        <>
            <IndexGroup
                match={match}
                loader={loader}
                withData={withData}
                loc={loc}
                profInfo={profInfo}
                elementRef={elementRef}
            />
            <PromptModalDesk isOpen={notUserWarning} header={Prompt_Vector2} title= "Oh no Hero User, Seems like you are not a part of this connection."  btnLeftText={'Got it!'} btnLeft={(e) => {notUserHandler(); setNotUserWarning(false);}}/>
        </>
    )
}

import { lazy, useContext, useEffect, useState, useRef } from "react";
import { VM } from "../../../core/VM";
import { ToolContext } from "../../../core/context/ToolContext";
import { gfGetConnectionChat, gfMessageByConnectionChat } from "../../../core/global/graphqlFunc/GraphqlFunctions";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { PromptModalDesk } from "../../../core/global/modal/PromptModal";
import Prompt_Vector2 from '../../../assets/images/core/prompt_vector3.png'

let view = VM();
const IndexHome= lazy(() => import(`.${view}/Index`))

export const FunctionHomeBiz = ({match}) => {
    const { connection_state, dialog_state, tool_state, install_state, conversation_state } = useContext(ToolContext)
    let csdata = conversation_state.chatData.data
    let nav = useHistory()
    const elementRef = useRef(null);
    const __LOCATION = useLocation().pathname
    const loc = __LOCATION.split('/')
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const profInfo = tool_state.__SESSION.data.pinfo
    const [loader, setLoader] = useState(false)
    const [withData, setWithData] = useState(false)
    const [notUserWarning, setNotUserWarning] = useState(false)
    const newMsgHandlerSD = (e, location) => {
        dialog_state.mobDirect.set({ ...dialog_state.mobDirect.data, dlgStat: true })
        // if (location.cc === 'pro') {
        //     console.log(location)
        // } else if (location.cc === 'group') {
        //     drawer_state.newgcinfo.set({ ...drawer_state.newgcinfo.data, open: true })
        // } else {
            
        // }
    }

    useEffect(() => {
        setLoader(false)
        const element = elementRef.current;
        const __initConnection = async () => {
            let getcdata = await gfGetConnectionChat(loc[3])
            let conData = getcdata.data
            if (conData.getConnectionChat.type === 1) {
                if (conData.getConnectionChat.connectionChatUsers.items.filter((data) => data.userID === tool_state.__SESSION.data.graphAid.id).length > 0) {
                    let getConvoUser = conData.getConnectionChat.connectionChatUsers.items.filter((data) => data.userID !== tool_state.__SESSION.data.graphAid.id)
                    conversation_state.tdData.set({ ...conversation_state.tdData.data, dp: getConvoUser[0].user.profileImg, dn: getConvoUser[0].user.name, id: getConvoUser[0].userID })
                    __chatInit(conData)
                } else {
                    console.log('not a user')
                    setNotUserWarning(true)
                }
            } else {
                console.log('guard clause for errors')
            }
        }
        const __chatInit = async (conData) => {
            let compileData = []
            let nextTokenAct = csdata.nextToken
            let chatStart = ''
            while (compileData.length < 10) {
                let data = await gfMessageByConnectionChat(loc[3], 20, 'DESC')
                compileData = [...compileData, ...data.data.messagesByConnectionChat.items]
                nextTokenAct = data.data.messagesByConnectionChat.nextToken
                if (nextTokenAct === null) {
                    if (compileData.length > 0) {
                        let lastDate = compileData.sort(function (a, b) {
                            return new Date(a.createdAt) - new Date(b.createdAt);
                        })
                        chatStart = moment(lastDate[0].createdAt).format('ddd, MMMM DD, YYYY')
                    }
                    break
                }
            }
            let sorted = compileData.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            })
            conversation_state.chatData.set({ ...csdata, cdata: sorted, chatStarted: chatStart, nextToken: nextTokenAct })
            __finalInitialize()
            if (element) {
                const { scrollHeight } = element;
                element.scrollTop = scrollHeight;
                let chatcontent = document.getElementById('chatcontentscroller');
                chatcontent.scrollTop = chatcontent.scrollHeight;
            }
        }
        const __finalInitialize = () => {
            setWithData(connection_state.directData.data.list.length > 0)
            setLoader(true)
        }
        if (loc[2] === 'c') {
            __initConnection()
        } else {
            setTimeout(() => {
                __finalInitialize()
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [__LOCATION])

    const notUserHandler = () => {
        let loc = match.url.includes('group') ? '/psl/group' : match.url.includes('communities') ? '/psl/pro/communities' : match.url.includes('communities') ? '/psl/pro' : '/psl'
        nav.push(loc)
    }
    return (
        <>        
            <IndexHome 
                windowSize={windowSize}
                profInfo={profInfo}
                newMsgHandlerSD={newMsgHandlerSD}
                install_state={install_state}
                loader={loader}
                withData={withData}
            />
            <PromptModalDesk isOpen={notUserWarning} header={Prompt_Vector2} title="Oh no Hero User, Seems like you are not a part of this connection." btnLeftText={'Got it!'} btnLeft={(e) => { notUserHandler(); setNotUserWarning(false); }} />
        </>

    )
}

import { Box } from '@mui/system'
import { Logo } from '../global/Icons'
import { SnakeLoader } from './SnakeLoader'

export const Loader = () => {
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Logo size={60}/>
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    )
}

import { API, graphqlOperation } from "aws-amplify"
import { checkVersionsByUserID, listAttachments, listCallFeedBacks } from '../../../graphql/queries';
import { createAttachment, createCallFeedBack, createCheckVersion, createConnectionChat, createConnectionChatUser, createMessage, createUser, updateConnectionChat, updateConnectionChatUser, updateMessage, createForward, updateUser } from '../../../graphql/mutations';

export const gfUserByAid = async (aid) => {
  const userByAid1 = /* GraphQL */ `
  query UserByAid(
    $aid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAid(
      aid: $aid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        connectionChatUser {
          nextToken
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
  const graphAid = await API.graphql(graphqlOperation(userByAid1, { aid: aid }))
  return graphAid
}
export const gfCheckVersionsByUserID = async (graphAid) => {
  const checkVersion = await API.graphql(graphqlOperation(checkVersionsByUserID, { userID: graphAid }))
  return checkVersion
}


export const gfListAttachment = async (connectionId, token) => {
  const listAttach = await API.graphql(graphqlOperation(listAttachments, token === "" ? { filter: { connectionChatId: { eq: connectionId } }, limit: 100 } : { filter: { connectionChatId: { eq: connectionId } }, limit: 100, nextToken: token }))
  return listAttach

}

export const gfGetUser = async (aid) => {
  const getUser1 = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
        id
        aid
        name
        profileImg
        status
        userType
        connectionChatUser {
            items {
          id
          userID
          connectionChatID
          nickName
          addedBy
          role
          status
          connectionType
          createdAt
          updatedAt
          connectionChat {
            updatedAt
            connectionName
            connectionProfile
            connectionChatType
            isActive
            status
            lastmsg {
              content
              status
              id
              user {
                aid
                id
                name
              }
            }
            connectionChatUsers {
              items {
                user {
                  id
                  name
                  profileImg
                  aid
                  status
                  userType
                }
              }
            }
          }
        }
            nextToken
            __typename
        }
        createdAt
        updatedAt
        __typename
        }
    }
    `;
  const graphUser = await API.graphql(graphqlOperation(getUser1, { id: aid },))
  return graphUser
}

export const gfMessageByConnectionChat = async (cid, limit, sort, ntoken) => {
  const messagesByConnectionChat1 = /* GraphQL */ `
    query MessagesByConnectionChat(
        $connectionChatID: ID!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelMessageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        messagesByConnectionChat(
        connectionChatID: $connectionChatID
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        ) {
        items {
            id
            createdAt
            updatedAt
            deletedAt
            content
            userID
            status
            isSeen
            repliedID
            repliedMsg {
                id
                createdAt
                updatedAt
                deletedAt
                content
                userID
                status
                isSeen
                repliedID
                connectionChatID
            }
            connectionChatID
            user {
                id
                aid
                name
                profileImg
                status
                userType
                createdAt
                updatedAt
            }
            connectionChat {
                id
                connectionChatType
                isActive
                status
                type
                connectionName
                connectionProfile
                createdBy
                lastmsgID
                createdAt
                updatedAt
            }
            attachments {
            items {
                id
                url
                type
                size
                messageId
                createdAt
                updatedAt
                name
            }
            nextToken
            }
            __typename
        }
        nextToken
        __typename
        }
    }
    `;


  if (ntoken === '' || ntoken === undefined) {
    const data = await API.graphql(graphqlOperation(messagesByConnectionChat1, { connectionChatID: cid, limit: limit, sortDirection: sort }))
    return data
  } else {
    const data = await API.graphql(graphqlOperation(messagesByConnectionChat1, { connectionChatID: cid, limit: limit, sortDirection: sort, nextToken: ntoken }))
    return data
  }
}

export const gfListCallFeedBacks = async (userID, ntoken) => {
  const listCallFeedBack = await API.graphql(graphqlOperation(listCallFeedBacks, ntoken === "" ? { filter: { userID: { eq: userID } }, limit: 100 } : { filter: { userID: { eq: userID } }, limit: 100, nextToken: ntoken }))
  return listCallFeedBack
}

export const gfGetConnectionChat = async (id) => {
  const getConnectionChat = /* GraphQL */ `
    query GetConnectionChat($id: ID!) {
      getConnectionChat(id: $id) {
        id
        connectionChatType
        connectionChatUsers {
          items {
            id
            userID
            connectionChatID
            nickName
            addedBy
            role
            status
            connectionType
            createdAt
            updatedAt
            user {
              profileImg
              name
              aid
              status
              userType
            }
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            createdAt
            updatedAt
            deletedAt
            content
            userID
            status
            isSeen
            repliedID
            connectionChatID
          }
          nextToken
          __typename
        }
        attachments {
          items {
            id
            messageId
            name
            size
            type
            url
          }
          nextToken
          __typename
        }
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        lastmsg {
          id
          createdAt
          updatedAt
          deletedAt
          content
          userID
          status
          isSeen
          repliedID
          repliedMsg {
            id
            createdAt
            updatedAt
            deletedAt
            content
            userID
            status
            isSeen
            repliedID
            connectionChatID
          }
          connectionChatID
          user {
            id
            aid
            name
            profileImg
            status
            userType
            createdAt
            updatedAt
          }
          connectionChat {
            id
            connectionChatType
            type
            connectionName
            connectionProfile
            isActive
            status
            createdBy
            lastmsgID
            createdAt
            updatedAt
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
    }
  `;


  const cdata = await API.graphql(graphqlOperation(getConnectionChat, { id: id }))
  return cdata
}

export const gfGetConnectionChatFiltered = async (ids, filterer) => {
  const getConnectionChats = /* GraphQL */ `
    query GetConnectionChat($id: ID!, $status: String) {
        getConnectionChat(id: $id) {
            id
            connectionChatType
            connectionChatUsers(filter: {status: {ne: $status}}) {
                items { id userID connectionChatID nickName addedBy role status connectionType createdAt updatedAt
                    user { profileImg name aid status userType}
                }
                nextToken
            }
        messages {
            items { id createdAt updatedAt deletedAt content userID status isSeen repliedID connectionChatID } nextToken
        }
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        lastmsg { id createdAt updatedAt deletedAt content userID status isSeen repliedID
            repliedMsg { id createdAt updatedAt deletedAt content userID status isSeen repliedID connectionChatID }
            connectionChatID
            user { id aid name profileImg status userType createdAt updatedAt }
            connectionChat { id connectionChatType type connectionName connectionProfile isActive status createdBy lastmsgID createdAt updatedAt }
            attachments { nextToken }
        }
        createdAt
        updatedAt
    }
}`;
  const cdata = await API.graphql(graphqlOperation(getConnectionChats, { id: ids, status: filterer }));
  return cdata
}

export const gfconnectionChatUsersByUserIDAndConnectionChatID = async (aid, connectionType) => {
  const connectionChatUsersByUserIDAndConnectionChatID1 = /* GraphQL */ `
  query ConnectionChatUsersByUserIDAndConnectionChatID(
    $userID: ID!
    $connectionChatID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionChatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionChatUsersByUserIDAndConnectionChatID(
      userID: $userID
      connectionChatID: $connectionChatID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        connectionChatID
        nickName
        addedBy
        role
        status
        connectionType
        user {
          id
          aid
          name
          profileImg
          status
          userType
          createdAt
          updatedAt
        }
        connectionChat {
          id
          connectionChatType
          isActive
          status
          type
          connectionName
          connectionProfile
          createdBy
          lastmsgID
          createdAt
          updatedAt
          connectionChatUsers {
          nextToken
          items {
            userID
            connectionType
          }
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
  if (connectionType === 'all') {
    const graphccubuiaci = await API.graphql(graphqlOperation(connectionChatUsersByUserIDAndConnectionChatID1, { userID: aid }))
    return graphccubuiaci
  } else {
    const graphccubuiaci = await API.graphql(graphqlOperation(connectionChatUsersByUserIDAndConnectionChatID1, { userID: aid }, { filter: { connectionType: { eq: connectionType } } }))
    return graphccubuiaci
  }
}

export const gfCreateUser = async (aid, name, profileImg, status, userType) => {
  try {
    const cu = await API.graphql(graphqlOperation(createUser, {
      input: {
        aid: aid,
        name: name,
        profileImg: profileImg,
        status: status,
        userType: userType,
      }
    }))
    return cu
  } catch (error) {
    console.log(error);
  }
}

export const gfCreateCallFeedBack = async (inputData) => {
  try {
    const feedbackDetails = await API.graphql(graphqlOperation(createCallFeedBack, { input: inputData }))
    return feedbackDetails
  } catch (error) {
    console.log(error);
  }

}

export const gfCreateConnectionChat = async (inputData) => {
  try {
    const ccon = await API.graphql(graphqlOperation(createConnectionChat, { input: inputData }))
    return ccon
  } catch (error) {
    console.log(error);
  }
}

export const gfCreateConnectionChatUser = async (inputData) => {
  try {
    const cccu = await API.graphql(graphqlOperation(createConnectionChatUser, { input: inputData }))
    return cccu
  } catch (error) {
    console.log(error);
  }
}

export const gfCreateCheckVersion = async (userID, version) => {
  try {
    const ccv = await API.graphql(graphqlOperation(createCheckVersion, {
      input: {
        userID: userID,
        version: version,
      }
    }))
    return ccv
  } catch (error) {
    console.log(error);
  }
}

export const gfCreateMessage = async (input) => {
  try {
    const messageData = await API.graphql(graphqlOperation(createMessage, { input: input }))
    return messageData
  } catch (error) {
    console.log(error);
  }
}

export const gfCreateForward = async (input) => {
  try {
    const messageData = await API.graphql(graphqlOperation(createForward, { input: input }))
    return messageData
  } catch (error) {
    console.log(error);
  }
}


export const gfCreateAttachment = async (inputData) => {
  try {
    const attachmentData = await API.graphql(graphqlOperation(createAttachment, { input: inputData }))
    return attachmentData
  } catch (error) {
    console.log(error);
  }
}

export const gfUpdateMessage = async (inputData) => {
  try {
    let updateMsg = await API.graphql(graphqlOperation(updateMessage, { input: inputData }))
    return updateMsg
  } catch (error) {
    console.log(error)
  }
}

export const gfUpdateUser = async (inputData) => {
  try {
    let updateMsg = await API.graphql(graphqlOperation(updateUser, { input: inputData }))
    return updateMsg
  } catch (error) {
    console.log(error)
  }
}

export const gfUpdateConnectionChat = async (ccid, lmid) => {
  try {
    let updateCChat = await API.graphql(graphqlOperation(updateConnectionChat, {
      input: {
        id: ccid,
        lastmsgID: lmid
      }
    }))
    return updateCChat
  } catch (error) {
    console.log(error)
  }
}

export const gfUpdateConnectionChatProfile = async (input) => {
  try {
    let updateCChat = await API.graphql(graphqlOperation(updateConnectionChat, { input: input }))
    return updateCChat
  } catch (error) {
    console.log(error)
  }
}

export const gfUpdateConnectionChatUser = async (ccuid, status) => {
  try {
    let updateCChat = await API.graphql(graphqlOperation(updateConnectionChatUser, {
      input: {
        id: ccuid,
        status: status
      }
    }))
    return updateCChat
  } catch (error) {
    return console.log('error')
  }
}

export const gfUpdateConnectionChatUserAdmin = async (ccuid, role) => {
  try {
    let updateCChat = await API.graphql(graphqlOperation(updateConnectionChatUser, {
      input: {
        id: ccuid,
        role: role
      }
    }))
    return updateCChat
  } catch (error) {
    return console.log('error')
  }
}

export const gfUpdateProfileConnectionGroupChat = async (input) => {
  try {
    let updateCChat = await API.graphql(graphqlOperation(updateConnectionChat, { input: { input } }))
    return updateCChat
  } catch (error) {
    return console.log(error);
  }
}

export const loginMutation = `
  mutation MyMutation($loginUserID: ID!, $mode: String!, $device: String!) {
    pofInsertLoginInfo(loginUserID: $loginUserID, mode: $mode, device: $device) {
      id
      loginUserID
      mode
      device
      createdAt
    }
  }
`;

export const insertLoginInfo = async (userID) => {
  const variables = {
    loginUserID: userID,
    mode: "login",
    device: "web"
  };

  try {
    const response = await API.graphql(graphqlOperation(loginMutation, variables));
    console.log("Login info inserted:", response);
  } catch (error) {
    console.error("Error inserting login info:", error);
  }
};

export const gfListConnectionChatUsers = async (userID, chatType, limit = 1000, aid) => {
  const allData = []
  const grabData = []
  let token = ''
  const listConnectionChatUsersQuery = /* GraphQL */ `
    query ListConnectionChatUsers($filter: ModelConnectionChatUserFilterInput, $limit: Int, $nextToken: String){
        listConnectionChatUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items { updatedAt connectionChatID connectionType id nickName status
            connectionChat { connectionName connectionProfile connectionChatType isActive updatedAt
                lastmsg { content repliedID status updatedAt isSeen id userID 
                    user {aid id name}
                }
                connectionChatUsers {
                    items { id nickName role status 
                        user { aid name id status profileImg userType}
                    }
                }
            }
        }
        nextToken
    }}`;

  do {
    const listConnection = await API.graphql(graphqlOperation(listConnectionChatUsersQuery, token === "" ? { filter: { userID: { eq: userID }, connectionType: { eq: chatType }, status: { ne: "removed" } }, limit: limit } : { filter: { userID: { eq: userID }, connectionType: { eq: chatType }, status: { ne: "removed" } }, limit: limit, nextToken: token }))
    // console.log({filter: {userID: {eq: userID}, connectionType: {eq: chatType},status: {ne: "removed"}}, limit: limit, nextToken: token})
    // console.log('run')
    token = listConnection.data.listConnectionChatUsers.nextToken
    // console.log(listConnection)
    allData.push(...listConnection.data.listConnectionChatUsers.items)
    // console.log(listConnection.data.listConnectionChatUsers.nextToken)
  } while (token !== '' && token !== null);
  for (let i = 0; i < allData.length; i++) {
    if (allData[i].connectionType === chatType && allData[i].connectionChat?.isActive === 1) {
      let sd = {}
      sd.updatedAt = allData[i].connectionChat.updatedAt
      sd.connectionChatID = allData[i].connectionChatID
      sd.lastMessage = allData[i].connectionChat.lastmsg
      sd.connectedUser = allData[i].connectionChat.connectionChatUsers.items.filter((d) => d.user.aid !== aid)
      sd.connectionName = allData[i].connectionChat.connectionName
      sd.connectionProfile = allData[i].connectionChat.connectionProfile
      sd.connectionChatType = allData[i].connectionChat.connectionChatType
      grabData.push(sd)
    }
  }
  // console.log(grabData)
  let sorted = grabData.sort(function (a, b) {
    return new Date(a.updatedAt) - new Date(b.updatedAt);
  });
  // console.log(sorted)
  return sorted
}
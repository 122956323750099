import { useEffect, useState } from 'react'
import { lazy, useContext } from 'react'
import { VM } from '../../../../core/VM'
import { ToolContext } from '../../../../core/context/ToolContext';
import Peer from "simple-peer";
import { useHistory } from 'react-router-dom';
import useVideoLogic from '../../../../core/hooks/CustomHook';

let view = VM();
const IndexNotification = lazy(() => import(`./Index`))


export const FunctionNotification = () => {

  const { calling_state } = useContext(ToolContext)
  const callAccept = calling_state.callAccepted
  const { answerCall, leaveCall, receivingCall, name, callInfo, caller } = useVideoLogic();



  useEffect(() => {
    callInfo();
  }, [])

  return (
    <>
      <IndexNotification
        receivingCall={receivingCall}
        callAccepted={callAccept.data}
        name={name}
        answerCall={answerCall}
        leaveCall={leaveCall}
      />
    </>
  )
}

import { lazy, useContext, useLayoutEffect } from "react";
import { userByAid } from '../../../graphql/queries';
import { createCheckVersion } from '../../../graphql/mutations';
import { env } from '../../../core/Env';
import { rqx } from '../../../core/request/API';
import { VM } from '../../../core/VM';
import { ToolContext } from '../../../core/context/ToolContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { useKeenSlider } from "keen-slider/react"
let view = VM();
const IndexOnBoarding = lazy(() => import(`.${view}/Index`))

export const OnBoardingFunctions = ({ webAppVersion, setUserLog }) => {
    const { account_type_states } = useContext(ToolContext);
    const __SESSION = JSON.parse(localStorage.getItem('pof_chat_session'))
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#B0B7FF"
    });
    const [state, setState] = useState(false)
    const [keenSlide, setKeenSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, setSliderRef] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setKeenSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    })
    const handleClickScroll = () => {
        const element = document.getElementById('section-1');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const boardingUser = async () => {
        setUserLog(true);
    }

    const handlerLastStep = async () => {
        let body = {
            user_id: window.atob(__SESSION.ainfo.aid).split("_")[1],
            feature_name: 'pchatp_onboarding',
            tool_id: 0,
            personalization: [{
                status: 1,
                sf_name: 'onboarding'
            }],
            active: 1
        }
        let updatePsnlztn = await rqx.post(`<<global>>/${env()}/personalization/create`, body)
        const graphAid = await API.graphql(graphqlOperation(userByAid, { aid: `${window.atob(__SESSION.ainfo.aid).split("_")[1]}` }))
        try {
            await API.graphql(graphqlOperation(createCheckVersion, {
                input: {
                    userID: graphAid.data.userByAid.items[0].id,
                    version: webAppVersion,
                }
            }))
        } catch (error) {
            console.log(error);
        }
        if (updatePsnlztn.msg === 'success') {
            window.location.href = `/psl`
        } else {
            window.location.href = `/psl`
        }
    }
    return (
        <>
            <IndexOnBoarding setState={setState} state={state} handleClickScroll={handleClickScroll} setUserLog={account_type_states.userLogValidate.set} handlerLastStep={handlerLastStep} boardingUser={boardingUser} sliderRef={sliderRef} loaded={loaded} setSliderRef={setSliderRef} keenSlide={keenSlide} account_type_states={account_type_states} />
        </>
    )
}
import React, { lazy } from 'react'
import { VM } from '../../../../core/VM';
let view = VM();
const IndexRecordNotification = lazy(() => import(`./Index`))

export default function FunctionRecordNotification({ recordNotification }) {
    return (
        <IndexRecordNotification recordNotification={recordNotification} />
    )
}

import { io } from "socket.io-client";

const socket = io("https://api-socket-chat.pofsis.com", {
    cors: true,
})

// const socket = io("http://localhost:5000", {
//     cors: true,
// })

let getUserData = localStorage.getItem("pof_chat_session") || {}
let aid = null

// Check if user data is present in localStorage
if (Object.keys(getUserData).length > 0) {
    getUserData = JSON.parse(getUserData);
    aid = window.atob(getUserData.ainfo.aid).split("_")[1];
    getUserData.aid = aid;
}

// Register user on socket connection
socket.on("connect", () => {
    socket.emit("register_user", {
        ...getUserData,
        socket_id: socket.id
    })
});


export { socket };

// Placeholder for connecting user, should be implemented with specific event and data
export const connectUser = socket.emit("")  
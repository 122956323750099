import { lazy, useContext, useState } from "react";
import { VM } from "../../../../core/VM";
import { ToolContext } from "../../../../core/context/ToolContext";
import { env } from "../../../../core/Env";
import { rqx } from "../../../../core/request/API";
import { useHistory } from "react-router-dom"
import { gfCreateConnectionChat, gfCreateConnectionChatUser, gfCreateUser, gfUserByAid } from "../../../../core/global/graphqlFunc/GraphqlFunctions";
import { S3 } from "../../../../core/transmit/S3";
import { Box, SvgIcon } from "@mui/material";
import { CircleCheckFill } from "../../../../core/global/Icons";
let view = VM();

const IndexCreateGroup = lazy(() => import(`.${view}/Index`))
export const FunctionCreateGroup = () => {
    const { drawer_state, dialog_state, tool_state, gc_update_state, conversation_state, snack_state } = useContext(ToolContext);
    let gc_state_dialog = dialog_state.mobGroup
    let gc_state = drawer_state.newgcinfo
    let gc_state_dp = drawer_state.newgcDp
    let groupPhoto = conversation_state.attachGgdp
    const nav = new useHistory()
    const [withData, setWithData] = useState(false)
    const [searchData, setSearchData] = useState([])
    const [onceRun, setOnceRun] = useState(0)
    const [counterLoad, setCounterLoad] = useState(false)
    const [selectedAdd, setSelectedAdd] = useState([])
    const [swalStat, setSwalStat] = useState(false)
    const [startLoader, setStartLoader] = useState(true)
    const [selectedFiles, setSelectedFiles] = useState([]);
    
    const changeHandler = (e) => {
        setTimeout(async() => {
                if (e.target.value !== "") {
                let searchResult = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/searchuser`, {ref: "all", account_type: "1", search: e.target.value})
                setSearchData(searchResult.filter(d => d.user_id !== parseInt(window.atob(tool_state.__SESSION.data.ainfo.aid).split('_')[1])));
                if(searchResult.length === 0 ) {
                    setWithData(true)
                } else {
                    setWithData(false)
                }
            } else {
                setSearchData([]);
                setWithData(false)
            }
        }, 1000);
    }

    const selectHandler = async(e) => {
        if (!gc_state.data.members.includes(e)) {
            gc_state.set({...gc_state.data, members: [...gc_state.data.members, e]})
            setSelectedAdd([...selectedAdd, e])
        } else {
            let filtered = gc_state.data.members.filter((data) => data !== e)
            setSelectedAdd(filtered)
            gc_state.set({...gc_state.data, members: filtered})
        }
    }

    const proceedHandlerNew = async(e) => {
        if (onceRun === 0) {
            setOnceRun(1)
            setCounterLoad(true)
            let slctdmem = gc_state.data.members
            let compiledData = []
            let errors = {}
            if( gc_state_dp.data.stat !== false){
                for (let i = 0; i < slctdmem.length; i++) {
                        try {
                            const graphAid = await gfUserByAid(`${slctdmem[i].user_id}`)
                            if (graphAid.data.userByAid.items.length === 0) {
                                let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: window.btoa(`account_${slctdmem[i].user_id}`)})
                                const dataSampleGrab = await gfCreateUser(
                                    slctdmem[i].user_id, 
                                    `${slctdmem[i].first_name} ${slctdmem[i].last_name}`, 
                                    slctdmem[i].profile === null ? 'none' : JSON.parse(slctdmem[i].profile).profile === null ? 'none' : JSON.parse(slctdmem[i].profile).profile, 
                                    'online', 
                                    ainfo.account_type
                                )
                                compiledData.push(dataSampleGrab.data.createUser)
                            } else {
                                compiledData.push(graphAid.data.userByAid.items[0])
                            }
                        } catch (error) {
                            errors.ubAid = error
                        }
                }
                try {
                    const createConnection = await gfCreateConnectionChat({
                        lastmsgID: 'nullNoMessage',
                        connectionChatType: 'psl',
                        type: 2,
                        connectionName: gc_state.data.gcdata.gcName,
                        connectionProfile: gc_state_dp.data.newDp,
                        createdBy: `${tool_state.__SESSION.data.graphAid.id}`,
                        isActive: 1,
                    })
                    for (let ii = 0; ii < compiledData.length; ii++) {
                        await gfCreateConnectionChatUser( {
                            userID: compiledData[ii].id,
                            connectionChatID: createConnection.data.createConnectionChat.id,
                            addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                            role: 'member',
                            status: 'added',
                            connectionType: 2,
                        })
                    }
                    await gfCreateConnectionChatUser( {
                        userID: `${tool_state.__SESSION.data.graphAid.id}`,
                        connectionChatID: createConnection.data.createConnectionChat.id,
                        addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                        role: 'admin',
                        status: 'added',
                        connectionType: 2,
                    })
                    gc_state.set({open: false, gcdata: {gcName: '', gcdp: ''}, members: [], status: null})
                    gc_state_dialog.set({...gc_state_dialog.data, dlgStat: false, init: '', datastate: null})
                    nav.push(`/psl/group/c/${createConnection.data.createConnectionChat.id}`)
                    setCounterLoad(false)
                } catch (error) {
                    errors.cccUser = error
                }
                setTimeout(() => {
                    setOnceRun(0)
                }, 1000);
            } else {
                for (let i = 0; i < slctdmem.length; i++) {
                        try {
                            const graphAid = await gfUserByAid(`${slctdmem[i].user_id}`)
                            if (graphAid.data.userByAid.items.length === 0) {
                                let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: window.btoa(`account_${slctdmem[i].user_id}`)})
                                const dataSampleGrab = await gfCreateUser(
                                    slctdmem[i].user_id, 
                                    `${slctdmem[i].first_name} ${slctdmem[i].last_name}`, 
                                    slctdmem[i].profile === null ? 'none' : JSON.parse(slctdmem[i].profile).profile === null ? 'none' : JSON.parse(slctdmem[i].profile).profile, 
                                    'online', 
                                    ainfo.account_type
                                )
                                compiledData.push(dataSampleGrab.data.createUser)
                            } else {
                                compiledData.push(graphAid.data.userByAid.items[0])
                            }
                        } catch (error) {
                            errors.ubAid = error
                        }
                }
                try {
                    const createConnection = await gfCreateConnectionChat({
                        lastmsgID: 'nullNoMessage',
                        connectionChatType: 'psl',
                        type: 2,
                        connectionName: gc_state.data.gcdata.gcName,
                        connectionProfile: 'none',
                        createdBy: `${tool_state.__SESSION.data.graphAid.id}`,
                        isActive: 1,
                    }) 
                    for (let ii = 0; ii < compiledData.length; ii++) {
                        await gfCreateConnectionChatUser( {
                            userID: compiledData[ii].id,
                            connectionChatID: createConnection.data.createConnectionChat.id,
                            addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                            role: 'member',
                            status: 'added',
                            connectionType: 2,
                        })
                    }
                    await gfCreateConnectionChatUser( {
                        userID: `${tool_state.__SESSION.data.graphAid.id}`,
                        connectionChatID: createConnection.data.createConnectionChat.id,
                        addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                        role: 'admin',
                        status: 'added',
                        connectionType: 2,
                    })
                    gc_state.set({open: false, gcdata: {gcName: '', gcdp: 'none'}, members: [], status: null})
                    gc_state_dialog.set({...gc_state_dialog.data, dlgStat: false, init: '', datastate: null})
                    nav.push(`/psl/group/c/${createConnection.data.createConnectionChat.id}`)
                    setCounterLoad(false)
                } catch (error) {
                    errors.cccUser = error
                }
                setTimeout(() => {
                    setOnceRun(0)
                }, 1000);
            }
        }
    }

    const handleGroupProfileCreate = async(event) => {
        const targetCreateProfile = [...event.target.files]
        const gcPhoto = [];
            if (targetCreateProfile === undefined ){
                snack_state.alertVal.set({isOpen: true, textBody: <Box display="flex" flexDirection="row"><Box display="flex"><SvgIcon component={CircleCheckFill} fill={'#635FF6'} p={.3}/></Box><Box p={.3} pl={1}>Profile didn't change successfully</Box></Box>})
                setTimeout(() => {
                snack_state.alertVal.set({isOpen: false, textBody: ""})
                }, 1000);
            } else {
                for (let i = 0; i < targetCreateProfile.length; i++) {
                    const gcprofile = targetCreateProfile[i];
                    if ( gcprofile.size > 26214400 ) {
                        setSwalStat(true);
                    } else {
                        if (gcprofile && (gcprofile.type.startsWith('image/') || gcprofile.type.startsWith('video/') || gcprofile.type.startsWith('application/'))) {
                            const transmitdp = await S3([event.target.files[i]])
                            gcPhoto.push({name: gcprofile.name, size: gcprofile.size, type:gcprofile.type, link:transmitdp.location});
                            gc_state_dp.set({newDp:transmitdp.location, stat: true})
                            snack_state.alertVal.set({isOpen: true, textBody: <Box display="flex" flexDirection="row"><Box display="flex"><SvgIcon component={CircleCheckFill} fill={'#635FF6'} p={.3}/></Box><Box p={.3} pl={1}>Group profile picture set successfully</Box></Box>})
                            setTimeout(() => {
                                snack_state.alertVal.set({isOpen: false, textBody: ""})
                            }, 1500);
                        }
                    }
                }
                groupPhoto.set([...groupPhoto.data, ...gcPhoto]);
            }
    };

    const proceedHandler = () => {
        proceedHandlerNew()
        gc_state_dp.set({ stat: false})
        // if (gc_state.cidAddUpdate.data === '') {
        // } else {
        //     proceedHandlerAddUpdate()
        // }
    }
    
    return (
        <IndexCreateGroup 
            gc_state_dialog={gc_state_dialog} 
            gc_state={gc_state} 
            withData={withData} 
            searchData={searchData}
            changeHandler={changeHandler}
            selectHandler={selectHandler}
            proceedHandler={proceedHandler}
            counterLoad={counterLoad}
            gc_update_state={gc_update_state}
            selectedAdd={selectedAdd}
            handleGroupProfileCreate={handleGroupProfileCreate}
            setStartLoader={setStartLoader}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            swalStat={swalStat}
            startLoader={startLoader}
            gc_state_dp={gc_state_dp}
            groupPhoto={groupPhoto}
        />
    )
}
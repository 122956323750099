import { env } from "../../Env"
import { rqx } from "../../request/API"

export const SearchUser = async(e, test) => {
    try {
        let searchResult = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/searchuser`, {ref: "all", account_type: "1", search: e.target.value})
        return searchResult
    } catch (error) {
        return('error');
    }
}

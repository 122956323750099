import { lazy, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ToolContext } from '../../../core/context/ToolContext';
import { VM } from '../../../core/VM';
import { ChatIcon, EndCallIcon, MoreIcon, MuteIcon, PeopleIcon, PresentIcon, RecordIcon, UnmuteIcon, VideoCallIcon, VideoOffCallIcon } from '../../../core/global/Icons';
import { socket } from '../../../service/socket';
import Peer from "simple-peer";
import useVideoLogic from '../../../core/hooks/CustomHook';
import { gfCreateMessage, gfGetConnectionChat, gfListCallFeedBacks, gfUpdateConnectionChat } from '../../../core/global/graphqlFunc/GraphqlFunctions';
import CallingPermission from '../layouts/permission/CallingPermission';
import FuncationRecording from './recordingModal/FuncationRecording';
import FunctionRecordNotification from './recordingNotification/FunctionRecordNotification';


let view = VM();
const IndexCalling = lazy(() => import(`.${view}/Index`))

// Custom hook to handle page refresh
const useRefresh = (history, path, resetRoute) => {
    let handler;

    const refresh = () => {
        history.push(resetRoute);

        handler = setTimeout(() => history.push(path), 10);
    };

    useEffect(() => {
        return () => handler && clearTimeout(handler);
    }, [handler]);

    return refresh;
}



export const FuncationCalling = () => {
    const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
    const [video, setVideo] = useState(Array(21).fill(''));
    const [videoDetails, setVideoDetails] = useState([]);
    const [otherDetails, setOtherDetails] = useState([]);
    const [giveWidth, setGiveWidth] = useState(null);
    const [giveHeight, setGiveHeight] = useState(null);
    const [giveMaxWidth, setGiveMaxWidth] = useState(null);
    const [giveMinWidth, setGiveMinWidth] = useState(null);
    const [giveMaxHeight, setGiveMaxHeight] = useState(null);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [myStream, setMyStream] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [videoToggle, setVideoToggle] = useState(false);
    const [screenShare, setScreenShare] = useState(false);
    const [callAudioPemission, setCallAudioPemission] = useState(true);
    const [callVideoPemission, setCallVideoPemission] = useState(true);
    const [isAudioEnable, setIsAudioEnable] = useState(false);
    const [isVideoEnable, setIsVideoEnable] = useState(false);
    const [peers, setPeers] = useState([]);
    const [message, setMessage] = useState('');
    const [chatNotification, setChatNotification] = useState({});
    const [recordingStarted, setRecordingStarted] = useState(false);
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [mediaR, setMediaR] = useState(null);
    const [remoteRecord, setRemoteRecord] = useState({});
    const [groupName, setGroupName] = useState(null);
    const [recordingEnabled, setRecordingEnabled] = useState(null);
    const [recordNotification, setRecordNotification] = useState(null);
    const [meetingStartTime, setMeetingStartTime] = useState(null);
    const [meetingDuration, setMeetingDuration] = useState(null);
    const [feedbackDetails, setFeedbackDetails] = useState([]);


    const videoStream = useRef()
    const screenTrackRef = useRef();
    const userStream = useRef();
    const remoteStreamRef = useRef();
    const webcamStream = useRef();
    const peersRef = useRef([]);

    let { conId } = useParams();
    let history = useHistory();
    let nav = useHistory()
    const refresh = useRefresh(history, window.location.pathname);

    const { calling_state, tool_state, conversation_state } = useContext(ToolContext)
    let csdata = conversation_state.chatData.data

    const { setReceivingCall, callInfo } = useVideoLogic();

    const callType = localStorage.getItem('callingState')
    const handleSuccess = (redirectPath) => {
        if (history.location.pathname === redirectPath) {
            refresh();
        } else {
            history.push(redirectPath, {
                refresh: true
            });
        }
    };


    const loc = window.location.pathname
    const profInfo = tool_state.__SESSION.data.pinfo
    const userInfo = tool_state.__SESSION.data.graphAid
    let callState = calling_state.callingprofile
    let activeState = calling_state.activeIcon
    let callStream = calling_state.stream
    let grpName = calling_state.callGrpName
    let callUserName = calling_state.remoteUserName
    let callRecord = calling_state.openCallRecord
    let callRecordPermission = calling_state.recordingPermission
    // const listUsers = calling_state.connectUsers.data.length;
    const userChat = calling_state.chatMessages;
    const listUsers = calling_state.connectUsers.data;
    const userName = `${profInfo[0].first_name} ${profInfo[0].last_name}`
    const profileImg = userInfo.profileImg

    const callerId = tool_state.__SESSION.data.graphAid.id



    // Fetch feedback details when component mounts
    useEffect(() => {
        const fetchFeedbackDetail = async () => {
            try {
                const details = await gfListCallFeedBacks(userInfo.id, csdata.nextToken);
                setFeedbackDetails(details.data.listCallFeedBacks.items);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        };

        fetchFeedbackDetail();
    }, [])

    useEffect(() => {
        callInfo();
    }, [])

    useEffect(async () => {
        let getcdata = await gfGetConnectionChat(conId)
        setGroupName(getcdata.data.getConnectionChat.connectionName);
        grpName.set(getcdata.data.getConnectionChat.connectionName)
        let conData = getcdata.data.getConnectionChat.connectionChatUsers.items
        const userId = atob(profInfo[0].aid)
        const remoteUserDetail = conData.find((item) => item.user.aid !== userId.split('_')[1])
        setRemoteRecord(remoteUserDetail)
        callUserName.set(remoteUserDetail)
    }, [profInfo])

    // Set the video and user streams
    const handleStream = (stream) => {
        videoStream.current.srcObject = stream;
        userStream.current = stream;
        callStream.set(stream)
        setMyStream(stream)

        if (stream) {
            // Find video and audio tracks from the stream
            const videoTrack = stream.getTracks().find(track => track.kind === 'video');
            const audioTrack = stream.getTracks().find(track => track.kind === 'audio');

            if (videoTrack) {
                // Enable video track and toggle video state
                setIsVideoEnable(true)
                if (videoTrack.enabled) {
                    videoTrack.enabled = false;
                    setVideoToggle(true)
                } else {
                    setVideoToggle(false)
                    videoTrack.enabled = true;
                }
            }
            if (audioTrack) {
                // Enable audio track and toggle mute state
                setIsAudioEnable(true)
                if (audioTrack.enabled) {
                    audioTrack.enabled = false;
                    setIsMuted(true);
                } else {
                    setIsMuted(false);
                    audioTrack.enabled = true;
                }
            }
        }

        // Emit event to join the room with user details
        socket.emit("joining the room", { userName: userName, roomID: conId, userInfo: userInfo });

        socket.on("all users", users => {
            const peers = [];
            users.forEach(userID => {
                if (userID.userName !== userName) {
                    // Create a new peer connection for each user
                    const peer = createPeer(userID.socket, socket.id, stream);
                    peer.userName = userName;
                    peer.profileImg = profileImg;
                    peersRef.current.push({
                        peerID: userID.socket,
                        peer,
                        userName: userID.userName,
                        profileImg: userID?.userInfo?.profileImg
                    })

                    peers.push({
                        peerID: userID.socket,
                        userName: userID.userName,
                        profileImg: userID?.userInfo?.profileImg,
                        peer
                    });
                }
            })
            // Set the list of peers in the state
            setPeers(peers);
        })

        // Handle receiving all user details
        socket.on("all users details", userDetails => {
            calling_state.connectUsers.set(userDetails)
        });

        // Handle a new user joining the room
        socket.on("user joined", payload => {
            if (peersRef.current) {
                // Check if the peer already exists
                const item = peersRef.current.find(p => p.peerID === payload.callerID);
                let peer;
                if (!item) {
                    // Add a new peer connection
                    peer = addPeer(payload.signal, payload.callerID, stream);
                    const ref = [...peersRef.current]
                    ref.push({
                        peerID: payload.callerID,
                        peer,
                        userName: payload.userName,
                        profileImg: payload?.userDetails?.profileImg
                    })
                    peersRef.current = ref;
                    const peerObj = {
                        peer,
                        peerID: payload.callerID,
                        userName: payload.userName,
                        profileImg: payload?.userDetails?.profileImg
                    };
                    setPeers(users => [...users, peerObj]);
                }
            }
        });

        // Handle receiving a returned signal from a peer
        socket.on("receiving returned signal", payload => {
            const item = peersRef.current.find(p => p.peerID === payload.id);
            // Pass the received signal to the peer connection
            item.peer.signal(payload.signal);
        });

        // Handle a user leaving the room
        socket.on('userLeft', id => {
            const peerObj = peersRef.current.find(p => p.peerID === id);
            if (peersRef.current.length <= 1 && peerObj) window.close();
            // Destroy the peer connection and remove it from the list
            if (peerObj) peerObj.peer.destroy();
            const peers_data = peersRef.current.filter(p => p.peerID !== id);
            peersRef.current = peers_data;
            setPeers(peers_data);
            setReceivingCall(false);
            localStorage.removeItem('callMode');
            localStorage.removeItem('callingState');
        });

        // Handle socket or call reconnection
        socket.on("connect", () => {
            setIsOnline(true);
            setPeers([])
            peersRef.current = []
            socket.emit("joining the room", { userName: userName, roomID: conId, userInfo: userInfo })
        });

        // Cleanup socket event listeners on component unmount
        return () => {
            socket.off("join video room");
            socket.off("all users");
            socket.off("all users details");
            socket.off("user joined");
            socket.off("receiving returned signal");
            socket.off("user left");
        };
    }

    // Handle media error and retry for audio access
    function handleMediaError(error) {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError' || error.name === 'NotFoundError') {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(handleStream)
                .catch(error => console.log("Also failed to access audio:", error));
        }
    }


    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
        const speedMbps = (connection.downlink || 0) / 1024; // Convert to Mbps
        // setNetworkSpeed(speedMbps.toFixed(2));
    }
    useEffect(() => {
        const handleOnline = async () => {
            handleSuccess(window.location.pathname)
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        // Add event listeners for online and offline events
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount 
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Check for microphone and camera permissions  
    useEffect(() => {
        navigator.permissions.query({ name: 'microphone' }).then(function (result) {
            if (result.state == 'granted') {
                setCallAudioPemission(true)
            } else if (result.state == 'denied') {
                setCallAudioPemission(false)
            } else if (result.state === 'prompt') {
                setCallAudioPemission(false)
            }
        });

        navigator.permissions.query({ name: 'camera' })
            .then((cameraPermission) => {
                if (cameraPermission.state == 'granted') {
                    setCallVideoPemission(true)
                } else if (cameraPermission.state == 'denied') {
                    setCallVideoPemission(false)
                } else if (cameraPermission.state === 'prompt') {
                    setCallVideoPemission(false)
                }
            });

        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: { echoCancellation: true }
        })
            .then(handleStream)
            .catch(handleMediaError);

    }, []);


    // Listen for socket events 
    useEffect(() => {
        let notificationTimeout;
        socket.on('chatMessage', (message) => {
            userChat.set((prevMessages) => [...prevMessages, message]);
        });

        socket.on('newMessageNotification', (data) => {
            setChatNotification(data)
            setTimeout(() => {
                setChatNotification({});
            }, 6000);
        });

        return () => {
            socket.off('chatMessage');
            clearTimeout(notificationTimeout);
        };
    }, []);

    useEffect(() => {
        socket.on('recordNotification', (data) => {
            setRecordNotification(data);
            setTimeout(() => {
                setRecordNotification(null);
            }, 6000);
        });
        return () => {
            socket.off('recordNotification');
        };
    }, [])

    const handleSendMessage = () => {
        if (message.trim() != '') {
            const currentUser = listUsers.find(user => user.socket === socket.id);
            const timestamp = new Date().toLocaleString('en-US', {
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            if (currentUser) {
                socket.emit('chatMessage', { text: message, sender: currentUser.userName, sender_socket_id: currentUser.socket, timestamp: timestamp, users: listUsers });
                const recipientSockets = listUsers.filter(user => user.socket !== socket.id).map(user => user.socket);

                recipientSockets.forEach(recipientSocket => {
                    socket.emit('newMessageNotification', { sender: currentUser.userName, timestamp: timestamp, text: message });
                });

                setMessage('');
            }
        }
    };
    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });
        peer.on("signal", signal => {
            socket.emit("sending signal", { userToSignal, callerID, signal })
        })
        return peer;
    }
    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            trickle: false,
            stream,
        })

        peer.on("signal", signal => {
            socket.emit("returning signal", { signal, callerID })
        })

        peer.signal(incomingSignal);
        return peer;
    }

    const updateInnerWidth = () => {
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateInnerWidth);

        return () => {
            window.removeEventListener('resize', updateInnerWidth);
        };
    }, []);


    const iconsList = [
        { title: 'Mute', icon: isMuted ? UnmuteIcon : MuteIcon, type: "audio" },
        { title: 'Turn off camera', icon: videoToggle ? VideoOffCallIcon : VideoCallIcon, type: "video" },
        { title: 'Present', icon: PresentIcon, type: "present" },
        { title: !recordingStarted ? 'Start Record' : 'Stop Record', icon: RecordIcon, type: "record" },
        { title: 'More', icon: MoreIcon, type: "more" },
        { title: 'Chats', icon: ChatIcon, type: "chats" },
        { title: 'People', icon: PeopleIcon, count: listUsers.length, type: "people" },
        { title: 'End call', icon: EndCallIcon, color: '#EF5C48', type: "end_call" },
    ];

    let modifiedIconsList = iconsList;
    if (view === '/devices/mobile') {
        modifiedIconsList = modifiedIconsList.filter((iconItem) => iconItem.title !== 'Present' && iconItem.title !== 'Start Record');
    } else {
        modifiedIconsList = modifiedIconsList.filter((iconItem) => iconItem.title !== 'More');
    }

    // Handle screen sharing
    const clickScreenSharing = async () => {
        if (!screenShare) {
            const constraints = {
                cursor: true,
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                },
                audio: true // Ensure audio is included
            };

            if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
                try {
                    const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
                    const screenTrack = stream.getVideoTracks()[0];
                    const screenAudioTrack = stream.getAudioTracks()[0];

                    const message = { type: 'screen-on', userId: atob(profInfo[0].aid), socket: socket.id, roomId: conId };
                    socket.emit("videoAudioToggle", JSON.stringify(message));

                    peers.forEach(peer => {
                        const peerStream = peer && peer.peer.streams[0];
                        if (peerStream) {
                            const videoTrack = peerStream.getVideoTracks()[0];
                            const audioTrack = peerStream.getAudioTracks()[0];

                            if (videoTrack) {
                                peer.peer.replaceTrack(videoTrack, screenTrack, userStream.current);
                            } else {
                                peer.peer.addTrack(screenTrack, stream);

                            }
                            console.log("Pwee", peer)

                            if (audioTrack && screenAudioTrack) {
                                peer.peer.replaceTrack(audioTrack, screenAudioTrack, userStream.current);
                            } else {
                                console.warn('No audio track found in the peer stream or screen audio track is unavailable.');
                            }
                        } else {
                            console.error('Peer stream or tracks not available.');
                        }
                    });

                    // Listen click end
                    screenTrack.onended = () => {
                        peers.forEach(peer => {
                            const userVideoTrack = userStream.current.getVideoTracks()[0];
                            const userAudioTrack = userStream.current.getAudioTracks()[0];

                            peer.peer.replaceTrack(screenTrack, userVideoTrack, userStream.current);
                            if (screenAudioTrack) {
                                peer.peer.replaceTrack(screenAudioTrack, userAudioTrack, userStream.current);
                            }

                            const message = { type: 'screen-off', userId: atob(profInfo[0].aid), socket: socket.id, roomId: conId };
                            socket.emit("videoAudioToggle", JSON.stringify(message));
                        });
                        setMyStream(userStream.current);
                        videoStream.current.srcObject = userStream.current;
                        webcamStream.current = userStream.current;
                        setScreenShare(false);
                    };

                    setMyStream(stream);
                    videoStream.current.srcObject = stream;
                    webcamStream.current = stream;
                    screenTrackRef.current = screenTrack;
                    setScreenShare(true);

                } catch (err) {
                    console.error("Error accessing display media: ", err);
                }
            } else {
                alert("Screen sharing is not supported on this device.");
            }
        } else {
            screenTrackRef.current.onended();
        }
    };

    const handleRecording = async () => {
        try {
            const displayMediaOptions = {
                video: {
                    cursor: "always"
                },
                audio: true
            };

            if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
                const myStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
                let mediaRecorder = new MediaRecorder(myStream);
                let chunks = [];

                socket.emit('recording', `${userName} is start meeting recording`);

                mediaRecorder.ondataavailable = (ev) => {
                    if (ev.data.size > 0) {
                        chunks.push(ev.data);
                    }
                };

                mediaRecorder.onstop = async () => {
                    const mp4Blob = new Blob(chunks, { type: 'video/mp4' });
                    const url = URL.createObjectURL(mp4Blob);

                    const currentDate = new Date();
                    const dateString = currentDate.toISOString().replace(/:/g, '-').split('.')[0];
                    const fileName = `recording_${dateString}.mp4`;

                    const mediaBlob = await fetch(url).then(response => response.blob());
                    const myFile = new File([mediaBlob], fileName, { type: 'video/mp4' });
                    const fileUrl = URL.createObjectURL(myFile);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = fileUrl;
                    downloadLink.download = myFile.name;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                };

                mediaRecorder.start();
                setMediaR(mediaRecorder);
                setRecordingStarted(true);
                return mediaRecorder;
            } else {
                alert("Recording is not supported on this device.");
            }
        } catch (err) {
            console.error("Error: " + err);
        }
    };


    // Stop recording
    const handleStopRecording = () => {
        socket.emit('recording', `${userName} is stop meeting recording`)
        let mediaRecorder = mediaR;
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            setRecordingStarted(false);
        }
    };

    const handleRecordPermissions = () => {
        callRecord.set(true);
    }

    useEffect(() => {
        socket.on('updateRecordingPermission', (permission) => {
            setRecordingEnabled(permission)
        });
    }, []);

    // Format duration in "hours h minutes m seconds s" format
    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        let formattedDuration = '';

        if (hours > 0) {
            formattedDuration += hours + 'h ';
        }
        if (minutes > 0) {
            formattedDuration += minutes + 'm ';
        }
        formattedDuration += seconds + 's';

        return formattedDuration.trim();
    }

    // Format date to "MMM DD, YYYY, HH:MM AM/PM"
    function formatDate(date) {
        const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }


    // handle all actions 
    const handleIconClick = async (title) => {
        activeState.set(title);
        if (title === 'Chats' || title === 'People') {
            if (title === 'Chats' && chatDrawerOpen) {
                activeState.set('')
                setChatDrawerOpen(false);
            }
            if (title === 'People' && chatDrawerOpen) {
                activeState.set('')
                setChatDrawerOpen(false);
            }
            callState.set(!callState.data);
        } else if (title === 'End call') {
            const feedbackRecord = feedbackDetails.find((item) => item.userID === userInfo.id);
            peers.forEach(peer => {
                const videoTracks = peer.peer?.streams[0]?.getVideoTracks();
                videoTracks.forEach(track => track.stop());
                if (peer.peer) peer.peer.destroy()
                socket.disconnect()
            })
            if (!feedbackRecord) {
                history.push(`/psl/c/${conId}/calling/feedback`)
            } else {
                window.close();
            }
            if (meetingStartTime) {
                const endTime = new Date();
                const durationInSeconds = Math.round((endTime - meetingStartTime) / 1000);
                const formattedDuration = formatDuration(durationInSeconds);
                const formattedDate = formatDate(endTime);
                const durationMessage = `Call ended ${formattedDuration}`;
                const finalMessage = `${formattedDate}    ${durationMessage}`;
                localStorage.setItem('durationInSeconds', finalMessage);
                setMeetingDuration(finalMessage);
                if (peers.length === 1) {
                    let cmInput = { connectionChatID: conId, content: finalMessage, isSeen: false, status: 'Endcall', userID: callerId }
                    const messageData = await gfCreateMessage(cmInput)
                    await gfUpdateConnectionChat(conId, messageData.data.createMessage.id)
                }
            }
        } else if (title === 'Mute') {
            const audioTrack = myStream.getTracks().find(track => track.kind === 'audio');
            if (audioTrack.enabled) {
                audioTrack.enabled = false;
                setIsMuted(true);
            } else {
                setIsMuted(false);
                audioTrack.enabled = true;
            }
        } else if (title === 'Turn off camera') {
            const videoTrack = myStream.getTracks().find(track => track.kind === 'video');
            if (videoTrack.enabled) {
                videoTrack.enabled = false;
                setVideoToggle(true)
            } else {
                setVideoToggle(false)
                videoTrack.enabled = true;
            }
        } else if (title === 'Present') {
            setShowSubmenu(!showSubmenu);
            clickScreenSharing()
        } else if (title === 'Start Record') {
            setShowSubmenu(!showSubmenu);
            if (loc.includes('group') || recordingEnabled === 'Start Recording') {
                handleRecording();
            } else {
                handleRecordPermissions()
            }
        } else if (title === 'Stop Record') {
            setShowSubmenu(!showSubmenu);
            handleStopRecording();
        } else if (title === 'More') {
            setShowSubmenu(!showSubmenu);
        }
    };

    // Notify server about video toggle
    useEffect(() => {
        const messageType = videoToggle ? 'video-off' : 'video-on';
        const message = { type: messageType, userId: atob(profInfo[0].aid), socket: socket.id, roomId: conId };
        socket.emit("videoAudioToggle", JSON.stringify(message));
    }, [videoToggle])

    // Notify server about audio mute/unmute
    useEffect(() => {
        const messageType = isMuted ? 'audio-mute' : 'audio-unmute';
        const message = { type: messageType, userId: atob(profInfo[0].aid), socket: socket.id, roomId: conId };
        socket.emit("videoAudioToggle", JSON.stringify(message));
    }, [isMuted])

    // Close window if call is not received
    useEffect(() => {
        if (!window.location.href.includes('group')) {
            socket.on("callNotReceived", () => {
                window.close();
            });
        }
    }, [])

    // Adjust video layout based on number of peers and screen size
    useEffect(() => {
        let slicedVideo, remainingVideo, mWidth, mHeight, maxWidth, maxHeight, minWidth;
        if (view !== '/devices/mobile') {
            if (peers.length === 1) {
                maxHeight = "100%"
                maxWidth = "1100px"
                mWidth = "100%"
                minWidth = "auto"
                mHeight = "auto"
                slicedVideo = peers.slice(0, maxWidth)
                slicedVideo = peers;
            } else if (peers.length === 2) {
                maxHeight = "100%"
                maxWidth = "calc(50% - 12px)"
                mWidth = "100%"
                minWidth = "auto"
                mHeight = "auto"
                slicedVideo = peers.slice(0, maxWidth)
                slicedVideo = peers;
            } else if (peers.length === 3) {
                maxHeight = "100%"
                maxWidth = "calc(33% - 12px)"
                mWidth = "100%"
                mHeight = "auto"
                minWidth = "300px"
                slicedVideo = peers.slice(0, maxWidth)
                slicedVideo = peers;
            } else if (peers.length === 4) {
                maxHeight = "calc(50vh - 160px)"
                maxWidth = callState.data ? "calc(44% - 12px)" : "calc(34% - 12px)"
                mWidth = "100%"
                mHeight = "100%"
                minWidth = "200px"
                slicedVideo = innerWidth < 700 ? peers.slice(0, 2) : peers;
                remainingVideo = innerWidth < 700 && peers.slice(2)
            } else if (peers.length >= 5 && peers.length <= 7) {
                maxHeight = "calc(50vh - 160px)"
                maxWidth = "calc(33% - 12px)"
                mWidth = "100%"
                mHeight = "100%"
                minWidth = "320px"
                slicedVideo = peers;
                if (innerWidth < 1428 && innerWidth > 700) {
                    slicedVideo = peers.slice(0, 3);
                    remainingVideo = peers.slice(3)
                } else if (innerWidth > 700 && peers.length === 7) {
                    slicedVideo = peers.slice(0, 5);
                    remainingVideo = peers.slice(5)
                } else if (innerWidth < 700) {
                    slicedVideo = peers.slice(0, 2);
                    remainingVideo = peers.slice(2)
                } else {
                    slicedVideo = peers
                }
            } else if (peers.length >= 8 && peers.length <= 11) {
                maxHeight = "calc(50vh - 160px)"
                maxWidth = "calc(25% - 12px)"
                mWidth = "100%"
                mHeight = "100%"
                minWidth = "320px"
                if (innerWidth < 1980 && peers.length > 8 && innerWidth > 1760) {
                    slicedVideo = peers.slice(0, 7);
                    remainingVideo = peers.slice(7)
                }
                else if (innerWidth < 1760 && peers.length > 8 && innerWidth > 1428) {
                    slicedVideo = callState.data ? peers.slice(0, 5) : peers.slice(0, 7);
                    remainingVideo = callState.data ? peers.slice(5) : peers.slice(7)
                }
                else if (innerWidth < 1428 && innerWidth > 1366) {
                    slicedVideo = callState.data ? peers.slice(0, 3) : peers.slice(0, 7);
                    remainingVideo = callState.data ? peers.slice(3) : peers.slice(7)
                } else if (innerWidth < 1366 && innerWidth > 1032) {
                    slicedVideo = callState.data ? peers.slice(0, 3) : peers.slice(0, 5);
                    remainingVideo = callState.data ? peers.slice(3) : peers.slice(5)
                } else if (innerWidth < 1032 && innerWidth > 700) {
                    slicedVideo = peers.slice(0, 5);
                    remainingVideo = peers.slice(5)
                } else if (innerWidth < 700) {
                    slicedVideo = peers.slice(0, 2);
                    remainingVideo = peers.slice(2)
                } else {
                    slicedVideo = peers
                }
            } else if (peers.length >= 12 && peers.length <= 23) {
                maxHeight = "calc(33vh - 110px)"
                maxWidth = "calc(25% - 12px)"
                mWidth = "100%"
                mHeight = "100%"
                minWidth = "320px"
                if (innerWidth < 1980 && peers.length > 12 && innerWidth > 1760) {
                    slicedVideo = peers.slice(0, 11);
                    remainingVideo = peers.slice(11)
                } else if (innerWidth < 1760 && innerWidth > 1427) {
                    slicedVideo = callState.data ? peers.slice(0, 8) : peers.slice(0, 11);
                    remainingVideo = callState.data ? peers.slice(8) : peers.slice(11)
                } else if (innerWidth <= 1427 && innerWidth > 1363) {
                    slicedVideo = callState.data ? peers.slice(0, 5) : peers.slice(0, 11);
                    remainingVideo = callState.data ? peers.slice(5) : peers.slice(11)
                } else if (innerWidth <= 1363 && innerWidth > 1032) {
                    slicedVideo = callState.data ? peers.slice(0, 5) : peers.slice(0, 8);
                    remainingVideo = callState.data ? peers.slice(5) : peers.slice(8)
                } else if (innerWidth < 1032 && innerWidth > 700) {
                    slicedVideo = peers.slice(0, 5);
                    remainingVideo = peers.slice(5)
                } else if (innerWidth < 700) {
                    slicedVideo = peers.slice(0, 2);
                    remainingVideo = peers.slice(2)
                } else {
                    slicedVideo = peers
                }
            } else if (peers.length >= 24) {
                maxHeight = "146px"
                maxWidth = "268px"
                mWidth = "100%"
                mHeight = "100%"
                minWidth = "268px"
                const breakpoints = callState.data ? [1785, 1500, 1225, 950, 550] : [1680, 1436, 1117, 832, 550];
                const numColumns = callState.data ? [19, 15, 11, 7, 3] : [23, 19, 15, 11, 7];
                // const openDraworNumColumns = [19, 15, 11, 7, 5];
                // const numColumns = [17, 15, 11, 5, 3];

                for (let i = 0; i < breakpoints.length; i++) {
                    if (innerWidth >= breakpoints[i]) {
                        slicedVideo = peers.slice(0, numColumns[i]);
                        remainingVideo = peers.slice(numColumns[i]);
                        break;
                    }
                }
            }
        } else {
            const maxColumns = [1, 2, 4, 6, Infinity];
            const columnWidths = ["100%", "100%", "calc(50% - 10px)", "calc(50% - 10px)", "calc(50% - 10px)"];
            const columnHeights = ["100%", "50%", "50%", "calc(33% - 8px)", "calc(33% - 8px)"];

            const maxVideoIndex = maxColumns.findIndex(max => peers.length <= max);
            mWidth = columnWidths[maxVideoIndex];
            mHeight = columnHeights[maxVideoIndex];

            if (peers.length >= 7) {
                slicedVideo = peers.slice(0, 5);
                remainingVideo = peers.slice(5);
            } else {
                slicedVideo = peers;
            }

        }
        setGiveWidth(mWidth);
        setGiveHeight(mHeight);
        setGiveMaxWidth(maxWidth);
        setGiveMinWidth(minWidth);
        setGiveMaxHeight(maxHeight);
        setVideoDetails(slicedVideo);
        setOtherDetails(remainingVideo);
    }, [innerWidth, callState, peers])



    return (
        <>
            <IndexCalling
                chatDrawerOpen={chatDrawerOpen}
                setChatDrawerOpen={setChatDrawerOpen}
                video={video}
                innerWidth={innerWidth}
                callState={callState}
                activeState={activeState}
                conId={conId}
                history={history}
                updateInnerWidth={updateInnerWidth}
                iconsList={modifiedIconsList}
                handleIconClick={handleIconClick}
                giveWidth={giveWidth}
                giveHeight={giveHeight}
                giveMaxHeight={giveMaxHeight}
                giveMinWidth={giveMinWidth}
                giveMaxWidth={giveMaxWidth}
                // numColumns={numColumns}
                // getVideoBox={getVideoBox}
                // slicedVideo={slicedVideo}
                videoDetails={videoDetails}
                otherDetails={otherDetails}
                localStream={myStream}
                videoRef={videoStream}
                remoteStreamRef={remoteStreamRef}
                callType={callType}
                peers={peers}
                setPeers={setPeers}
                videoToggle={videoToggle}
                myStream={myStream}
                remoteRecord={remoteRecord}
                groupName={groupName}
                message={message}
                setMessage={setMessage}
                handleSendMessage={handleSendMessage}
                chatNotification={chatNotification}
                showSubmenu={showSubmenu}
                setShowSubmenu={setShowSubmenu}
                recordingEnabled={recordingEnabled}
                setMeetingStartTime={setMeetingStartTime}
                isOnline={isOnline}
                isVideoEnable={isVideoEnable}
                isAudioEnable={isAudioEnable}
            />
            <CallingPermission callAudioPemission={callAudioPemission} callVideoPemission={callVideoPemission} setCallVideoPemission={setCallVideoPemission} setCallAudioPemission={setCallAudioPemission} />
            <FunctionRecordNotification recordNotification={recordNotification} />
            <FuncationRecording />
        </>
    )
}

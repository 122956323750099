import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
function CallingPermission({ callAudioPemission, callVideoPemission, setCallAudioPemission, setCallVideoPemission }) {
    const [open, setOpen] = useState(true);
    let history = useHistory();
    let { conId } = useParams();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCallAudioPemission(true);
        setCallVideoPemission(true);
    }

    return (
        <div>
            {!callAudioPemission && !callVideoPemission && <Modal
                open={!callAudioPemission && !callVideoPemission}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Permission Required
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ my: 2 }}>
                        This application requires access to your camera and microphone.
                    </Typography>
                    <Button sx={{ background: 'blue', textAlign: 'center', ":hover": { background: 'blue' } }} onClick={handleClose}>Allow Access</Button>
                </Box>
            </Modal>}
        </div>
    );
}
export default CallingPermission;
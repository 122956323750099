import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { env } from './core/Env';
import { rqx } from './core/request/API';
import { Loader } from './core/loader/Loader';
import { ErrorCodeAid } from './core/errorCodes/ErrorCode';

export const DirectRqx = () => {
    return <Router><Validate /></Router>
}

const Validate = () => {
    //variables
    const q = new URLSearchParams(useLocation().search);
    const loc = window.location.pathname
    const subdir = loc.split('/')

    //states
    const [err, setErr] = useState(false)

    //useeffect
    useEffect(() => {
        const __init = async () => {
            if (q.get('ainfo') !== null) {
                const ainfo = JSON.parse(q.get('ainfo'))
                const acc = await rqx.get(`<<global>>/${env() === 'local' ? 'dev' : env()}/account/read`, { aid: ainfo.aid })
                if (typeof acc === 'object') {
                    let d = new Date()
                    d.setDate(d.getDate() + 30);

                    const strg = {
                        expiration: `${d.getMonth() + 1}${d.getUTCDate()}${d.getUTCFullYear()}`,
                        ainfo: ainfo,
                        platform: subdir[2] === 'biz' ? 'business' : 'personal'
                    }
                    localStorage.setItem('pof_chat_session', JSON.stringify(strg))
                    if (acc.user_type === 1 && subdir[2] === 'psl') {
                        window.location.href = `/psl`
                    } else if (acc.user_type === 2 && subdir[2] === 'biz') {
                        window.location.href = `/biz`
                    } else if (acc.user_type === 3) {
                        window.location.href = `/${subdir[2]}`
                    } else {
                        setErr(true)
                    }
                } else {
                    setErr(true)
                }
            } else {
                setErr(true)
            }
        }

        setTimeout(() => {
            __init()
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !err ? (
        <Loader />
    ) : (
        <ErrorCodeAid />
    )
}

import { env } from "../../Env"
import { rqx } from "../../request/API"
import { gfCreateConnectionChat, gfCreateConnectionChatUser, gfCreateUser, gfUserByAid, gfconnectionChatUsersByUserIDAndConnectionChatID } from "../graphqlFunc/GraphqlFunctions"

export const MakeGetConnectionBiz = async(e, currType, navLocate, tool_state, connectionType, connectionName, connectionProfile, cctype) => {
    const data = await gfUserByAid(`${e.user_id}`)
    let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: window.btoa(`account_${e.user_id}`)})
    let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: window.btoa(`account_${e.user_id}`)})
    if (data.data.userByAid.items.length === 0) {
        const grabuser = await gfCreateUser(e.user_id, `${pinfo[0].first_name} ${pinfo[0].last_name}`, JSON.parse(pinfo[0].profile).profile === null ? 'none' : JSON.parse(pinfo[0].profile).profile, 'online', ainfo.account_type)
        if (grabuser !== 'error') {
            try {
                const grabcConnection = await gfCreateConnectionChat({
                    lastmsgID: 'nullNoMessage',
                    connectionChatType: cctype,
                    type: connectionType,
                    connectionName: connectionName,
                    connectionProfile: connectionProfile,
                    createdBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    isActive: 1,
                })
                await gfCreateConnectionChatUser({
                    userID: grabuser.data.createUser.id,
                    connectionChatID: grabcConnection.data.createConnectionChat.id,
                    addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    role: 'default',
                    status: 'added',
                    connectionType: connectionType,
                })
                await gfCreateConnectionChatUser( {
                    userID: tool_state.__SESSION.data.graphAid.id,
                    connectionChatID: grabcConnection.data.createConnectionChat.id,
                    addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    role: 'default',
                    status: 'added',
                    connectionType: connectionType,
                })
               
               
                return navLocate.replace('/n', `/c/${grabcConnection.data.createConnectionChat.id}`)
            } catch (error) {
                console.log(error); 
            }
        }
    } else {

        // let check = graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index].connectionChat.connectionChatUsers.items.filter((d) => d.userID === data.data.userByAid.items[0].id)
        // console.log(check)
        // if (check.length > 0) {
        //     for (let i = 0; i < check.length; i++) {
        //         if (check[i].connectionChat.isActive === 1) {
        //             withConnection = graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index]
        //             break;
        //         }
        //     }
        // }


        let withConnection = null
        const graphValidate = await gfconnectionChatUsersByUserIDAndConnectionChatID(tool_state.__SESSION.data.graphAid.id, connectionType) 
        for (let index = 0; index < graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items.length; index++) {
            if (graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index].connectionChat.isActive === 1 && graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index].connectionType === connectionType) {
                let check = graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index].connectionChat.connectionChatUsers.items.filter((d) => d.userID === data.data.userByAid.items[0].id)
                if (check.length > 0) {
                    withConnection = graphValidate.data.connectionChatUsersByUserIDAndConnectionChatID.items[index]
                    break;
                }
            }
        }
        if (withConnection === null) {
            try {
                const grabcConnection = await gfCreateConnectionChat({
                    lastmsgID: 'nullNoMessage',
                    connectionChatType: cctype,
                    type: connectionType,
                    connectionName: connectionName,
                    connectionProfile: connectionProfile,
                    createdBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    isActive: 1,
                })
                await gfCreateConnectionChatUser( {
                    userID: data.data.userByAid.items[0].id,
                    connectionChatID: grabcConnection.data.createConnectionChat.id,
                    addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    role: 'default',
                    status: 'added',
                    connectionType: connectionType,
                })
                await gfCreateConnectionChatUser({
                    userID: tool_state.__SESSION.data.graphAid.id,
                    connectionChatID: grabcConnection.data.createConnectionChat.id,
                    addedBy: `${tool_state.__SESSION.data.graphAid.id}`,
                    role: 'default',
                    status: 'added',
                    connectionType: connectionType,
                })
                return navLocate.replace('/n', `/c/${grabcConnection.data.createConnectionChat.id}`)
            } catch (error) {
                console.log(error);
            }
        } else {
            return navLocate.replace('/n', `/c/${withConnection.connectionChatID}`)
        }
    }
}

import { lazy, useContext, useEffect, useRef, useState } from "react";
import { VM } from "../../../core/VM";
import { ToolContext } from "../../../core/context/ToolContext";
import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { CircleCheckFill, TrashBlack } from "../../../core/global/Icons";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { makeStyles, withStyles } from '@mui/styles';
import { useLongPress } from "../../../core/global/functions/UseLongPress";
import { SearchUser } from "../../../core/global/functions/SearchUser";
// import { MakeGetConnection } from "../../../core/global/functions/MakeGetConnection";
import { updateConnectionChat } from "../../../graphql/mutations";
import { MakeGetConnectionBiz } from "../../../core/global/functions/MakeGetConnectionBiz";

let view = VM();
const Customtooltip = withStyles({
    tooltip: {
        backgroundColor: '#333333',
        color: '#FFFFFF',
        boxShadow: ' 0 .5rem 1rem rgba(0, 0, 0, .15) !important',
        borderRadius: 10,
        width: 220,
        fontSize: 20,
        padding: 20

    },
    arrow: {
        color: '#333333',
        fontSize: 15
    },
    popper: {
        zIndex: 1001 + '!important'
    }

})(Tooltip);
const IndexConnectionBiz= lazy(() => import(`.${view}/Index`))

const FunctionConnectionsBiz = ({profInfo}) => {
	const { conversation_state, navigation_state, connection_state, tool_state, dialog_state, drawer_state, snack_state, snack_stateunsuc, layout_drawer_state } = useContext(ToolContext)
    const { tc } = navigation_state
    const __LOCATION = useLocation().pathname
    let timeoutID;
    let nav = new useHistory();
    let allDirect = connection_state.directData.data.base.map(data => data)
    let allGroup = connection_state.groupData.data.base.map(data => data)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    let location = __LOCATION.split('/')[2] === 'pro' ? __LOCATION.split('/')[2] === 'communities' ? { ct: 'pro', cc: 'coZmmunities' } : { ct: 'pro', cc: 'direct' } : __LOCATION.split('/')[2] === 'group' ? { ct: 'home', cc: 'group' } : { ct: 'home', cc: 'direct' }
    const [searchData, setSearchData] = useState({ load: false, isResult: false, data: [] })
    const [isModal, setIsModal] = useState(false)
    const [UMAnchor, setUMAnchor] = useState(null);
    const [hoverData, setHoverData] = useState([]);
    const [runJoyride, setRunJoyride] = useState(false);
    const [swalStat, setSwalStat] = useState(false)

    const useStyles = makeStyles((theme) => ({
        act_list_item: { cursor: 'pointer', padding: '10px', boxShadow: 'none', backgroundColor: '#E7E7FA', borderRadius: '6px', transition: '0.3s', '&:hover': { backgroundColor: '#E7E7FA', } },
        inact_list_item: { cursor: 'pointer', padding: '10px', boxShadow: 'none', backgroundColor: '#FAFAFF', borderRadius: '6px', transition: '0.3s', '&:hover': { backgroundColor: '#E7E7FA', } },
        grp_inact_list_item: { cursor: 'pointer', padding: '10px', boxShadow: 'none', backgroundColor: '#FFF', borderRadius: '6px', transition: '0.3s', '&:hover': { backgroundColor: '#E7E7FA', } },
    }));
    
    const classes = useStyles();

    //handlers
    useEffect(() => {
        setRunJoyride(true); // Start the joyride when the component mounts
    }, []);

    const closeMenuHandler = () => {
        setHoverData([])
        setUMAnchor(null)
    }

    const onLongPress = (ev) => {
        drawer_state.connectionList.set({ ...drawer_state.connectionList.data, open: true, data: { target: ev.target.getAttribute("thislptarget"), connectionID: ev.target.getAttribute("thisConnectId") } })
    }

    const onClickPress = (event) => {
        return null
    }

    const longPressProps = useLongPress({
        onClick: (ev) => onClickPress(ev),
        onLongPress: (ev) => onLongPress(ev),
    });
    const MsgToolTipDesign = () => {
        return (
            <Box display="flex" alignItems="center" fontSize="12px">
                <Box px={1.2} py={1.2}>Create new chat</Box>
                <IconButton ><Close style={{ fill: "#ffffff" }} /></IconButton>
                <Box bgcolor="#333333" width="18px" height="18px" position="absolute" ml="165px" mt="20px" sx={{ transform: 'translateY(-50%) rotate(45deg)', }}></Box>
            </Box>
        )
    }

    const GrpToolTipDesign = () => {
        return (
            <Box display="flex" alignItems="center" >
                <Box alignItems="center" flexDirection="column">
                    <Box display="flex" px={1.2} py={1.2} fontSize="14px">Group</Box>
                    <Box display="flex" px={1.2} fontSize="12px">Create connections with a group of Hero Users.</Box>
                </Box>
                <IconButton ><Close style={{ fill: "#ffffff" }} /></IconButton>
                <Box bgcolor="#333333" width="18px" height="18px" position="absolute" ml="245px" mt="90px" sx={{ transform: 'translateY(-50%) rotate(45deg)', }}></Box>
            </Box>
        )
    }

    const newMsgHandler = (e) => {
        if (__LOCATION.split('/').includes('pro')) {
            if (__LOCATION.split('/').includes('communities')) {

            } else {

            }
        } else if (__LOCATION.split('/').includes('group')) {
            drawer_state.newgcinfo.set({ ...drawer_state.newgcinfo.data, open: true })
        } else {
            nav.push(`/psl/n`)
            setIsModal(true)
        }
        // nav.push(`/psl${__LOCATION.split('/').includes('pro') ? __LOCATION.split('/').includes('communities') ? '/pro/communities' : '/pro' : __LOCATION.split('/').includes('group') ? '/group' : '' }/n`)
    }
    const newMsgHandlerSD = (e, location) => {
        if (location.cc === 'pro') {
            console.log(location)
        } else if (location.cc === 'group') {
            drawer_state.newgcinfo.set({ ...drawer_state.newgcinfo.data, open: true })
        } else {
            dialog_state.mobDirect.set({ ...dialog_state.mobDirect.data, dlgStat: true })
        }
    }

    const closeModalHandler = () => {
        setIsModal(false)
    }
    const backHandler = (identifier) => {
        if (identifier === 'direct') {
            dialog_state.mobDirect.set({ ...dialog_state.mobDirect.data, dlgStat: false, datastate: null })
        }
    }
    const dateFormater = (date) => {
        const dateFormat = "YYYY-MM-DD HH:mm:ss";
        const rightNow = moment().format(dateFormat);
        const thisTimeYesterday = moment().subtract(7, 'days').format(dateFormat);
        if (moment(date).diff(rightNow, 'hours') >= -10 && moment(date).diff(rightNow, 'hours') <= 12) {
            return (
                <Box >{moment(date).format("h:mm A")}</Box>
            )
        } else if (moment(date).diff(thisTimeYesterday, 'hours') >= 12 && moment(date).diff(thisTimeYesterday, 'hours') < 168) {
            return (
                <Box >{moment(date).format("ddd")}</Box>
            )
        } else {
            return (
                <Box >{moment(date).format("MMM DD")}</Box>
            )
        }
    }

    //idebtify on the connection list who sent and what type of message you have sent or recieved
    const messageFormater = (data, tool_state) => {
        if (data.lastMessage === null) {
            return `Start Conversation`
        } else if (data.lastMessage.status === "removed") {
            return <Box width="100%" display="flex"><SvgIcon component={TrashBlack} />{"\n"}Chat removed</Box>
        } else if (data.lastMessage.content === "" && data.lastMessage.user.id === tool_state.__SESSION.data.graphAid.id) {
            return <Box width="100%" whiteSpace="noWrap" overflow="hidden" textOverflow="ellipsis"><b>You:</b>{"\n"}Sent an Attachment</Box>
        } else if (data.lastMessage.content === "" && data.lastMessage.user.id !== tool_state.__SESSION.data.graphAid.id) {
            return <Box width="100%" whiteSpace="noWrap" overflow="hidden" textOverflow="ellipsis"><b>{data.lastMessage.user.name.split(' ').slice(0, 1).join(' ')}:</b>{"\n"}Sent an Attachment</Box>
        } else if (data.lastMessage.user.id === tool_state.__SESSION.data.graphAid.id) {
            return <Box width="100%" whiteSpace="noWrap" overflow="hidden" textOverflow="ellipsis" ><b>You:</b>{"\n"}{data.lastMessage.content}</Box>
        } else if (data.lastMessage.content.includes('@') ) {
            // return <Box width="90%" whiteSpace="noWrap" overflow="hidden" fontSize="12px" textOverflow="ellipsis" display="flex" ><Box display="flex" color="red" flexDirection="column" mr={.5}><b>@</b></Box>{" "}<b>{" "}{data.lastMessage.user.name.split(' ').slice(0, 1).join(' ')} mentioned you</b>: {data.lastMessage.content}</Box>
            return <Box width="100%" whiteSpace="noWrap" overflow="hidden" textOverflow="ellipsis" ><b>{data.lastMessage.user.name.split(' ').slice(0, 1).join(' ')}:</b>{"\n"}{data.lastMessage.content}</Box>
        } else {
            return <Box width="100%" whiteSpace="noWrap" overflow="hidden" textOverflow="ellipsis" ><b>{data.lastMessage.user.name.split(' ').slice(0, 1).join(' ')}:</b>{"\n"}{data.lastMessage.content}</Box>
        }
    }

    const convoHandler = (data) => {
        nav.push(`/psl${__LOCATION.split('/').includes('pro') ? __LOCATION.split('/').includes('communities') ? '/pro/communities' : '/pro' : __LOCATION.split('/').includes('group') ? '/group' : ''}/c/${data.connectionChatID}`)
        connection_state.directData.set({ ...connection_state.directData.data, list: allDirect })
        connection_state.groupData.set({ ...connection_state.groupData.data, list: allGroup })
    }

    //api handlers
    const changeHandler = (e) => {
        e.preventDefault()
        setSearchData({ ...searchData, load: true })
        clearTimeout(timeoutID); // Clear any previous timeouts
        timeoutID = setTimeout(async () => {
            if (e.target.value !== "") {
                let data = await SearchUser(e)
                setSearchData({ isResult: data.length === 0, data: data, load: false })
                // setUMAnchor(prepAnch);
            } else {
                setSearchData({ isResult: false, data: [], load: false })
                // console.log('clear')
            }
        }, 1000);
    }

    const connSearchHandler = (e) => {
        e.preventDefault()
        setSearchData({ ...searchData, load: true })
        clearTimeout(timeoutID); // Clear any previous timeouts
        timeoutID = setTimeout(async () => {
            if (location.cc === "group") {
                let filterData = []
                connection_state.groupData.data.base.filter((data) => (data.connectionName).toLowerCase().includes(e.target.value.toLowerCase()) ? filterData.push(data) : null)
                connection_state.groupData.set({ ...connection_state.groupData.data, list: filterData })
            }
            else {
                let filterData = []
                connection_state.directData.data.base.filter((data) => (data.connectedUser[0].user.name).toLowerCase().includes(e.target.value.toLowerCase()) ? filterData.push(data) : null)
                connection_state.directData.set({ ...connection_state.directData.data, list: filterData })
            }
            e.preventDefault()
            setSearchData({ ...searchData, load: false })
            clearTimeout(timeoutID);
        }, 1000);
    }

    const selectHandler = async (e, identifier) => {
        let connectionName = identifier === 1 ? 'default' : 'test'
        let connectionProfile = identifier === 1 ? 'default' : 'test'
        let navlocate = await MakeGetConnectionBiz(e, 'biz', `${__LOCATION}/n`, tool_state, identifier, connectionName, connectionProfile, 'biz')
        dialog_state.mobDirect.set({ ...dialog_state.mobDirect.data, dlgStat: false })
        nav.push(navlocate)
    }

    const UMHandler = (e, act, data) => {
        if (act === 'moreset') {
            e.preventDefault()
            setHoverData(data)
            setUMAnchor(e.currentTarget);
        }
    }

    //removes the connection
    const removeConnectionHandler = async (e) => {
        try {
            await API.graphql(graphqlOperation(updateConnectionChat, { input: { id: hoverData.connectionChatID, isActive: 0, } }))
            nav.push(`/psl${__LOCATION.split('/').includes('pro') ? __LOCATION.split('/').includes('communities') ? '/pro/communities' : '/pro' : __LOCATION.split('/').includes('group') ? '/group' : ''}`)
            snack_state.alertVal.set({ isOpen: true, textBody: <Box display="flex" flexDirection="row"><Box display="flex"><SvgIcon component={CircleCheckFill} fill={'#635FF6'} p={.3} /></Box><Box p={.3} pl={1}>Connection removed successfully</Box></Box> })
            setTimeout(() => {
                snack_state.alertVal.set({ isOpen: false, textBody: "" })
            }, 1000);
            setHoverData([])
        } catch (error) {
            console.log(error)
            snack_stateunsuc.alertVal.set({ isOpen: true, textBody: <Box display="flex" flexDirection="row"><Box display="flex"><SvgIcon component={CircleCheckFill} fill={'#F44336'} p={.3} /></Box><Box p={.3} pl={1}>connection removed unsuccessful</Box></Box> })
            setTimeout(() => {
                snack_stateunsuc.alertVal.set({ isOpen: false, textBody: "" })
            }, 1500);
            drawer_state.connectionList.set({ open: false, data: null, status: null })
            setHoverData([])
        }
    }

	return (
		<>
			<IndexConnectionBiz 
				__LOCATION={__LOCATION}
                location={location}
                tc={tc}
                layout_drawer_state={layout_drawer_state}
                connection_state={connection_state}
                classes={classes}
                MsgToolTipDesign={MsgToolTipDesign}
                tool_state={tool_state}
                dialog_state={dialog_state}
                TrashBlack={TrashBlack}
                dateFormater={dateFormater}
                messageFormater={messageFormater}
                windowSize={windowSize}
                convoHandler={convoHandler}
                newMsgHandler={newMsgHandler}
                newMsgHandlerSD={newMsgHandlerSD}
                backHandler={backHandler}
                //use for dialogs search/create connection
                changeHandler={changeHandler}
                selectHandler={selectHandler}
                isModal={isModal}
                searchData={searchData}
                closeModalHandler={closeModalHandler}
                longPressProps={longPressProps}
                closeMenuHandler={closeMenuHandler}
                UMAnchor={UMAnchor}
                UMHandler={UMHandler}
                removeConnectionHandler={removeConnectionHandler}
                profInfo={profInfo}
                Customtooltip={Customtooltip}
                GrpToolTipDesign={GrpToolTipDesign}
                runJoyride={runJoyride}
                swalStat={swalStat}
                setSwalStat={setSwalStat}
                connSearchHandler={connSearchHandler}
                conversation_state={conversation_state}
                hoverData={hoverData}
			/>
		</>

	)
}

export default FunctionConnectionsBiz

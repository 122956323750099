import { lazy, useContext, useEffect, useState } from 'react'
import { VM } from '../../../../core/VM'
import { socket } from '../../../../service/socket';
import { gfCreateCallFeedBack } from '../../../../core/global/graphqlFunc/GraphqlFunctions';
import { ToolContext } from '../../../../core/context/ToolContext';
import { useHistory, useLocation } from 'react-router-dom';
let view = VM();
const IndexFeedBack = lazy(() => import(`.${view}/Index`))


export const FeedBackForm = () => {
    // State variables for feedback form
    const [viewPages, setViewPage] = useState('feedback')
    const [feedback, setFeedback] = useState(null);
    const [descriptions, setDescriptions] = useState('');
    const maxWordCount = 120;
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

    // Accessing context variables
    const { calling_state, tool_state } = useContext(ToolContext)
    const userInfo = tool_state.__SESSION.data.graphAid
    let grpName = calling_state.callGrpName
    let callUserName = calling_state.remoteUserName

    const history = useHistory();
    const location = useLocation();

    // Function to redirect 
    const handleRedirect = () => {
        // const baseUrl = location.pathname.split('/calling')[0];
        // history.push(baseUrl);
        window.close();
    };

    // Callback function for feedback selection1
    const handleFeedbackSelection = (selectedFeedback) => {
        setFeedback(selectedFeedback);
    }

    // Callback function for text change
    const handleTextChange = (e) => {
        const text = e.target.value;
        setDescriptions(text);
    };

    // Effect to enable/disable send button based on feedback and description
    useEffect(() => {
        setIsSendButtonDisabled(!(feedback === 1 || feedback === 0) || descriptions.length < 4);
    }, [feedback, descriptions]);

    // Callback function to send feedback
    const handleSendFeedback = async (data) => {
        setViewPage(data)
        const feedbackData = {
            callID: socket.id,
            feedBack: descriptions,
            quality: feedback,
            status: true,
            userID: userInfo.id
        };

        try {
            // Send feedback data to GraphQL function
            const details = await gfCreateCallFeedBack(feedbackData);
        } catch (error) {
            console.error("Error sending feedback:", error);
        }

    }

    // Callback function to close feedback form
    const handleClose = (data) => {
        setViewPage(data)
    }


    return (
        <>
            <IndexFeedBack
                feedback={feedback}
                setFeedback={setFeedback}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                maxWordCount={maxWordCount}
                isSendButtonDisabled={isSendButtonDisabled}
                setIsSendButtonDisabled={setIsSendButtonDisabled}
                handleFeedbackSelection={handleFeedbackSelection}
                handleTextChange={handleTextChange}
                handleSendFeedback={handleSendFeedback}
                viewPages={viewPages}
                setViewPage={setViewPage}
                handleClose={handleClose}
                grpName={grpName}
                callUserName={callUserName}
                handleRedirect={handleRedirect}
            />
        </>
    )
}

import { useState } from 'react'

export const LoaderState = () => {
    const [connection_devices_mobile_newconneciton, setConnection_devices_mobile_newconnection] = useState({loader: false})
    const [gMsg, setGMsg] = useState({base: [], search: [], list: [], isSearch: false})
    const [pMsg, setPmsg] = useState({base: [], search: [], list: [], isSearch: false})
    return {
        directData:{data: connection_devices_mobile_newconneciton, set: setConnection_devices_mobile_newconnection},
        groupData:{data: gMsg, set: setGMsg},
        proData:{data: pMsg, set: setPmsg}
    }
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSocket = /* GraphQL */ `
  mutation CreateUserSocket(
    $input: CreateUserSocketInput!
    $condition: ModelUserSocketConditionInput
  ) {
    createUserSocket(input: $input, condition: $condition) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSocket = /* GraphQL */ `
  mutation UpdateUserSocket(
    $input: UpdateUserSocketInput!
    $condition: ModelUserSocketConditionInput
  ) {
    updateUserSocket(input: $input, condition: $condition) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSocket = /* GraphQL */ `
  mutation DeleteUserSocket(
    $input: DeleteUserSocketInput!
    $condition: ModelUserSocketConditionInput
  ) {
    deleteUserSocket(input: $input, condition: $condition) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConnectionChatUser = /* GraphQL */ `
  mutation CreateConnectionChatUser(
    $input: CreateConnectionChatUserInput!
    $condition: ModelConnectionChatUserConditionInput
  ) {
    createConnectionChatUser(input: $input, condition: $condition) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConnectionChatUser = /* GraphQL */ `
  mutation UpdateConnectionChatUser(
    $input: UpdateConnectionChatUserInput!
    $condition: ModelConnectionChatUserConditionInput
  ) {
    updateConnectionChatUser(input: $input, condition: $condition) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConnectionChatUser = /* GraphQL */ `
  mutation DeleteConnectionChatUser(
    $input: DeleteConnectionChatUserInput!
    $condition: ModelConnectionChatUserConditionInput
  ) {
    deleteConnectionChatUser(input: $input, condition: $condition) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConnectionChat = /* GraphQL */ `
  mutation CreateConnectionChat(
    $input: CreateConnectionChatInput!
    $condition: ModelConnectionChatConditionInput
  ) {
    createConnectionChat(input: $input, condition: $condition) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConnectionChat = /* GraphQL */ `
  mutation UpdateConnectionChat(
    $input: UpdateConnectionChatInput!
    $condition: ModelConnectionChatConditionInput
  ) {
    updateConnectionChat(input: $input, condition: $condition) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConnectionChat = /* GraphQL */ `
  mutation DeleteConnectionChat(
    $input: DeleteConnectionChatInput!
    $condition: ModelConnectionChatConditionInput
  ) {
    deleteConnectionChat(input: $input, condition: $condition) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const createCheckVersion = /* GraphQL */ `
  mutation CreateCheckVersion(
    $input: CreateCheckVersionInput!
    $condition: ModelCheckVersionConditionInput
  ) {
    createCheckVersion(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCheckVersion = /* GraphQL */ `
  mutation UpdateCheckVersion(
    $input: UpdateCheckVersionInput!
    $condition: ModelCheckVersionConditionInput
  ) {
    updateCheckVersion(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCheckVersion = /* GraphQL */ `
  mutation DeleteCheckVersion(
    $input: DeleteCheckVersionInput!
    $condition: ModelCheckVersionConditionInput
  ) {
    deleteCheckVersion(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppVersion = /* GraphQL */ `
  mutation CreateAppVersion(
    $input: CreateAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    createAppVersion(input: $input, condition: $condition) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppVersion = /* GraphQL */ `
  mutation UpdateAppVersion(
    $input: UpdateAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    updateAppVersion(input: $input, condition: $condition) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppVersion = /* GraphQL */ `
  mutation DeleteAppVersion(
    $input: DeleteAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    deleteAppVersion(input: $input, condition: $condition) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCallFeedBack = /* GraphQL */ `
  mutation CreateCallFeedBack(
    $input: CreateCallFeedBackInput!
    $condition: ModelCallFeedBackConditionInput
  ) {
    createCallFeedBack(input: $input, condition: $condition) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCallFeedBack = /* GraphQL */ `
  mutation UpdateCallFeedBack(
    $input: UpdateCallFeedBackInput!
    $condition: ModelCallFeedBackConditionInput
  ) {
    updateCallFeedBack(input: $input, condition: $condition) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCallFeedBack = /* GraphQL */ `
  mutation DeleteCallFeedBack(
    $input: DeleteCallFeedBackInput!
    $condition: ModelCallFeedBackConditionInput
  ) {
    deleteCallFeedBack(input: $input, condition: $condition) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createForward = /* GraphQL */ `
  mutation CreateForward(
    $input: CreateForwardInput!
    $condition: ModelForwardConditionInput
  ) {
    createForward(input: $input, condition: $condition) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateForward = /* GraphQL */ `
  mutation UpdateForward(
    $input: UpdateForwardInput!
    $condition: ModelForwardConditionInput
  ) {
    updateForward(input: $input, condition: $condition) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteForward = /* GraphQL */ `
  mutation DeleteForward(
    $input: DeleteForwardInput!
    $condition: ModelForwardConditionInput
  ) {
    deleteForward(input: $input, condition: $condition) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createSwitch = /* GraphQL */ `
  mutation CreateSwitch(
    $input: CreateSwitchInput!
    $condition: ModelSwitchConditionInput
  ) {
    createSwitch(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const updateSwitch = /* GraphQL */ `
  mutation UpdateSwitch(
    $input: UpdateSwitchInput!
    $condition: ModelSwitchConditionInput
  ) {
    updateSwitch(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const deleteSwitch = /* GraphQL */ `
  mutation DeleteSwitch(
    $input: DeleteSwitchInput!
    $condition: ModelSwitchConditionInput
  ) {
    deleteSwitch(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;

// Libraries
import { Box } from "@mui/material"
import { useEffect } from "react"
import { domain } from "./core/Env"

const VerifyReferral = () => {

    //useeffect
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        let copyLink = `${domain('pm').url}/${'psl'}/registration?ref_a_frnd=${searchParams.get('rc')}`
        window.location.href = copyLink
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Box></Box>
    )
}

export default VerifyReferral
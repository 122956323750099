

import { lazy, useContext, useEffect } from 'react';
import { VM } from '../../../../../core/VM';
import { useHistory } from 'react-router-dom';
import { ToolContext } from '../../../../../core/context/ToolContext';

//dynamic imports
let view = VM();
const IndexTopConvo = lazy(() => import(`.${view}/Index`))

export const FunctionTopConvo = ({props}) => {
    //context Values
    const {conversation_state} = useContext(ToolContext)
    let nav = useHistory()
    //variables
    
    //handlers/functions/validations
    const backHandler = () => {
        let loc = props.match.url.includes('group') ? '/psl/group' : props.match.url.includes('communities') ? '/psl/pro/communities' : props.match.url.includes('communities') ? '/psl/pro' : '/psl'
        conversation_state.tdData.set({dp: 'Pofsis', dn: 'Hero User', id: ''})
        nav.push(loc)
    }

    useEffect(() => {
        // console.log(__SESSION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //styleings
    return (
        <IndexTopConvo 
            backHandler={backHandler}
            conversation_state={conversation_state}
        /> 
    )
}
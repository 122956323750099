import { lazy, useContext, useRef, useState } from "react";
import { VM } from "../../../core/VM";
import { ToolContext } from "../../../core/context/ToolContext";
let view = VM();
const IndexComm = lazy(() => import(`.${view}/Index`))

const FunctionCommunity = () => {
    const { tool_state, install_state} = useContext(ToolContext)
    const profInfo = tool_state.__SESSION.data.pinfo
    return (
        <IndexComm 
            profInfo={profInfo}
            install_state={install_state}
        />
    )
}

export default FunctionCommunity

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const aid = /* GraphQL */ `
  query Aid($aid: String!) {
    aid(aid: $aid) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const connectionChatByUserAidAndType = /* GraphQL */ `
  query ConnectionChatByUserAidAndType($connectionChatType: String!) {
    connectionChatByUserAidAndType(connectionChatType: $connectionChatType) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      aid
      name
      profileImg
      status
      userType
      connectionChatUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSocket = /* GraphQL */ `
  query GetUserSocket($id: ID!) {
    getUserSocket(id: $id) {
      id
      userID
      socketID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSockets = /* GraphQL */ `
  query ListUserSockets(
    $filter: ModelUserSocketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSockets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        socketID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnectionChatUser = /* GraphQL */ `
  query GetConnectionChatUser($id: ID!) {
    getConnectionChatUser(id: $id) {
      id
      userID
      connectionChatID
      nickName
      addedBy
      role
      status
      connectionType
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConnectionChatUsers = /* GraphQL */ `
  query ListConnectionChatUsers(
    $filter: ModelConnectionChatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionChatUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        connectionChatID
        nickName
        addedBy
        role
        status
        connectionType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnectionChat = /* GraphQL */ `
  query GetConnectionChat($id: ID!) {
    getConnectionChat(id: $id) {
      id
      connectionChatType
      connectionChatUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      type
      connectionName
      connectionProfile
      createdBy
      lastmsgID
      isActive
      status
      lastmsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConnectionChats = /* GraphQL */ `
  query ListConnectionChats(
    $filter: ModelConnectionChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      createdAt
      updatedAt
      deletedAt
      content
      userID
      status
      isSeen
      repliedID
      pin
      repliedMsg {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      connectionChatID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      connectionChat {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      url
      type
      size
      name
      messageId
      connectionChatId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        type
        size
        name
        messageId
        connectionChatId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      receiverID
      senderID
      type
      message
      createdAt
      updatedAt
      isRead
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        receiverID
        senderID
        type
        message
        createdAt
        updatedAt
        isRead
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckVersion = /* GraphQL */ `
  query GetCheckVersion($id: ID!) {
    getCheckVersion(id: $id) {
      id
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCheckVersions = /* GraphQL */ `
  query ListCheckVersions(
    $filter: ModelCheckVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppVersion = /* GraphQL */ `
  query GetAppVersion($id: ID!) {
    getAppVersion(id: $id) {
      id
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppVersions = /* GraphQL */ `
  query ListAppVersions(
    $filter: ModelAppVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appVersion
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCallFeedBack = /* GraphQL */ `
  query GetCallFeedBack($id: ID!) {
    getCallFeedBack(id: $id) {
      id
      callID
      feedBack
      quality
      status
      userID
      user {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCallFeedBacks = /* GraphQL */ `
  query ListCallFeedBacks(
    $filter: ModelCallFeedBackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallFeedBacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        callID
        feedBack
        quality
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForward = /* GraphQL */ `
  query GetForward($id: ID!) {
    getForward(id: $id) {
      id
      userID
      status
      pin
      createdAt
      updatedAt
      deletedAt
      messages {
        nextToken
        __typename
      }
      connectionChats {
        nextToken
        __typename
      }
      attachments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listForwards = /* GraphQL */ `
  query ListForwards(
    $filter: ModelForwardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        status
        pin
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSwitch = /* GraphQL */ `
  query GetSwitch($id: ID!) {
    getSwitch(id: $id) {
      id
      content
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const listSwitches = /* GraphQL */ `
  query ListSwitches(
    $filter: ModelSwitchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByAid = /* GraphQL */ `
  query UserByAid(
    $aid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAid(
      aid: $aid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        aid
        name
        profileImg
        status
        userType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionChatUsersByUserIDAndConnectionChatID = /* GraphQL */ `
  query ConnectionChatUsersByUserIDAndConnectionChatID(
    $userID: ID!
    $connectionChatID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionChatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionChatUsersByUserIDAndConnectionChatID(
      userID: $userID
      connectionChatID: $connectionChatID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        connectionChatID
        nickName
        addedBy
        role
        status
        connectionType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionChatUsersByConnectionChatIDAndUserID = /* GraphQL */ `
  query ConnectionChatUsersByConnectionChatIDAndUserID(
    $connectionChatID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionChatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionChatUsersByConnectionChatIDAndUserID(
      connectionChatID: $connectionChatID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        connectionChatID
        nickName
        addedBy
        role
        status
        connectionType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByUserAidAndType = /* GraphQL */ `
  query ByUserAidAndType(
    $connectionChatType: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByUserAidAndType(
      connectionChatType: $connectionChatType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        connectionChatType
        type
        connectionName
        connectionProfile
        createdBy
        lastmsgID
        isActive
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConnectionChat = /* GraphQL */ `
  query MessagesByConnectionChat(
    $connectionChatID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConnectionChat(
      connectionChatID: $connectionChatID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        deletedAt
        content
        userID
        status
        isSeen
        repliedID
        pin
        connectionChatID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attachmentByMessages = /* GraphQL */ `
  query AttachmentByMessages(
    $messageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attachmentByMessages(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        type
        size
        name
        messageId
        connectionChatId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const attachmentByConnectionChatID = /* GraphQL */ `
  query AttachmentByConnectionChatID(
    $connectionChatId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attachmentByConnectionChatID(
      connectionChatId: $connectionChatId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        type
        size
        name
        messageId
        connectionChatId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByreceiverID = /* GraphQL */ `
  query NotificationsByreceiverID(
    $receiverID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByreceiverID(
      receiverID: $receiverID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receiverID
        senderID
        type
        message
        createdAt
        updatedAt
        isRead
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsBysenderID = /* GraphQL */ `
  query NotificationsBysenderID(
    $senderID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsBysenderID(
      senderID: $senderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receiverID
        senderID
        type
        message
        createdAt
        updatedAt
        isRead
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkVersionsByUserID = /* GraphQL */ `
  query CheckVersionsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkVersionsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkCallFeedBackByUserID = /* GraphQL */ `
  query CheckCallFeedBackByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCallFeedBackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkCallFeedBackByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        callID
        feedBack
        quality
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

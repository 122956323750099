// Libraries
import { Box } from '@mui/material/';
import { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Assets

// Layouts
import { env } from '../core/Env';
import { rqx } from '../core/request/API';
import { Logo, LogoBiz } from '../core/global/Icons';
import { ToolContext } from '../core/context/ToolContext';
import { SnakeLoader } from '../core/loader/SnakeLoader'
// import { InstallPromotion } from '../components/layouts/installation/InstallPromotion';
import { WebRouteBiz } from '../core/webrouter/BizRoute';
import { BrandLog } from '../core/global/BrandLog';

//variables
const __SESSION = JSON.parse(localStorage.getItem('pof_chat_session'))

export const CoreBusiness = () => {
//context values
const { tool_state, install_state } = useContext(ToolContext)
const [loader, setLoader] = useState(false)
const [userLog, setUserLog] = useState(true)

//variables

//states

//functions
const __installer = () => {
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		install_state.prompt.set(e)
		installPromptHandler()
	});
}
const installPromptHandler = () => {
	setTimeout(() => {
		install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
	}, 2000);
}

//useeffect
	useEffect(() => {
		BrandLog()
		const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
			let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: __SESSION.ainfo.aid})
			let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})
			// SET STATES HERE ( SAMPLE STORING BELOW: )
			let d = new Date()
			d.setDate(d.getDate() + 30);
			localStorage.setItem('my_tool_session', JSON.stringify({expiration: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`, ainfo: ainfo}))
			tool_state.__SESSION.set({ainfo, pinfo})
			tool_state.prefetch.set(true)
			
			// RUN PWA INSTALLATION PROMPT HANDLER
			// installPromptHandler()
		}
		
		if (__SESSION !== null) {
			__installer()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				__installer()
			}, 1000);
		}
		
		console.log(`POFChat V2.9.6 Biz is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<LogoBiz size={60}/>
								<Box mt={4}>
									<SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
								</Box>
							</Box>
						}>
							<WebRouteBiz />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<LogoBiz size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			{/* <InstallPromotion install_state={install_state} /> */}
		</Box>
	)
}
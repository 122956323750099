// import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';
// import { TC } from '../../components/layouts/tc/Index'
import Navigation from '../../__personal/components/layouts/navigations/Index';
import { Category } from '../../__personal/components/layouts/switching/Category';
import { TCDrawer } from '../../components/layouts/tc/drawer/Index';
// import { FunctionConversation } from '../../__personal/components/conversation/FunctionConversation';
import { FunctionConnection } from '../../__personal/components/connections/FunctionConnection';
import { FunctionDirect } from '../../__personal/components/direct/FunctionDirect';
import { FunctionGroup } from '../../__personal/components/group/FunctionGroup';
// import { FunctionInfo } from '../../__personal/components/info/FunctionInfo';
import { FeedBackForm } from '../../__personal/components/calling/feedback/FeedBackForm';
import { FuncationCalling } from '../../__personal/components/calling/FuncationCalling';
// import { lazy } from 'react';
import { Settings } from '../../__personal/components/settings/Index';
import { WebRouteBiz } from './BizRoute';
// import { IndexProfile } from '../../__personal/components/info/devices/mobile/Index';

// const Navigation = lazy(() => import(`../../components/layouts/navigations${view}/Index`))
let view = VM();

export const WebRoutePsl = () => {
    const location = useLocation().pathname;
    return (
        <Switch>
            {
                view !== "" ?
                    <>
                        {/*Mobile ROUTES*/}
                        {/* DIRECT ROUTES */}
                        <Route exact path="/psl/c/info/:conId" render={props => (MRender(FunctionDirect, props))} />
                        <Route exact path="/psl/c/:conId" render={props => (MConvoRender(FunctionDirect, props))} />
                        <Route exact path="/psl/n" render={props => (MConvoRender(FunctionDirect, props))} />
                        <Route exact path="/psl" render={props => (MRender(FunctionDirect, props))} />
                        <Route exact path="/psl/more" render={props => (MRender(Settings, props))} />
                        <Route exact path="/psl/c/:conId/calling" render={props => (MRender(FuncationCalling, props))} />
                        <Route exact path="/psl/c/:conId/calling/feedback" render={props => (MRender(FeedBackForm, props))} />
                        {/* GROUP ROUTES */}
                        <Route exact path="/psl/group/c/info/:conId" render={props => (MRender(FunctionGroup, props))} />
                        <Route exact path="/psl/group/c/:conId" render={props => (MConvoRender(FunctionGroup, props))} />
                        <Route exact path="/psl/group" render={props => (MRender(FunctionGroup, props))} />
                        <Route exact path="/psl/group/c/:conId/calling" render={props => (MRender(FuncationCalling, props))} />
                        {/* PRO ROUTES */}
                        <Route exact path="/psl/pro/communities" render={props => (MRender(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/:commId" render={props => (MRender(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/:commId/:chanId" render={props => (MRender(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/info/:commId" render={props => (MRender(FunctionConnection, props))} />
                        <Route exact path="/psl/pro" render={props => (MRender(FunctionConnection, props))} />
                        {/* Business ROUTES */}
                        <Route exact path="/biz" render={props=>(MRender(WebRouteBiz, props, location))} />
                    </>
                    :
                    <>
                        {/*Desktop ROUTES*/}
                        {/* DIRECT ROUTES */}
                        <Route exact path="/psl/c/info/:conId" render={props => (Render(FunctionDirect, props))} />
                        <Route exact path="/psl/c/:conId" render={props => (Render(FunctionDirect, props))} />
                        <Route exact path="/psl/c/:conId/call" render={props => (Render(FunctionDirect, props))} />
                        <Route exact path="/psl/n" render={props => (Render(FunctionDirect, props))} />
                        <Route exact path="/psl" render={props => (Render(FunctionDirect, props))} />
                        <Route exact path="/psl/c/:conId/calling" render={props => (Render(FuncationCalling, props))} />
                        <Route exact path="/psl/c/:conId/calling/feedback" render={props => (Render(FeedBackForm, props))} />

                        {/* GROUP ROUTES */}
                        <Route exact path="/psl/group/c/info/:conId" render={props => (Render(FunctionGroup, props))} />
                        <Route exact path="/psl/group/c/:conId" render={props => (Render(FunctionGroup, props))} />
                        <Route exact path="/psl/group/n" render={props => (Render(FunctionGroup, props))} />
                        <Route exact path="/psl/group" render={props => (Render(FunctionGroup, props))} />
                        <Route exact path="/psl/group/c/:conId/calling" render={props => (Render(FuncationCalling, props))} />
                        {/* PRO ROUTES */}  
                        <Route exact path="/psl/pro/communities" render={props => (Render(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/:commId" render={props => (Render(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/:commId/:chanId" render={props => (Render(FunctionConnection, props))} />
                        <Route exact path="/psl/pro/c/info/:commId" render={props => (Render(FunctionConnection, props))} />
                        <Route exact path="/psl/pro" render={props => (Render(FunctionConnection, props))} />
                    </>
            }

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props) => {
    return (
        <Box width="100%" height="100%">
            {props.location.pathname.includes('calling') ?
                (
                    <Component {...props} />
                ) : (
                    <Box width="100%" height="100%">
                        <Navigation side={0} />
                        <Box display="flex" width="100%" height="100%" bgcolor="#EEECF7">
                            <Navigation side={1} />
                            <FunctionConnection />
                            <Box component="main" flex={1} mt={7}>
                                <Box sx={{ position: 'relative' }} width="100%" height="100%">
                                    <Box pt={2} width="100%" height="100%"><Component {...props} /></Box>
                                    <TCDrawer />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
        </Box>
    )
}

const MRender = (Component, props, location) => {
    return (
        <Box width="100%"> {props.location.pathname.includes('calling') ?
            (
                <Component {...props} />
            ) : (
                <Box height="100%" width="100%">
                    { props.location.pathname !== '/psl/more' ? 
                        <Box >
                            <Navigation side={0} />
                        </Box>
                        :
                        <Box height="100%" mt={12} bgcolor="#FFFFFF">
                            <Navigation side={0} />
                        </Box>
                    }
                    <Box display="flex" width="100%" height="100%" >
                        <Box component="main" mt={props.location.pathname !== '/psl/more' ? (9.5) : (0)} width="100%" sx={{ zIndex: 1100 }}>
                            <Box sx={{ position: 'relative' }} width="100%" >
                                <Box width="100%" pt={props.location.pathname !== '/psl/more' ? (2) : (0)} >
                                    <Box width="100%" height="100%" sx={props.location.pathname !== '/psl/more' ? { borderRadius: '12px 12px 6px 6px', backgroundColor: '#FFFFFF', zIndex: 2 } : { backgroundColor: '#FFFFFF', zIndex: 2 }} p={props.location.pathname !== '/psl/more' ? (.5) : (0)} overflowy="hidden">
                                    { props.location.pathname !== '/psl/more' ? 
                                            <Category />
                                        :
                                            <></>
                                    }
                                        <Component {...props} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Navigation side={2} />
                </Box>
            )}
        </Box>
    )
}

const MConvoRender = (Component, props) => {
    return (
        <Box width="100%" height="100%" >
            {/* <Navigation side={3} propvals={props}/> */}
            <Box display="flex" width="100%" height="100%">
                <Box component="main" flex={1} mt={7} width="100%" heigth="100%">
                    <Box sx={{ position: 'relative' }} width="100%" height="100%">
                        <Box width="100%" heigth="100%">
                            <Box width="100%" heigth="100%" p={2} >
                                <Component {...props} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <Navigation side={4} /> */}
        </Box>
    )
}

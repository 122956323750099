

import { lazy, useEffect, useRef, useState } from 'react';
import { VM } from '../../../../../core/VM';
import { S3 } from '../../../../../core/transmit/S3';
//dynamic import
let view = VM();
const IndexBtmConvo = lazy(() => import(`.${view}/Index`))

export const FunctionBtmConvo = () => {
    //variables
    const [message, setMessage] = useState("")
    const [keys, setKeys] = useState({keycode: '', skey: false})
    const inputFile = useRef(null) 
    // const [swalStat, setSwalStat] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [startLoader, setStartLoader] = useState(true)
    const [file, setFile] = useState(null);
    //handlers/functions/validations
    const inputHandler = (e) => {
        if (keys.keycode !== 13) {
            setMessage(e.target.value);
            // setFile(e.target.value);
        } else if (keys.skey) {
            setMessage(e.target.value);
            // setFile(e.target.value);
        }
    }
    
    const handleRemoveFile = (fileToRemove) => {
        setSelectedFiles(selectedFiles.filter((file) => file !== fileToRemove));
      };
    
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileChange = async(event) => {
        const targetFile = [...event.target.files]
        const newFiles = [];
        if (targetFile === undefined ){
            return
        } else {
            for (let i = 0; i < targetFile.length; i++) {
                const file = targetFile[i];
                if ( file.size > 26214400 ) {
                    // setSwalStat(true);
                } else {
                    if (file && (file.type.startsWith('image/') || file.type.startsWith('video/') || file.type.startsWith('application/'))) {
                        const transmit = await S3([event.target.files[i]])
                        newFiles.push({name: file.name, size: file.size, type:file.type, link:transmit.location});
                    } 
                    // else {
                    //     setSwalStatFile(true);
                    // }
                }
            }
            setStartLoader(true)  
            setSelectedFiles([...selectedFiles, ...newFiles]);
        }
    };

    useEffect(() => {
        // console.log(__SESSION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //styleings
    return (
        <IndexBtmConvo 
        inputHandler={inputHandler} 
        message={message} 
        onButtonClick={onButtonClick} 
        inputFile={inputFile} 
        startLoader={startLoader} 
        selectedFiles={selectedFiles} 
        handleRemoveFile={handleRemoveFile} 
        file={file} 
        setFile={setFile}
        setKeys={setKeys}
        setSelectedFiles={setSelectedFiles} 
        setStartLoader={setStartLoader}
        handleFileChange={handleFileChange}
        />
    )
}
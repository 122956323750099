import { useState } from 'react'

export const InfoState = () => {
    const [dialogState, setDialogState] = useState(false)
    const [initializer, setInitializer] = useState('')
    const [attachData, setAttachData] = useState({name: '', nlabel: '', connectionID: ''})
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null})
    const [__UserType, setUserType] = useState({atype: null, currtype: null})

    return{
        dialog: {data: dialogState, set: setDialogState},
        initer: {data: initializer, set: setInitializer},
        attchData: {data: attachData, set: setAttachData},
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},
        __UserType: {data: __UserType, set: setUserType}
    }
}



import { lazy, useContext, useEffect, useState } from 'react';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { VM } from '../../../../../core/VM';
import { useHistory, useLocation } from 'react-router-dom';
import PSLLogo from '../../../../../assets/images/core/avatar2.png'
import { makeStyles } from '@mui/styles';
import { env } from '../../../../../core/Env';

//dynamic imports
let view = VM();
const IndexTop = lazy(() => import(`.${view}/Index`))
const drawerWidth = 278;
export const FunctionTop = () => {
    //context Values
    let nav = new useHistory();
    let timeoutID;
    const { navigation_state, tool_state, install_state, dialog_state, connection_state } = useContext(ToolContext)
    let promtprop = dialog_state.prompt
    const { __SESSION } = tool_state
    const { tc } = navigation_state
    const { notif, myTool } = navigation_state
    const [UMAnchor, setUMAnchor] = useState(null);
    const [searchData, setSearchData] = useState({load: false, isResult: false, data: []})
    const [switchModal, setSwitchModal] = useState(false)
    const __LOCATION = useLocation().pathname
    const display = __SESSION.data.pinfo.length > 0 ? __SESSION.data.pinfo[0].profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile : `${__SESSION.data.pinfo[0].first_name} ${__SESSION.data.pinfo[0].last_name}` : PSLLogo : PSLLogo
    const history = useHistory()
    const profInfo = tool_state.__SESSION.data.pinfo
    const accntInfo = tool_state.__SESSION.data.ainfo
    //variables
    
    //handlers/functions/validations
    const NavHandler = async(e, name) => {
        if (name === 'Notifications' && __LOCATION !== '/notification') {
            notif.set(false)
        }else if(name === 'MyTools'){
            myTool.set(!myTool.data)
        }else if (name === 'Chat'){
            let link = `https://${env()==='prod'?'': env() === 'sb' ?'sb-':'dev-'}chat.pofsis.com`
            window.open(`${link}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(__SESSION.data.ainfo))}`, '_blank').focus()
        }
    }

    const UMHandler = (e) => {
        e.preventDefault()
        setUMAnchor(e.currentTarget);
    }
    const NPromtCaller = (open, title, content, btnTxt1, btnTxt2, btnStat1, btnStat2) => {
        promtprop.set({ open: open, title: title, content: content, btnTxt1: btnTxt1, btnTxt2: btnTxt2, btnStat1: btnStat1, btnStat2: btnStat2 })
    }
    
    const onCloseSwitchModal = (e) =>{
        setSwitchModal(false)
    }
    const profileHandler  = (e) =>{
        nav.push(`/psl/myProfile`)
    }
    const logoutPromtHandler = () => {
        setTimeout(() => {
            install_state.ip.set({ stat: true, prompt_type: 'logout' })
        });
    }

    const searchConnHandler = async (e) => {
        e.preventDefault()
        setSearchData({ ...searchData, load: true })
        clearTimeout(timeoutID); // Clear any previous timeouts
        timeoutID = setTimeout(async () => {
            if (__LOCATION.split('/').includes("group")) {
                let filterData = []
                connection_state.groupData.data.base.filter((data) => (data.connectionName).toLowerCase().includes(e.target.value.toLowerCase()) ? filterData.push(data) : null)
                connection_state.groupData.set({ ...connection_state.groupData.data, list: filterData })
            }
            else {
                let filterData = []
                connection_state.directData.data.base.filter((data) => (data.connectedUser[0].user.name).toLowerCase().includes(e.target.value.toLowerCase()) ? filterData.push(data) : null)
                connection_state.directData.set({ ...connection_state.directData.data, list: filterData })
                setSearchData(connection_state.directData.data.base.map(data => data))
            }
        }, 1000);
    }

    const GPromtCaller = (open, title, content, btnTxt1, btnTxt2, btnStat1, btnStat2) => {
        promtprop.set({ open: open, title: title, content: content, btnTxt1: btnTxt1, btnTxt2: btnTxt2, btnStat1: btnStat1, btnStat2: btnStat2 })
    }

    const [tabActive, setTabActive] = useState('')
    const mouseHover = (name) => {
        setTabActive(name)
    }

    //styleings
    const useStyles = makeStyles((theme) => ({
        appbar: {
            marginLeft: drawerWidth,
            width: tc.data.open ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${75}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[tc.data.open ? 'leavingScreen' : 'enteringScreen'],
            }),
        },
        drawerToggle: {
            width: drawerWidth
        },
        ListItemText: {
            '& span, & svg': {
                fontSize: '13px'
            },
        },
        active: {
            backgroundColor: '#007c74',
            color: '#ffffff',
            '&:hover': {
                color: '#ffffff',
                backgroundColor: '#007c74',
            },
        }
    }));
    useEffect(() => {
        // Simulate menu opening after a delay (e.g., 1 second)
        const timeout = setTimeout(() => {
            setSwitchModal(true);
        }, 3500);
    
        return () => clearTimeout(timeout); // Clean up the timeout on unmount
      }, []);
    const classes = useStyles();
    return (
        <IndexTop
            profInfo={profInfo}
            navigation_state={navigation_state}
            __SESSION={__SESSION}
            install_state={install_state}
            UMAnchor={UMAnchor}
            setUMAnchor={setUMAnchor}
            __LOCATION={__LOCATION}
            display={display}
            history={history}
            UMHandler={UMHandler}
            tc={tc}
            classes={classes}
            profileHandler={profileHandler}
            GPromtCaller={GPromtCaller}
            logoutPromtHandler={logoutPromtHandler}
            accntInfo={accntInfo}
            searchConnHandler={searchConnHandler}
            NPromtCaller={NPromtCaller}
            setSwitchModal={setSwitchModal}
            switchModal={switchModal}
            onCloseSwitchModal={onCloseSwitchModal}
            NavHandler={NavHandler}
            mouseHover={mouseHover}
            tabActive={tabActive}
            myTool={myTool}
            notif={notif}
            setTabActive={setTabActive}
        />
    )
}
import { useState } from "react"

export const ToolStates = () => {
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null})
    const [tracker, setTracker] = useState({platform: null, pfCategory: null, pfChatType: null})
    const [__UserType, setUserType] = useState({atype: null, currtype: null})
    const [toolOwned, setToolOwned] = useState([])
    const [globalLoader, setGlobalLoader] = useState(true)
    const [validateAccount, setValidateAccount] = useState({data:'', count:0})
    return {
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},
        __initTrack: {data: tracker, set: setTracker},
        toolOwned: {data: toolOwned, set: setToolOwned},
        __UserType: {data: __UserType, set: setUserType},
        globalLoader: {data: globalLoader, set: setGlobalLoader},
        validateAccount: {data: validateAccount, set: setValidateAccount},
    }
}
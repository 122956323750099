import { useState } from 'react'

export const DialogState = () => {
    const [prompter, setPrompter] = useState({open: false, title:  '', content: '', btnTxt1: '', btnTxt2: '', btnStat1: null, btnStat2: null})
    const [mobDirNmsg, setMobDirNmsg] = useState({dlgStat: false, init: '', datastate: null})
    const [mobGrpNmsg, setMobGrpNmsg] = useState({dlgStat: false, init: '', datastate: null})
    const [mobGrpUpmsg, setMobGrpUpmsg] = useState({modalStat: false, init: '', datastate: null, members: [], gcname: '', gcprofile: '', cchatid: ''})
    const [pMsg, setPmsg] = useState({base: [], search: [], list: [], isSearch: false})
    return {
        prompt: {data: prompter, set: setPrompter},
        mobDirect:{data: mobDirNmsg, set: setMobDirNmsg},
        mobGroup:{data: mobGrpNmsg, set: setMobGrpNmsg},
        updateGroup:{data: mobGrpUpmsg, set: setMobGrpUpmsg}, 
        proData:{data: pMsg, set: setPmsg},
    }
}

import { forwardRef, useContext } from "react"
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";
import { ToolContext } from "../../context/ToolContext";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FunctionPromt = () => {
    const {dialog_state} = useContext(ToolContext)
    let promtprop = dialog_state.prompt
    const handleClose = () => {
        promtprop.set({open: false, title:  '', content: '', btnTxt1: '', btnTxt2: '', btnStat1: null, btnStat2: null})
    };
    return (
        <Dialog
            open={promtprop.data.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle>{promtprop.data.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {promtprop.data.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>Disagree</Button> */}
                <Button onClick={handleClose}>{promtprop.data.btnTxt1}</Button>
            </DialogActions>
      </Dialog>
    )
}

import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';
// import { TC } from '../../components/layouts/tc/Index'
// import { TCDrawer } from '../../components/layouts/tc/drawer/Index';
import { Category } from '../../__personal/components/layouts/switching/Category';
import { FunctionHomeBiz } from '../../__business/components/home/FunctionHomeBIz';
import { Settings } from '../../__business/components/settings/Index';
import { RenderAPI } from '../../__business/components/global/RenderAPI';
import { WebRoutePsl } from './PslRoute';
import { Switching } from '../../components/layouts/switching/Index';
import FunctionConnectionsBiz from '../../__business/components/connections/FunctionConnectionsBiz';
import FunctionCommunity from '../../__business/components/community/FunctionCommunity';

let view = VM();
const Navigation = lazy(() => import(`../../components/layouts/navigations${view}/Index`))

export const WebRouteBiz = () => {
    const location = useLocation().pathname;

    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/biz" render={props=>(Render(FunctionHomeBiz, props, location))}/>
            <Route exact path="/biz/community" render={props=>(Render(FunctionCommunity, props, location))}/>
            <Route exact path="/biz/switching" render={props=><Switching/>} />
            <Route exact path="/biz/more" render={props=>(Render(Settings, props, location))} />
            <Route exact path="/psl" render={props=>(MRender(WebRoutePsl, props, location))} />
            {/* <Route exact path="/biz/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} /> */}

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
        // <Switch>
        //     { view !== "" ?
        //         <>
        //             {/* <Navigation side={0} /> */}
        //             {/* HOME ROUTES */}
        //             <Route exact path="/biz" render={props=>(MRender(FunctionHomeBiz, props))} />
        //             <Route exact path="/biz/more" render={props=>(MRender(Settings, props, location))} />
        //             {/* 404 NOT FOUND */}
        //             <Route>
        //                 {/* <Container>
        //                     <Box display="flex">
        //                         Error 404
        //                     </Box>
        //                 </Container> */}
        //             </Route>
        //         </>
        //     :
        //         <>
        //             {/* <Navigation side={0} /> */}
        //             {/* HOME ROUTES */}
        //             <Route exact path="/biz" render={props=>(Render(FunctionHomeBiz, props))} />

        //             {/* 404 NOT FOUND */}
        //             <Route>
        //                 <Container>
        //                     <Box display="flex">
        //                         Error 404
        //                     </Box>
        //                 </Container>
        //             </Route>
        //         </>
        //     }
        // </Switch>
    )
}

const Render = (Component, props, location) => (
    <>
        {view === '' ? (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {/* {(location !== '/biz/profiling') && (<Navigation side={1} />)} */}
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        {/* <Navigation side={0} /> */}
                        <FunctionConnectionsBiz/>
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {(location !== '/biz/profiling' && location !== '/biz/more') && (
                        <Box minHeight={location === '/biz' ? '92px' : '40px'}>
                            <Navigation side={0} />
                        </Box>
                    )}
                    
                    <Box height="100%" width="100%" className="overflowY noScrollcss">
                        {(location !== '/biz/profiling' && location !== '/biz/more' && location !== '/psl') ?
                            <Box><Category/></Box>  
                        :
                            <Box></Box>
                        }
                        <Component {...props} />
                    </Box>
                    {(location !== '/profile' && location !== '/biz/profiling') &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        )}
        <RenderAPI/>
    </>
)

// const Render = (Component, props) => {
//     return (
//         <Box width="100%" height="100%">
//             <Navigation side={0} />
//             <Box display="flex" width="100%" height="100%">
//                 <Navigation side={1} />
//                 <TC />
//                 <Box component="main" flex={1} mt={7}>
//                     <Box sx={{position: 'relative'}} width="100%" height="100%">
//                         <Box p={3}><Component {...props} /></Box>
//                         <TCDrawer />
//                     </Box>
//                 </Box>
//             </Box>
//             <Navigation side={2} />
//         </Box>
//     )
// }

const MRender = (Component, props) => {
    return (
        <Box width="100%" height="100%"> {props.location.pathname.includes('calling') ?
            (
                <Component {...props} />
            ) : (
                <Box width="100%" height="100%">
                    <Navigation side={0} />
                    <Box display="flex" width="100%" >
                        <Box component="main" width="100%" sx={{ zIndex: 1100 }}>
                            <Box sx={{ position: 'relative' }} width="100%" >
                                <Box width="100%" >
                                    <Box width="100%" height="100%" sx={{ borderRadius: '12px 12px 6px 6px', backgroundColor: '#FFFFFF', zIndex: 2 }} p={.5} overflowy="hidden">
                                        {/* <Category /> */}
                                        <Component {...props} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Navigation side={2} />
                </Box>
            )}
        </Box>
    )
}
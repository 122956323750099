import { Box } from "@mui/material"
// import { ActiveHome, InactiveProf } from "../../../../core/global/Icons";
import { useHistory, useLocation } from "react-router-dom"
import { useContext } from "react";
import { ToolContext } from "../../../../core/context/ToolContext";

const commonStyles = {
    borderColor: 'text.primary',
    width: '120%',
    maxWidth: 500,
};
export const Category = () => {
    const {dialog_state} = useContext(ToolContext)
    let promtprop = dialog_state.prompt
    let nav = new useHistory();
    let location = useLocation().pathname.split('/')
    const handleClick  = (data) =>{
        nav.push(data)
    }
    const GPromtCaller = (open, title, content, btnTxt1, btnTxt2, btnStat1, btnStat2) => {
        promtprop.set({open: open, title:  title, content: content, btnTxt1: btnTxt1, btnTxt2: btnTxt2, btnStat1: btnStat1, btnStat2: btnStat2})
    }
    
    return (
        <>
            { location[1] !== 'biz' ?
                <Box height="100%" width="100%" p={1.5} mt={-3} >
                    <Box my={2} fontWeight={600} fontSize="16px" >Connections</Box>
                    <Box height="100%" width="100%" display="flex" justifyContent="space-evenly" fontWeight="600" fontSize="14px" >
                        <Box width="165.5px" height="20px" bgcolor={ location[1] !== 'biz' ? "#256EFF" : "rgb(17, 120, 60) !important"} display="flex" justifyContent="center" borderRadius="6px" alignItems="center" sx={{ ...commonStyles, borderBottom: 3, color:location[2] !== 'pro' ? "#FFFFFF":"#FFFFFF" }} py={1.5} onClick={(e) => handleClick('/psl')}>
                            {/* <SvgIcon component={ActiveHome} fill={location[2] === 'pro' ? 'none' : '#4d5eff'}/>  */}
                            <Box style={{color: location[2] !== 'pro' ? "#FFFFFF":"rgba(139, 133, 176, 1)"}} ml={1}>Home</Box>
                        </Box>
                        <Box width="165.5px" height="20px"  display="flex" justifyContent="center" alignItems="center" py={1.5} onClick={(e) => GPromtCaller(true, 'Hi Hero User!', 'The features of the PRO are not accessible at the moment, but we will keep you informed when they become available.', 'Got it!', 'testTile1', 'testTile1')}
                            sx={{ ...commonStyles, borderBottom: 3, color:location[2] === 'pro' ? "#FFFFFF":"transparent" }} 
                        >
                            {/* <SvgIcon component={InactiveProf} fill={location[2] !== 'pro' ? 'none' : '#4d5eff'} /> */}
                            { location[1] !== 'biz' ?
                                <Box style={{color: location[2] === 'pro' ? "rgba(40, 55, 69, 1)":"rgba(139, 133, 176, 1)"}}  ml={1}>Pro</Box>
                            :
                                <Box style={{color: location[2] === 'pro' ? "rgba(40, 55, 69, 1)":"rgba(139, 133, 176, 1)"}}  ml={1}>Tool</Box>
                            }
                        </Box>
                    </Box>
                </Box>
                :
                <Box height="100%" width="100%" p={1.5} mt={-3} sx={{borderRadius: '16px 16px 0px 0px', backgroundColor: '#FAFAFF', zIndex: 1}}>
                    <Box my={2} fontWeight={600} fontSize="16px" >Connections</Box>
                    <Box height="100%" width="100%" display="flex" justifyContent="space-evenly" fontWeight="600" fontSize="14px" >
                        <Box width="165.5px" height="20px" bgcolor={ location[1] !== 'biz' ? "#256EFF" : "rgb(17, 120, 60) !important"} display="flex" justifyContent="center" borderRadius="6px" alignItems="center" sx={{ ...commonStyles, borderBottom: 3, color:location[2] !== 'pro' ? "#FFFFFF":"#FFFFFF" }} py={1.5} onClick={(e) => handleClick('/psl')}>
                            {/* <SvgIcon component={ActiveHome} fill={location[2] === 'pro' ? 'none' : '#4d5eff'}/>  */}
                            <Box style={{color: location[2] !== 'pro' ? "#FFFFFF":"rgba(139, 133, 176, 1)"}} ml={1}>Home</Box>
                        </Box>
                        <Box width="165.5px" height="20px"  display="flex" justifyContent="center" alignItems="center" py={1.5} onClick={(e) => GPromtCaller(true, 'Hi Hero User!', 'The features of the PRO are not accessible at the moment, but we will keep you informed when they become available.', 'Got it!', 'testTile1', 'testTile1')}
                            sx={{ ...commonStyles, borderBottom: 3, color:location[2] === 'pro' ? "#FFFFFF":"transparent" }} 
                        >
                            {/* <SvgIcon component={InactiveProf} fill={location[2] !== 'pro' ? 'none' : '#4d5eff'} /> */}
                            { location[1] !== 'biz' ?
                                <Box style={{color: location[2] === 'pro' ? "rgba(40, 55, 69, 1)":"rgba(139, 133, 176, 1)"}}  ml={1}>Pro</Box>
                            :
                                <Box style={{color: location[2] === 'pro' ? "rgba(40, 55, 69, 1)":"rgba(139, 133, 176, 1)"}}  ml={1}>Tool</Box>
                            }
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}



import { Box, Backdrop, Modal, Fade, Grid } from '@mui/material';
import { domain } from '../../../core/Env';
import { Options } from './Options';
import { Auth } from './Auth';
import { Verification } from './Verification';
import { useState } from 'react';
import { swchstyle, LogoAnimator } from '../../../core/styling/switching';
import { motion } from 'framer-motion';
import { Add as IAdd } from '@mui/icons-material';
import PSLLogo from '../../../assets/images/logos/logo_v1.png'
import BIZLogo from '../../../assets/images/logos/biz_logo.png'

export const Switching = ({__SESSION, switchForm, setSwitchForm}) => {
    const [opt1form, setOpt1form] = useState({ stat: false, step: '1', valid_otp: '', inputs: {account:{value: '', stat: false, msg: ''}, otp:{value: '', stat: false, msg: '', toggle: false}} });
    const classes = swchstyle();
    const logoAnimator = LogoAnimator()
    const handleClose = () =>{
        setSwitchForm({...switchForm, stat: false})
    }

    const redirectHandler = (type) => {
        let aid = __SESSION.ainfo.aid;
        // let email = window.btoa(__SESSION.ainfo.email);
        if (type === 'op1') {
            if (__SESSION.ainfo.email !== null) {
                let sainfo = {
                    account: window.btoa(`unauth_${__SESSION.ainfo.email}`),
                    va: 'email',
                    step: 2,
                    srf: '!default'
                }
                window.location.href = `${domain('pm').url}/${switchForm.platform}/switching/option1?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`
            } else {
                setOpt1form({...opt1form, stat: true})
            }
        }else if(type === 'op2'){
            let sainfo = {
                aid: aid,
                va: 'email'
            }
            window.location.href = `${domain('pm').url}/${switchForm.platform}/switching/option2?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`
        }else{
            let sainfo = {
                aid: aid,
                va: 'email'
            }
            window.location.href = `${domain('pm').url}/${switchForm.platform}/switching/option3?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`
        }
    }

    return ( 
        <Modal
            open={switchForm.stat}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={switchForm.stat}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box position="absolute" width={378} bgcolor={switchForm.platform==='psl'?'#F5F9FE':'#EFF6F3'} borderRadius="15px" p={2} className={classes.top_bg}>
                        <Box component={motion.div} variants={logoAnimator.loaderContainer} initial="start" animate="end" display="flex" justifyContent="center" alignItems="center" p={5}>
                            <Box component={motion.div} style={{transform: `rotate(-15deg)`}} variants={logoAnimator.cVariants1} transition={logoAnimator.cTransition}><img src={PSLLogo} alt={BIZLogo} width={45} /></Box>
                            <Box component={motion.div} variants={logoAnimator.cVariants} transition={logoAnimator.cTransition} px={2}><IAdd /></Box>
                            <Box component={motion.div} style={{transform: `rotate(15deg)`}} variants={logoAnimator.cVariants2} transition={logoAnimator.cTransition}><img src={BIZLogo} alt={PSLLogo} width={60} /></Box>
                        </Box>
                        <Grid container spacing={3}>
                            {
                                opt1form.stat ? (
                                    <Grid item xs={12}>
                                        {
                                            (opt1form.step==='10' || opt1form.step==='1') ? (
                                                <Auth classes={classes} opt1form={opt1form} setOpt1form={setOpt1form} />
                                            ) : (
                                                <Verification classes={classes} opt1form={opt1form} setOpt1form={setOpt1form} />
                                            )
                                        }
                                    </Grid>
                                ) : (
                                    <Options __SESSION={__SESSION} switchForm={switchForm} setSwitchForm={setSwitchForm} classes={classes} logoAnimator={logoAnimator} redirectHandler={redirectHandler} />
                                )
                            }
                        </Grid>   
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
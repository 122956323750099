import { useState } from "react"
import { HomeIcons, NotificationIcons, TcIcons, MyToolIcons, ReferUserIcons, RenewIcons, GroupIcon, MessageIcon, InactiveGroup } from '../global/Icons';

export const NavigationStates = () => {
    const [tabs, setTabs] = useState({
        top: [
            {name:'Notifications', className:'', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#333333" />, active: <NotificationIcons  fill="#3D77E9" />},
            {name:'MyTools', className:'tenth-step', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#292D32" />, active:<MyToolIcons fill="#3D77E9" stroke="#3D77E9" />},
        ],
        mtop: [
            {name:'Notifications', className:'', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#fff" />, active: <NotificationIcons fill="#3D77E9" />},
            {name:'MyTools', className:'tenth-step', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#FFFFFF" stroke="#FFFFFF" />, active:<MyToolIcons fill="#3D77E9" stroke="#3D77E9"/>},
        ],
        left: [
            {name:'Chat', className:'first-step',  subdir:'/psl', ref: undefined, not_active:<HomeIcons stroke="#8F8DA9" />, active:<HomeIcons fill="#3D77E9" stroke="#fff"/>},
            {name:'Group', className:'second-step', subdir:'/psl/group', ref: 'group', not_active:<GroupIcon stroke="#8F8DA9" />, active:<GroupIcon fill="#3D77E9" stroke="#3D77E9"/>},
            // {name:'Tool Categories', className:'third-step', subdir:'/psl/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcons stroke={'#89A594'}  fill={'#fff'} />, active:<TcIcons fill="#3D77E9" stroke="#3D77E9"/>},
        ],
        msetting: [
            {name:'Refer a Hero Users', subdir:'/psl', ref: 'psl', steps: 'sixth-step', not_active:<ReferUserIcons fill="#fff" stroke="#A2A3A9"/>, active:<ReferUserIcons fill="#333333" stroke="#333333"/>},
            {name:'System Updates', subdir:'/psl', ref: 'psl', steps: 'seventh-step', not_active:<RenewIcons fill="none" stroke="#89A594"/>, active:<RenewIcons fill="#333333" stroke="#333333"/>},
        ],
        mobile: [
            {name:'Chats Individual', sub_name:'', subdir:'/biz', ref: undefined, steps:'first-step', not_active:<MessageIcon stroke="#89A594" />, active:<MessageIcon fill="#11783C" stroke="#FFFFFF"/>},
            {name:'Community', sub_name:'', subdir:'/biz/community', ref: 'community', steps:'second-step', not_active:<GroupIcon stroke="#B1C2D2"/>, active:<GroupIcon fill="#11783C" stroke="#11783C"/>},
            {name:'Transactions', sub_name:'', steps:'third-step', subdir:'/biz', ref: 'transactions',not_active:<HomeIcons stroke="#B1C2D2"/>, active:<HomeIcons fill="#B1C2D2" stroke="#fff"/>},
            {name:'Business Cloud', sub_name:'', steps:'third-step', subdir:'/biz', ref: 'tool-categories', not_active:<TcIcons stroke={'#B1C2D2'}  fill={'#fff'} />, active:<TcIcons fill="#107038" stroke="#107038"/>},
        ], 
        mobilepsl: [
            {name:'Chats Individual', sub_name:'', subdir:'/psl', ref: undefined, steps:'first-step', not_active:<MessageIcon stroke="#89A594" />, active:<MessageIcon fill="#4D5EFF" stroke="#FFFFFF"/>},
            {name:'Group Chats', sub_name:'', subdir:'/psl/group', ref: 'group', steps:'second-step', not_active:<InactiveGroup stroke="#687B75"/>, active:<InactiveGroup fill="#4D5EFF" stroke="#4D5EFF"/>},
            // {name:'Transactions', sub_name:'', steps:'third-step', subdir:'/biz', ref: 'feature2',not_active:<HomeIcons stroke="#687B75"/>, active:<HomeIcons fill="#11783C" stroke="#fff"/>},
            // {name:'Business Cloud', sub_name:'', steps:'third-step', subdir:'/biz/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcons stroke={'#89A594'}  fill={'#fff'} />, active:<TcIcons fill="#107038" stroke="#107038"/>},
        ]
    })
    const [tc, setTc] = useState({open: true, list: false})
    const [leftBar, setLeftbar] = useState(true)
    const [notif,  setNotif] = useState(false)
    const [myTool, setMyTool] = useState(false)
    const [myToolPzl, setMyToolPzl] = useState(false)

    return {
        tabs: {data: tabs, set: setTabs},
        tc: {data: tc, set: setTc},
        leftBar: {data: leftBar, set: setLeftbar},
        notif: {data: notif, set: setNotif},
        myTool: {data: myTool, set: setMyTool},
        myToolPzl: {data: myToolPzl, set: setMyToolPzl},
    }
}
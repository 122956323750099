import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { socket } from '../../service/socket';
import Peer from "simple-peer";
import { ToolContext } from '../context/ToolContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const useVideoLogic = () => {
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState(null);
  const [senderRoomId, setSenderRoomId] = useState(null);
  const [name, setName] = useState("");
  const [callerUser, setCallerUser] = useState(null)
  const [callMode, setCallMode] = useState('');
  const [countIncoming, setIncomingCall] = useState(0);


  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const myVideo = useRef(null);

  const { tool_state, connection_state, calling_state } = useContext(ToolContext)
  const callAccept = calling_state.callAccepted
  const profInfo = tool_state.__SESSION.data.pinfo
  const callStream = calling_state.stream
  const currentUrl = window.location.pathname;
  const ringingPlay = calling_state.isRinging;
  let { conId } = useParams();


  // Fetches user list for direct connections
  const getUserList = useMemo(() => {
    if (conId) {
      return connection_state.directData.data.list.find((item) => item.connectionChatID === conId)?.connectedUser || [];
    }
    return []
  }, [connection_state, conId])


  // Fetches user list for group connections
  const getUsersGroupList = useMemo(() => {
    if (conId) {
      return connection_state.groupData.data.list.find((item) => item.connectionChatID === conId)?.connectedUser || [];
    }
    return []
  }, [connection_state, conId])



  useEffect(() => {
    // Receives the unique socket ID for the current user
    socket.on("me", (id) => {
      setMe(id);
    });
  }, []);

  // Initializes user media (video)
  const loadVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        setStream(stream);
        if (myVideo.current) {
          myVideo.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });
    callInfo();
  }

  // Listens for incoming calls
  const callInfo = () => {
    socket.on("callUser", (data) => {
      localStorage.setItem('callingState', data.data.callType);
      localStorage.removeItem('callMode', data.data.callMode);
      setReceivingCall(true);
      ringingPlay.set(true)
      callAccept.set(false)
      setCaller(data.from);
      setName(data.name);
      setCallMode(data.data.callMode)
      setCallerSignal(data.signal);
      setSenderRoomId(data.roomId)
      setCallerUser(data);
    });
  }

  // Initiates a call to another user
  const callUser = (e, call, remoteuser, callMode) => {

    localStorage.setItem('callingState', call);
    socket.emit('remoteUser', remoteuser)

    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: callStream.data,
    });
    // Sends the call signal to the server
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        signalData: data,
        roomId: conId,
        users: getUserList.length > 0 ? getUserList : getUsersGroupList,
        userId: atob(profInfo[0].aid),
        callType: call,
        callMode: callMode,
        callerId: tool_state.__SESSION.data.graphAid.id,
        from: `${profInfo[0].first_name} ${profInfo[0].last_name}`,
        name: getUserList.length > 0 ? `${profInfo[0].first_name} ${profInfo[0].last_name}` : remoteuser,
      }, (onCallUser, isCallStart) => {
        // connectionRef.current = peer;
        if (isCallStart) {
          let url = currentUrl.includes('group') ? `/psl/group/c/${conId}/calling` : `/psl/c/${conId}/calling`;
          try {
              setTimeout(() => {
                window.open(url, '_blank');
              })
            } catch (error) {
                window.location.href = url;
          }
          // window.open(url, '_blank');
          // nav.push(url)
        }
        const messages = []
        onCallUser.forEach((item) => {
          messages.push(`${item.user.name} on Another call`)
        })
        if (messages.length > 0) {
          toast.warn(messages.join(', '));
        }
      });
    });

    // Listens for the call being accepted
    socket.on("callAccepted", (signal) => {
      callAccept.set(true);
      peer.signal(signal);
    });
  };

  // Answers an incoming call
  const answerCall = () => {
    callAccept.set(true);
    ringingPlay.set(false)
    let url = callMode === 'group' ? `/psl/group/c/${senderRoomId}/calling` : `/psl/c/${senderRoomId}/calling`;
    try {
        setTimeout(() => {
			window.open(url, '_blank');
		})
    } catch (error) {
        window.location.href = url;
    }
    // window.open(url);
    // socket.emit('acceptedCall', {
    //   roomId: senderRoomId,
    // }, (status) => {
    //   if (status) {
    //     let url = callMode === 'group' ? `/psl/group/c/${senderRoomId}/calling` : `/psl/c/${senderRoomId}/calling`;
    //         try {
    //             window.open(url, '_blank');
    //         } catch (error) {
    //             window.location.href = url;
    //         }
    //   } else {
    //     toast.warn('The room is full. You cannot join.');
    //   }
    // });
    // nav.push(url)
  };

  // Declines an incoming call
  const leaveCall = () => {
    setReceivingCall(false);
    ringingPlay.set(false)
    callAccept.set(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    socket.emit("declineCall", callerUser)
  };


  // Auto declines the call after 30 seconds if not answered
  useEffect(() => {
    if (receivingCall && !callAccept.data) {
      if (countIncoming === 30) {
        leaveCall()
      } else {
        setTimeout(() => {
          setIncomingCall(countIncoming + 1)
        }, 1000)
      }
    }
  }, [receivingCall, callAccept.data, countIncoming])

  return {
    me,
    stream,
    receivingCall,
    caller,
    callerSignal,
    name,
    userVideo,
    connectionRef,
    myVideo,
    callerUser,
    callUser,
    answerCall,
    leaveCall,
    loadVideo,
    callInfo,
    setReceivingCall,
  };

};

export default useVideoLogic;
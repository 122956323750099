import { useState } from "react"
import { Box } from "@mui/material"

export const PersonalizationState = () => {
    const [tourOpen, setTourOpen] = useState(false)
    const [tourStep, setTourStep] = useState([])
    const [tourData, setTourData] = useState([])
    const [tourSTUStep, setTourSTUStep] = useState(
        [
            {selector: '.first-step', content: () => (
                <Box width="auto" height="auto">
                    <Box width="auto" height="auto"  bgColor="#1B1B1B" display="flex" justifyContent="space-between">
                        <Box fontSize={"14px"} fontWeight="700" color="#4E5FF6">Home</Box>
                        {/* <Box fontSize={12} color="#1B1B1B"> 1 of 7</Box> */}
                    </Box>
                    <Box fontSize={12} mt={.5} color="#1B1B1B"> Make connection with your family and friends.</Box>
                    <Box bgcolor="#ffffff" width="18px" height="18px" position="absolute" ml="19px" mt="52px" sx={{transform:'translateY(-50%) rotate(45deg)', }}></Box>
                </Box>
            ),
                position: 'top',
            },
            {selector: '.second-step', content: () => (
                <Box width="auto" height="auto">
                    <Box bgColor="#1B1B1B" display="flex" justifyContent="space-between" mt={-1}>
                        <Box fontSize={"14px"} fontWeight="700" color="#4E5FF6">Pro</Box>
                        {/* <Box fontSize={12} color="#1B1B1B"> 2 of 7</Box> */}
                    </Box>
                    <Box fontSize={12} mt={1.5} color="#1B1B1B" mb={1}>Connect with people for work communication and collaboration.</Box>
                    <Box bgcolor="#ffffff" width="18px" height="18px" position="absolute" ml="165px" mt="45px" sx={{transform:'translateY(-50%) rotate(45deg)', }}></Box>
                </Box>
            ),
                position: 'top',
            },
            {selector: '.third-step', content: () => (
                <Box width="auto" height="auto">
                    <Box bgColor="#1B1B1B" display="flex" justifyContent="space-between">
                        <Box fontSize={"14px"} fontWeight="700" color="#4E5FF6">Groups</Box>
                        {/* <Box fontSize={12} color="#1B1B1B"> 3 of 7</Box> */}
                    </Box>
                    <Box fontSize={12} mt={1.5} color="#1B1B1B">Create connections with a group of Hero Users.</Box>
                    <Box bgcolor="#ffffff" width="18px" height="18px" position="absolute" ml="200px" mt="52px" sx={{transform:'translateY(-50%) rotate(45deg)', }}></Box>
                </Box>
            ),
                position: 'top',
            },
            {selector: '.switching', content: () => (
                <Box width="auto" height="auto">
                    <Box  bgColor="#1B1B1B" display="flex" justifyContent="space-between">
                        <Box fontSize={"14px"} fontWeight="700" color="#4E5FF6">Switch to main platform</Box>
                        {/* <Box fontSize={12} color="#1B1B1B"> 5 of 7</Box> */}
                    </Box>
                    <Box fontSize={12} mt={1.5} color="#1B1B1B">You can seamlessly access chat platforms</Box>
                    <Box bgcolor="#ffffff" width="18px" height="18px" position="absolute" ml="115px" mt="-72px" sx={{transform:'translateY(-50%) rotate(45deg)', }}></Box>
                </Box>
            ),
                position: 'right',
            },
        ]
    )

    return {
        // categories: {data: categories, set: setCategories},
        tourOpen: {data: tourOpen, set: setTourOpen},
        tourStep: {data: tourStep, set: setTourStep},
        tourData: {data: tourData, set: setTourData},
        tourSTUStep: {data: tourSTUStep, set: setTourSTUStep}
    }
}
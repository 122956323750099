import { Box, Divider, Grid, Button } from '@mui/material';
import { avatarstylesx } from '../../../core/styling/global';
import PSLLogo from '../../../assets/images/logos/logo_v1.png'

export const Options = ({ __SESSION, switchForm, redirectHandler, classes, logoAnimator }) => {
    return (
        <Grid item xs={12} m={0.5}>
            <Box fontSize={24}><b>Link Account Options</b></Box>
            <Box fontSize={12}>Access {switchForm.platform==='psl'?'POFPersonal':'POFBusiness'} to grow your extraordinary {switchForm.platform==='psl'?'career':'business'}</Box>
            <Box fontSize={14} mt={3} mb={1.2}>I want to:</Box>
            {
                (__SESSION.ainfo.user_type === 1 && (__SESSION.ainfo.account_type === 1 || __SESSION.ainfo.account_type === 2)) && (
                    <Box>
                        <Box fontSize={14} mb={1}><b>Use Personal Account</b></Box>
                        <Grid container direction="column" borderRadius={2} className="shadow c-pointer" sx={{backgroundColor: '#ffffff'}} onClick={()=>redirectHandler('op1')}>
                            <Box fontSize={11} color="#00BE81" textAlign="right" mr={1}>Recommended</Box>
                            <Box display="flex" alignItems="center" mb={1.5}>
                                <Box sx={avatarstylesx(__SESSION.pinfo.length > 0 ? JSON.parse(__SESSION.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.pinfo[0].profile).profile : PSLLogo : PSLLogo, '2.5rem', '2.5rem')} borderRadius={3} mx={1} />
                                <Box>
                                    <Box><b>{__SESSION.pinfo.length > 0 ? `${__SESSION.pinfo[0].first_name} ${__SESSION.pinfo[0].last_name}` : 'Anonymous'}</b></Box>
                                    <Box fontSize={12}>{__SESSION.ainfo.email !== null ? __SESSION.ainfo.email : __SESSION.ainfo.mobile_num}</Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Box my={4} mx={3}><Divider /></Box>
                    </Box>
                )
            }
            <Grid container direction="column" borderRadius={2} className="shadow c-pointer" sx={{backgroundColor: '#ffffff'}} onClick={()=>redirectHandler('op2')}>
                <Box display="flex" alignItems="center" m={1}>
                    <Box sx={avatarstylesx(PSLLogo, '2.5rem', '2.5rem')} borderRadius={3} mr={1} />
                    <Box fontSize={14}><b>Use existing {switchForm.platform==='psl'?'Personal':'Business'} account</b></Box>
                </Box>
            </Grid>
            <Box mt={2}><Button variant="contained" size="large" fullWidth sx={{borderRadius: '8px', backgroundColor: switchForm.platform==='psl'?'#0070EF':'#11783C'}} onClick={()=>redirectHandler('op3')}> + Create New Account </Button></Box>
        </Grid>
    )
}
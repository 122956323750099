import { createContext } from 'react';
import { InstallState } from './InstallState';
import { ToolStates } from './ToolStates';
import { NavigationStates } from './NavigationStates';
import { HomeStates } from './HomeStates';
import { StatisticsState } from './StatisticsState';
import { LeftNavStates } from './NavLeftStates';
import { ConnectionState } from './ConnectionState';
import { ConversationState } from './ConversationState';
import { LoaderState } from './LoaderState';
import { DialogState } from './DialogState';
import { DrawerState } from './DrawerStates';
import { InfoState } from './InfoState';
import { ConnectionProfileState } from './ConnectionProfileState';
import { SnackbarState } from './SnackbarState';
import { SnackbarStateUnsuc } from './SnackBarUnsuc';
import { UserValidateState } from './UserValidateState';
import { PersonalizationState } from './PersonalizationState';
import { CallingState } from './CallingState';
import { ToolCategoryState } from './ToolCategoryState';
export const ToolContext = createContext();

export const ToolProvider = ({ children }) => {
    let states = {
        install_state: InstallState(),
        tool_state: ToolStates(),
        home_state: HomeStates(),
        navigation_state: NavigationStates(),
        statistics_state: StatisticsState(),
        layout_drawer_state: LeftNavStates(),
        connection_state: ConnectionState(),
        conversation_state: ConversationState(),
        loader_state: LoaderState(),
        dialog_state: DialogState(),
        drawer_state: DrawerState(),
        info_state: InfoState(),
        calling_state: CallingState(),
        con_profile_state: ConnectionProfileState(),
        snack_state: SnackbarState(),
        account_type_states: UserValidateState(),
        personalization_state: PersonalizationState(),
        tool_category_state: ToolCategoryState(),
        snack_stateunsuc: SnackbarStateUnsuc(),
    }



    return (
        <ToolContext.Provider value={{ ...states }}>
            {children}
        </ToolContext.Provider>
    )
}
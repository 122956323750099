import { API, graphqlOperation } from "aws-amplify";
import { updateCheckVersion } from "../../../../graphql/mutations";
import { env } from "../../../Env";
import { rqx } from "../../../request/API";
import { Box, Dialog, DialogActions } from "@mui/material";
import { lazy, useContext, useEffect, useState } from "react";
import ModalNotifPic from '../../../../assets/images/core/ModalNotifPic.png'
import Prompt_Vector2 from '../../../../assets/images/core/prompt_vector2.png'
import { VM } from "../../../VM";
import { ToolContext } from "../../../context/ToolContext";
let view = VM();
const IndexModalUpdate = lazy(() => import(`.${view}/Index`))

    const ModalUpdateFunction = ({openVal, dataVal, newVerVal}) => {
        const { tool_state } = useContext(ToolContext)
        const [open, setOpen] = useState(false);
        const [data, setData] = useState([])
        const [version, setVersion] = useState([])
        const profInfo = tool_state.__SESSION.data.pinfo
        const __SESSION = JSON.parse(localStorage.getItem('pof_chat_session'))
        const handlerContinue = async() => {
            console.log(1)
        }

        const handlerApplyUpdates = async() => {
            let body = {
                user_id: window.atob(__SESSION.ainfo.aid).split("_")[1],
                feature_name: 'pchatp_prompt',
                tool_id:0,
                personalization: [{
                    status: 1,
                    sf_name: 'prompt'
                }],
                active: 1
            }
            let updatePsnlztn = await rqx.post(`<<global>>/${env()}/personalization/create`, body)
            if (updatePsnlztn.msg === 'success') {
            await API.graphql(graphqlOperation(updateCheckVersion, {input: {
                id: data.id,
                version: version
            }}))
            setOpen(false)
            window.location.href = '/'
            }
        }

        useEffect(() => {
            setData(dataVal)
            setVersion(newVerVal)
            setOpen(openVal)
        }, [dataVal, newVerVal, openVal])
        

        let textList = [{pic: '', title: '', body: '', footer:'', button: ''},
            {
                pic: <Box  width="100%"><img src={Prompt_Vector2} alt="No Conversation" width="100%"/></Box>,
                title: <Box fontWeight="700" fontSize="18px" mb={1.5} color="#635FF6" textAlign='start'  >Welcome!! </Box>,
                body: <Box fontSize="22px" fontWeight="400" textAlign='start' p={1.5}>Let me show you some great features.</Box>,
                footer: '',
                button: 
                        <Box  sx={{ textAlign: 'center', pt: 1, pb:1,  borderRadius:"8px", }} backgroundColor="#4E5FF6" color="#FFFFFF" zIndex={2} mb={2} className="c-pointer">
                            <Box textAlign="center"  sx={{borderRadius: '12px !important'}} px={1} minWidth="254px" onClick={(e) => handlerContinue()}>Proceed</Box>
                        </Box>,
            },
            {   pic: <Box mt={5}><img src={ModalNotifPic} width="100%" alt="No Conversation" /></Box>,
                title: <Box  fontSize="14px" fontWeight="600">New Update Available</Box>,
                body: <Box fontSize="14px" px={6} py={2} alignItems="start">Exciting News! We've rolled out a new update for Chat Personal. Here's What's new:</Box>,
                footer: <Box><Box fontSize="14px" p={2} alignItems="start">• <b>Minor Bug Fixes in mobile version and desktop</b></Box>
                            <Box fontSize="14px" p={2} alignItems="start" mt={-3.5}>• <b>Pin message status on conversation page</b></Box>
                            <Box fontSize="14px" p={2} alignItems="start" mt={-3.5}>• <b>Unpinned message status on conversation page</b></Box>
                            <Box fontSize="14px" p={2} alignItems="start" mt={-3.5}>• <b>Shorcut on viewing pin message</b></Box>
                            {/* <Box fontSize="14px" p={2} alignItems="start" mt={-3.5}>• <b>Adding more admin on the group chat</b></Box>
                            <Box fontSize="14px" p={2} alignItems="start" mt={-3.5}>• <b>Set up and change group profile picture</b></Box> */}
                        </Box>,
                button: <Box  sx={{ textAlign: 'center', pt: 1, pb:1,  borderRadius:"8px", }} backgroundColor="#4E5FF6" color="#FFFFFF" mb={2} className="c-pointer">
                            <Box textAlign="center"  sx={{borderRadius: '12px !important'}} px={1} minWidth="254px" onClick={(e) => handlerApplyUpdates()}>Apply Updates</Box>
                        </Box>,
            },
        ]
  return (
    <IndexModalUpdate
      textList={textList}
      handlerApplyUpdates={handlerApplyUpdates}
      Dialog={Dialog}
      DialogActions={DialogActions}
      open={open}
      profInfo={profInfo}
    />
  )
}

export default ModalUpdateFunction
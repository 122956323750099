// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ToolProvider } from './core/context/ToolContext';
// Assets
import './assets/css/global.css';
import './assets/css/scrollbar.css';
// Layouts
import { SSORqx } from './SSORqx';
import { env, domain } from './core/Env';
import VerifyReferral from './VReferral';
import { DirectRqx } from './DirectRqx';
import { CorePersonal } from './__personal/Core';
import { CoreBusiness } from './__business/Core';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const pageTheme = createTheme({
	palette: {
		background: {
			default: '#EBF2F0'
		},
		chatPrim: {
			main: '#635FF6',
			dark: '#1c474a',
			light: '#DCDFFF',
			lighter: '#F1F3FF',
		},
		chatText: {
			main: '#333333',
			dark: '#1c474a',
			light: '#8F8DA9',
			lighter: '#FFFFFF',
		},
		primary: {
			main: '#FFFFFF',
			dark: '#1c474a',
			light: '#F5F9F9',
			lighter: '#f8f9fa',
		},
		secondary: {
			main: '#669ede',
			dark: '#7aa3a1',
			light: '#ffffff',
		},
		third: {
			main: '#8D949E',
			dark: '#1c474a',
			light: '#F5F9F9',
			lighter: '#f8f9fa',
		},
		foruth: {
			main: '#8D949E',
			dark: '#1c474a',
			light: '#F5F9F9',
			lighter: '#f8f9fa',
		},
		info: {
			main: '#7aa3a1',
			dark: '#506e6c',
			light: '#b8d4d2',
		},
		error: {
			main: '#f44336',
			light: '#f6685e',
			dark: '#d32f2f'
		},
		warning: {
			main: '#ff9800',
			dark: '#b26a00',
			light: '#ffac33'
		},
		success: {
			main: '#4caf50',
			dark: '#388e3c',
			light: '#81c784'
		}
	},
	typography: {
		button: {
			textTransform: 'none'
		},
		fontFamily: [
			'-apple-system',
			'"Inter"',
		].join(','),
	},
});
const __SESSION = JSON.parse(localStorage.getItem('pof_chat_session'))
const loc = window.location.pathname
const splitVal = loc.split('/')[1]
let Core

if (loc === '/sso/account/auth' || loc === 'psl/sso/account/auth') {
	Core = SSORqx
} else if (loc === '/vr') {
	Core = VerifyReferral
} else if (loc === '/pchat/biz/direct/auth' || loc === '/pchat/psl/direct/auth') {
	Core = DirectRqx
} else if (__SESSION !== null) {
	if (splitVal === 'psl') {
		Core = CorePersonal
	} else if (splitVal === 'biz') {
		Core = CoreBusiness
	} else {
		window.location.href = `${__SESSION.platform === 'business' ? 'biz' : 'psl'}`
	}
} else {
	window.location.href = `${domain('pm').url}/sso/rqx?from=PCHT_SSO_RQX&callback=${env() === 'dev' ? 'development' : env() === 'sb' ? 'sandbox' : env()}&platform=personal`
}

ReactDOM.render(<ThemeProvider theme={splitVal === 'psl' ? pageTheme : pageTheme}><CssBaseline /><StyledEngineProvider injectFirst><ToolProvider><Core />
	<ToastContainer
		position="top-right"
		autoClose={2000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="light"
	/></ToolProvider></StyledEngineProvider></ThemeProvider>, document.getElementById('root'));

import { useState } from 'react'

export const DrawerState = () => {
    const [conlistdrawer, setConlistdrawer] = useState({open: false, data: null, status: null})
    const [longpressdrawer, setLongpressrawer] = useState({open: false, data: null, status: null})
    const [newgcDrawer, setNewgcDrawer] = useState({open: false, gcdata: {gcName: '', gcdp: ''}, members: [], status: null})
    const [ngcDp, setNgcDp] = useState({stat: false, newDp: '' })
    return {
        connectionList:{data: conlistdrawer, set: setConlistdrawer},
        cilongpress:{data: longpressdrawer, set: setLongpressrawer},
        newgcinfo:{data: newgcDrawer, set: setNewgcDrawer},
        newgcDp:{data: ngcDp, set: setNgcDp},
    }
}

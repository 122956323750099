import { Box, Modal } from '@mui/material'
import React from 'react'
import { Buttons } from '../form/Buttons'

export const PromptModalDesk = ({ isOpen, header, title, contents = "", fs = 20, btnLeft, btnLeftText = null, btnRight, btnRightText = null, disabled = false, }) => {

    return (
        <Modal open={isOpen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2000' }}>
            <Box width="auto" height="auto" minHeight="auto" bgcolor="#FFFFFF" borderRadius="13px" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" pb={2}>
                <Box width="100%"><img src={header} alt="No Conversation" width="100%" /></Box>
                <Box fontSize={fs} textAlign="center" component="strong" mt={3} p={2}>{title}</Box>
                <Box fontSize={14} textAlign="center" fontWeight={500} p={1}>{contents}</Box>
                <Box width="80%" display="flex" justifyContent="center" margin="10px 0px" mt={4} >
                    {
                        btnLeftText && btnRightText ?
                            <Box width="80%" display="flex" justifyContent="space-between">
                                <Box>
                                    <Buttons disabled={false} text={btnLeftText} height="48px" borderRadius="8px" event={btnLeft} onClick={btnLeft} variant="contained" color="primaryPsl" width="100%" />
                                </Box>
                                <Box>
                                    <Buttons disabled={false} text={btnRightText} height="48px" borderRadius="8px" event={btnRight} onClick={btnRight} variant="contained" color="primaryPsl" width="100%" />
                                </Box>
                            </Box>
                            :
                            <Buttons disabled={false} text={btnLeftText} height="48px" borderRadius="8px" event={btnLeft} onClick={btnLeft} variant="contained" color="primaryPsl" width="100%" />
                    }
                </Box>
            </Box>
        </Modal>
    )
}

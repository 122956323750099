import { useState } from "react"

export const ConversationState = () => {
    const [dConvo, setDConvo] = useState({ccdata: '', chats: [], nav: []})
    const [gConvo, setGConvo] = useState({ccdata: '', chats: [], nav: []})
    const [pConvo, setPConvo] = useState({ccdata: '', chats: [], nav: []})
    const [topBar, setTopBar] = useState({dp: 'Pofsis', dn: 'Hero User', id: ''})
    const [selectedReply, setSelectedReply] = useState({stat: false, data: [], recipient: ''})
    const [editMessage, setEditMessage] = useState({stat: false, data: []})
    const [forwardMessage, setForwardMessage] = useState({stat: false, data: []})
    const [message, setMessage] = useState('')
    const [gfMessage, setGfMessage] = useState('')
    const [attachment, setAttachment] = useState([])
    const [ggdp, setGgdp] = useState([])
    const [chats, setChats]= useState({cdata: [], nextToken: '', counter: 1, chatStarted: ''})
    const [attachments, setAttachments]= useState({assdata: [], nextToken: '', counter: 1, chatStarted: ''})
    const [roles, setRoles] = useState({stat: false, data: []})
    return {
        directConvo:{data: dConvo, set: setDConvo},
        groupConvo:{data: gConvo, set: setGConvo},
        proConvo:{data: pConvo, set: setPConvo},
        tdData:{data: topBar, set: setTopBar},
        sreply:{data: selectedReply, set: setSelectedReply},
        emsg:{data: editMessage, set: setEditMessage},
        fmsg:{data: forwardMessage, set: setForwardMessage},
        msg:{data: message, set: setMessage},
        gfmsg: {data: gfMessage, set: setGfMessage},
        chatData:{data: chats, set: setChats},
        attachData:{data: attachment, set: setAttachment},
        aDataLoader:{data: attachments, set:setAttachments},
        ccRoles:{data: roles, set: setRoles},
        attachGgdp:{data: ggdp, set: setGgdp},
    }
}

import { useState } from "react"
import { DesktopDownload, GroupIcon, MessageIcon, SupportFilled } from "../global/Icons"

export const LeftNavStates = () => {
    const [isShow, setIsShow] = useState({navSelect: '', status: true})
    const lefthomeList = 
        {top: [
            {name: 'Chat', link: '',cc: 'direct', icon: MessageIcon},
            {name: 'Group', link: '',cc: 'group', icon: GroupIcon},
        ],
        between: [
            {name: 'Support', link: '', cc: 'support', icon: SupportFilled}
        ],
        bottom: [
            {name: 'Download App', link: '',cc: 'dlapp', icon: DesktopDownload},
        ]
    }
    
    const topleftproList = 
        {top: [
            {name: 'Chat', link: '',cc: 'direct', icon: MessageIcon},
            {name: 'Communities', link: '',cc: 'community', icon: GroupIcon},
        ],
        bottom: [
            {name: 'Download App', link: '',cc: 'dlapp', icon: DesktopDownload},
        ]
    }
    

    return {
        leftHome: lefthomeList,
        leftPro: topleftproList,
        drawerMain: {data: isShow, set: setIsShow},
    }
}
import { useRef, useState } from 'react'

export const CallingState = () => {
    const [calProfile, setCalProfile] = useState(false)
    const [activeSelection, setActiveSelection] = useState('')
    const [callType, setCallType] = useState('');

    const [me, setMe] = useState("");
    const [stream, setStream] = useState(null);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState("");
    const [callerSignal, setCallerSignal] = useState(null);
    const [socketUsers, setSocketUsers] = useState(null);
    const [connectUsers, setConnectUsers] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [callAccepted, setCallAccepted] = useState(false);
    const [remoteUserName, setRemoteUserName] = useState("");
    const [callGrpName, setCallGrpName] = useState("");
    const [idToCall, setIdToCall] = useState("");
    const [callEnded, setCallEnded] = useState(false);
    const [answerCall, setAnswerCall] = useState(false);
    const [openCallRecord, setOpenCallRecord] = useState(false);
    const [isRinging, setIsRinging] = useState(false);
    const [recordingPermission, setRecordingPermission] = useState(null);
    const [name, setName] = useState("");
    const [sockets, setSockets] = useState("");
    const userVideo = useRef(null);
    const connectionRef = useRef(null);
    const videoStream = useRef(null)

    return {
        callingprofile: { data: calProfile, set: setCalProfile },
        activeIcon: { data: activeSelection, set: setActiveSelection },
        callType: { data: callType, set: setCallType },
        me: { data: me, set: setMe },
        stream: { data: stream, set: setStream },
        receivingCall: { data: receivingCall, set: setReceivingCall },
        caller: { data: caller, set: setCaller },
        callerSignal: { data: callerSignal, set: setCallerSignal },
        socketUsers: { data: socketUsers, set: setSocketUsers },
        connectUsers: { data: connectUsers, set: setConnectUsers },
        chatMessages: { data: chatMessages, set: setChatMessages },
        callAccepted: { data: callAccepted, set: setCallAccepted },
        remoteUserName: { data: remoteUserName, set: setRemoteUserName },
        callGrpName: { data: callGrpName, set: setCallGrpName },
        idToCall: { data: idToCall, set: setIdToCall },
        callEnded: { data: callEnded, set: setCallEnded },
        answerCall: { data: answerCall, set: setAnswerCall },
        openCallRecord: { data: openCallRecord, set: setOpenCallRecord },
        isRinging: { data: isRinging, set: setIsRinging },
        recordingPermission: { data: recordingPermission, set: setRecordingPermission },
        name: { data: name, set: setName },
        sockets: { data: sockets, set: setSockets },
        userVideo: userVideo,
        videoStream: videoStream,
        connectionRef: connectionRef
    }
}

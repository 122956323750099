// Libraries
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material';

// Layouts
import { env } from './core/Env';
import { rqx } from './core/request/API';
import { Logo } from './core/global/Icons';
import { SnakeLoader } from './core/loader/SnakeLoader';
import { ErrorCodeAid } from './core/errorCodes/ErrorCode';

export const SSORqx = () => {
    return <Router><Validate /></Router>
}

const Validate = () => {
    const q = new URLSearchParams(useLocation().search);
    const [err, setErr] = useState(false)
    console.log(q)
    useEffect(() => {
        const __init = async () => {
            if (q.get('ainfo') !== null) {
                const ainfo = JSON.parse(q.get('ainfo'))
                const acc = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: ainfo.aid})

                if (typeof acc === 'object') {
                    setErr(false)
                    let d = new Date()
                    d.setDate(d.getDate() + 30);
                    
                    const strg = {
                        expiration: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`,
                        ainfo: ainfo
                    }
                    localStorage.setItem('pof_chat_session', JSON.stringify(strg))
                    window.location.href = '/'
                } else {
                    setErr(true)
                }
                
            }
        }

        setTimeout(() => {
            __init()
        }, 1500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !err ? (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Logo size={60}/>
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    ) : (
        <ErrorCodeAid />
    )
}